import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FormControl,
  InputGroup,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { GoArchive } from "react-icons/go";
import { IoDocumentAttachOutline } from "react-icons/io5";
import { RiAddCircleLine } from "react-icons/ri";
import ModalGenerico from "../modalgenerico.tsx";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import { useCookies } from "react-cookie";

// API
import api from "../../../services/api";

interface ModalNovoComentariosProps {
  open: boolean;
  onClose: () => void;
  title: string;
  onClickButton: () => void;
  searchInput?: React.ReactNode;
  tooltipText?: string;
  nivelContaAtual: any;
  modalNovoComentarios: any;
  setToolipText: any;
  id: string;
  modalComentariosShow: any;
  notify: (string) => void;
  selected: any[];
  anoConsulta: Number;
  loadData: () => void;
}

const ModalNovoComentario: React.FC<ModalNovoComentariosProps> = ({
  open,
  onClose,
  title,
  onClickButton,
  searchInput,
  tooltipText,
  nivelContaAtual,
  modalNovoComentarios,
  setToolipText,
  id = "anexoAtualPergunta",
  modalComentariosShow,
  notify,
  selected,
  anoConsulta,
  loadData,
}) => {
  const [myUsers, setMyUsers] = useState<{ label: any; value: any }[]>([]);
  const [cookies] = useCookies(["usuario"]);
  const [selectedUser, setSelectedUser] = useState([]);
  const SSunidades = sessionStorage.getItem("unidades") ?? "";
  const NovaPergunta = document.getElementById("Nova-pergunta") ?? "";
  const novoComentarioElement = document.getElementById(
    "Novo-comentario"
  ) as HTMLInputElement;
  const [isComentario, setIsComentario] = useState(true);
  let pergunta = (NovaPergunta as HTMLInputElement)?.value;
  const MYFiles = document.getElementById("anexoAtualPergunta") ?? "";
  let myFiles = (MYFiles as HTMLInputElement)?.files;
  let myFilesValue = (MYFiles as HTMLInputElement)?.value;

  function criaChamadas(contaOrigem) {
    const chamadas: string[] = [];
    if (contaOrigem === "sintetica" || modalComentariosShow[6]) {
      JSON.parse(SSunidades)
        .filter((u) => {
          const selectedValues = selected.map((item) => item.value);
          return (
            selectedValues.includes(u.combo_id) ||
            selectedValues.includes(u.unidade_id)
          );
        })
        .map((unidadeAtual) =>
          chamadas.push(`/usuarios?combo_id=${unidadeAtual.combo_id}`)
        );
    } else {
      // Conta analitica
      if (modalNovoComentarios[2]) {
        var comboAtual = JSON.parse(SSunidades).find(
          (unidade) => unidade.id === modalNovoComentarios[2].unidade_id
        ).combo_id;
        chamadas.push(`/usuarios?combo_id=${comboAtual}`);
      }
    }
    return chamadas;
  }

  async function loadMyUsers(chamadas) {
    try {
      const promises = chamadas.map((url) =>
        api.get(url, {
          headers: { authorization: `Bearer ${cookies.usuario.accessToken}` },
        })
      );

      const responses = await Promise.all(promises);
      const uniqueUserIds = new Set();
      const users = [];
      responses.forEach((res) => {
        res.data.forEach((cv) => {
          if (cv.id !== cookies.usuario.id && !uniqueUserIds.has(cv.id)) {
            uniqueUserIds.add(cv.id);
            users.push({ label: cv.nome, value: cv.id });
          }
        });
      });

      setMyUsers(users);
    } catch (error) {
      console.error("Erro ao carregar usuários", error);
    }
  }

  useEffect(() => {
    loadMyUsers(criaChamadas(nivelContaAtual));
  }, [open]);

  async function adicionaComentarioGeral(contaOrigem, registroRazao) {
    var anexoAtualElement = document.getElementById(
      "anexoAtualPergunta"
    ) as HTMLInputElement;
    var anexoAtual = anexoAtualElement?.files ?? null;

    if (modalComentariosShow[4] === "orcado")
      return notify(
        "Comentario sobre o orçamento são permitidos apenas durante a elaboração!"
      );
    if (contaOrigem === "sintetica" || modalComentariosShow[6]) {
      if (!pergunta) return notify("Comentário vazio!");
      JSON.parse(SSunidades)
        .filter((u) => {
          const selectedValues = selected.map((item) => item.value);
          return (
            selectedValues.includes(u.combo_id) ||
            selectedValues.includes(u.unidade_id)
          );
        })
        .map(async (d) => {
          var response = await api.post(
            "/createComentariosGeral",
            {
              registrodrerazao_id: registroRazao.codigo_id,
              comentario: pergunta,
              usuario_id: cookies?.usuario?.id,
              unidade_id: d.id,
              group: registroRazao.grupo,
              codigodre_id: registroRazao.codigo_id,
              mes: parseFloat(modalComentariosShow[3]),
              ano: anoConsulta,
              origem: modalComentariosShow[6]
                ? modalComentariosShow[6]
                : modalComentariosShow[4] === "orcado"
                ? "orcado"
                : "realizado",
              pagina: "/dre/orcadorealizado",
            },
            {
              headers: {
                authorization: `Bearer ${cookies?.usuario?.accessToken}`,
              },
            }
          );

          var myFiles = anexoAtual;
          if (myFiles?.length) {
            for (let i = 0; i < myFiles?.length; i++) {
              const dados = new FormData();

              dados.append("file", myFiles[i]);
              dados.append(
                "drerazaocomentario_id",
                response.data.comentario.id
              );

              await api.post("/files", dados, {
                headers: {
                  authorization: `Bearer ${cookies?.usuario?.accessToken}`,
                },
              });
            }
            if (anexoAtualElement) {
              anexoAtualElement.value = "";
            }
            setToolipText("Adicionar anexo");
          }
          notify(d.unidade + " " + response.data.mensagem);
        });
      pergunta = "";
      loadData();
      onClose();
    } else if (contaOrigem === "analitica") {
      if (!pergunta) return notify("Comentário vazio!");
      var response = await api.post(
        "/createComentarios",
        {
          registrodrerazao_id: registroRazao.id,
          comentario: pergunta,
          usuario_id: cookies.usuario.id,
          unidade_id: registroRazao.unidade_id,
          codigodre_id: registroRazao.codigodre_id,
          origem: modalComentariosShow[6]
            ? modalComentariosShow[6]
            : modalComentariosShow[4] === "orcado"
            ? "orcado"
            : "realizado",
          mes: parseFloat(registroRazao.data.substring(5, 7)),
          ano: anoConsulta,
          pagina: "/dre/orcadorealizado",
        },
        {
          headers: { authorization: `Bearer ${cookies.usuario.accessToken}` },
        }
      );

      var myFiles = anexoAtualElement.files;
      if (myFiles?.length) {
        for (let i = 0; i < myFiles?.length; i++) {
          const dados = new FormData();

          dados.append("file", myFiles?.[i]);
          dados.append("drerazaocomentario_id", response.data.id);

          await api.post("/files", dados, {
            headers: {
              authorization: `Bearer ${cookies.usuario.accessToken}`,
            },
          });
        }
        if (anexoAtualElement) {
          anexoAtualElement.value = "";
        }
        setToolipText("Adicionar anexo");
      }

      notify(response.data.mensagem);
      pergunta = "";
      loadData();
      onClose();
    }
  }

  async function adicionaPerguntaGeral(contaOrigem, registroRazao) {
    if (modalComentariosShow[4] === "orcado")
      return notify(
        "Comentario sobre o orçamento são permitidos apenas durante a elaboração!"
      );
    if (contaOrigem === "sintetica" || modalComentariosShow[6]) {
      if (!pergunta) return notify("Escreva uma pergunta!");
      JSON.parse(SSunidades)
        .filter((u) => {
          const selectedValues = selected.map((item) => item.value);
          return (
            selectedValues.includes(u.combo_id) ||
            selectedValues.includes(u.unidade_id)
          );
        })
        .map(async (d) => {
          var response = await api.post(
            "/createPerguntasGeral",
            {
              registrodrerazao_id: registroRazao.codigo_id,
              comentario: pergunta,
              usuario_id: cookies.usuario.id,
              unidade_id: d.id,
              grupo: registroRazao.grupo,
              codigodre_id: registroRazao.codigo_id,
              origem: modalComentariosShow[6]
                ? modalComentariosShow[6]
                : modalComentariosShow[4] === "orcado"
                ? "orcado"
                : "realizado",
              mes: parseFloat(modalComentariosShow[3]),
              ano: anoConsulta,
              destinatarios: selectedUser,
              pagina: "/dre/orcadorealizado",
            },
            {
              headers: {
                authorization: `Bearer ${cookies.usuario.accessToken}`,
              },
            }
          );
          if (myFiles?.length) {
            for (let i = 0; i < myFiles.length; i++) {
              const dados = new FormData();

              dados.append("file", myFiles[i]);
              dados.append(
                "drerazaocomentario_id",
                response.data.comentario.id
              );

              await api.post("/files", dados, {
                headers: {
                  authorization: `Bearer ${cookies.usuario.accessToken}`,
                },
              });
            }
            myFilesValue = "";
            setToolipText("Adicionar anexo");
          }
          notify(d.unidade + " " + response.data.mensagem);
        });
      myFilesValue = "";
      loadData();
      onClose();
    } else if (contaOrigem === "analitica") {
      if (!pergunta) return notify("Escreva uma pergunta!");
      var response = await api.post(
        "/createPerguntas",
        {
          registrodrerazao_id: registroRazao.id,
          comentario: pergunta,
          usuario_id: cookies.usuario.id,
          unidade_id: registroRazao.unidade_id,
          codigodre_id: registroRazao.codigodre_id,
          origem: modalComentariosShow[6]
            ? modalComentariosShow[6]
            : modalComentariosShow[4] === "orcado"
            ? "orcado"
            : "realizado",
          mes: registroRazao.data.substring(5, 7),
          ano: anoConsulta,
          destinatarios: selectedUser,
          pagina: "/dre/orcadorealizado",
        },
        {
          headers: {
            authorization: `Bearer ${cookies.usuario.accessToken}`,
          },
        }
      );

      if (myFiles?.length) {
        for (let i = 0; i < myFiles.length; i++) {
          const dados = new FormData();

          dados.append("file", myFiles[i]);
          dados.append("drerazaocomentario_id", response.data.id);

          await api.post("/files", dados, {
            headers: {
              authorization: `Bearer ${cookies.usuario.accessToken}`,
            },
          });
        }
        myFilesValue = "";
        setToolipText("Adicionar anexo");
      }

      notify(response.data.mensagem);
      pergunta = "";
      loadData();
      onClose();
    }
  }

  return (
    <ModalGenerico
      isButtonHeaderDisabled
      buttonHeaderType="contained"
      open={open}
      onClose={onClose}
      onClickButton={onClickButton}
      title={title}
      searchInput={searchInput}
      showSearchIcon={false}
      buttonContent={<GoArchive />}
      dialogContent={
        <>
          <Row style={{ alignItems: "center", marginBottom: "1rem" }}>
            <Col>
              <Autocomplete
                multiple
                size="small"
                id="destinatarios"
                options={myUsers}
                getOptionLabel={(option) => option.label}
                onChange={(event, newValue) => {
                  setSelectedUser(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Selecione o destinatário (Opcional)"
                  />
                )}
              />
            </Col>
          </Row>
          <Row style={{ alignItems: "center", marginBottom: "1rem" }}>
            <Col>
              <InputGroup>
                <FormControl
                  placeholder="Comentar"
                  aria-label="Comentar"
                  id={"Nova-pergunta"}
                  onChange={(e) => {
                    pergunta = e.target.value;
                  }}
                />

                <OverlayTrigger
                  overlay={
                    <Tooltip
                      id={"TooltipAdicionarAnexo"}
                      style={{ zIndex: 100000 }}
                    >
                      {tooltipText}
                    </Tooltip>
                  }
                >
                  <Button
                    variant={
                      tooltipText !== "Adicionar anexo"
                        ? "success"
                        : "outline-secondary"
                    }
                    style={{
                      borderRadius: 0,
                      outline: "none",
                      boxShadow: "none",
                    }}
                    id={"buttonSave-resposta"}
                    onClick={() => {
                      document?.getElementById(id)?.click();
                    }}
                  >
                    <IoDocumentAttachOutline />
                  </Button>
                </OverlayTrigger>
                <input
                  type="file"
                  hidden
                  id="anexoAtualPergunta"
                  onChange={(e) => {
                    if (e.target.files != null && e?.target?.files[0]) {
                      setToolipText(e?.target?.files[0].name);
                    } else {
                      setToolipText("Adicionar anexo");
                    }
                  }}
                />
                <Button
                  style={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    outline: "none",
                  }}
                  id={"buttonSave-" + modalNovoComentarios[1]}
                  onClick={() => {
                    if (selectedUser.length > 0) {
                      adicionaPerguntaGeral(
                        nivelContaAtual,
                        modalNovoComentarios[2]
                      );
                      return;
                    }
                    adicionaComentarioGeral(
                      nivelContaAtual,
                      modalNovoComentarios[2]
                    );
                  }}
                >
                  <RiAddCircleLine /> Comentar
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </>
      }
    />
  );
};

export default ModalNovoComentario;
