/* eslint-disable */
import React, { useEffect, useState } from "react";
import { AiOutlineBank, AiOutlinePlus, AiOutlineTool } from "react-icons/ai";
import { MdAttachMoney } from "react-icons/md";
import { BiCommentEdit, BiMailSend, BiEditAlt, BiWorld } from "react-icons/bi";
import { FaFilePdf, FaFileSignature, FaSignature } from "react-icons/fa";
import { RiFileExcel2Fill, RiUserStarLine } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
  Modal,
  Button,
  InputGroup,
  FormControl,
  OverlayTrigger,
  Tooltip,
  Row,
  Col,
  Badge,
  Popover,
  Tab,
  Nav,
  Container,
  Form,
} from "react-bootstrap";
import Select from "react-select";

import { TextField } from "@material-ui/core";

import { create, all } from "mathjs";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Fab, Action } from "react-tiny-fab";
import "react-tiny-fab/dist/styles.css";
import api from "../../../services/api";

import logoUpManagement from "../../../assets/logoUPManagement.png";

function Investidores() {
  var location = useLocation();

  const [patrimonialObj, setPatrimonialObj] = useState(
    location.state.patrimonialObj
  );
  const [observacao, setObservacao] = useState({
    auxVersion: 0,
    modalVisivel: false,
    hooverVisivel: false,
    comentario: location.state.patrimonialObj?.comentario[0]?.comentario
      ? location.state.patrimonialObj?.comentario[0]?.comentario
      : "",
  });
  const [outrasDespesas, setOutrasDespesas] = useState({
    modalVisivel: false,
    hooverVisivel: false,
    valor: !isNaN(location.state.patrimonialObj?.outrasDespesas[0]?.valor)
      ? location.state.patrimonialObj?.outrasDespesas[0]?.valor
      : 0,
    descricao: location.state.patrimonialObj?.outrasDespesas[0]?.descricao
      ? location.state.patrimonialObj?.outrasDespesas[0]?.descricao
      : "",
    auxVersion: 0,
  });
  const [ajusteReceita, setAjusteReceita] = useState({
    modalVisivel: false,
    hooverVisivel: false,
    valor: location.state.patrimonialObj?.ajusteReceita[0]?.valor
      ? location.state.patrimonialObj?.ajusteReceita[0]?.valor
      : 0,
    descricao: location.state.patrimonialObj?.ajusteReceita[0]?.descricao
      ? location.state.patrimonialObj?.ajusteReceita[0]?.descricao
      : "",
    auxVersion: 0,
  });
  const [editarAlugueis, setEditarAlugueis] = useState({
    titulo: "",
    valor: "",
    id: null,
  });
  const [editarInvestidor, setEditarInvestidor] = useState({
    modalVisivel: false,
    auxVersion: 0,
    banco: "",
  });
  const [editarProprietarios, setEditarProprietarios] = useState({
    modalVisivel: false,
    auxVersion: 0,
    banco: "",
  });
  const [editarFundoDeReservaPerc, setEditarFundoDeReservaPerc] = useState(
    patrimonialObj?.fundoDeReservaPerc
  );
  const [modalAssinaturaComentario, setModalAssinaturaComentario] =
    useState(false);
  const [assinaturaComentario, setAssinaturaComentario] = useState("");
  const [modalVisualizarAssinatura, setModalVisualizarAssinatura] =
    useState(false);
  const [configuracoes, setModalConfiguracoes] = useState({
    modalVisivel: false,
  });
  const [keyAlugueis, setKeyAlugueis] = useState("Novo");
  const [modalDesativarAcionista, setModalDesativarAcionista] = useState(false);
  const [codigosBancosLista, setCodigosBancosLista] = useState([]);
  const [modalDataAdicionaApartamentoFix, setModalDataAdicionaApartamentoFix] =
    useState({ modalVisivel: false });

  const [cookies] = useCookies([]);
  const config = {};
  const math = create(all, config);

  useEffect(() => {
    loadPatrimonialAtual();
  }, [location.state.patrimonialObj]);

  async function loadPatrimonialAtual() {
    document.body.style.cursor = "wait";
    const atualLoadPatrimonial = await api.get(
      "/patrimoniais/Id/" +
      cookies.usuario.id +
      "/" +
      location.state.patrimonialObj.value,
      { headers: { authorization: `Bearer ${cookies.usuario.accessToken}` } }
    );
    setPatrimonialObj(atualLoadPatrimonial.data[0]);
    setObservacao({
      auxVersion: 0,
      modalVisivel: false,
      hooverVisivel: false,
      comentario: atualLoadPatrimonial.data[0]?.comentario[0]?.comentario
        ? atualLoadPatrimonial.data[0]?.comentario[0]?.comentario
        : "",
    });
    setOutrasDespesas({
      modalVisivel: false,
      hooverVisivel: false,
      valor: !isNaN(atualLoadPatrimonial.data[0]?.outrasDespesas[0]?.valor)
        ? atualLoadPatrimonial.data[0]?.outrasDespesas[0]?.valor
        : 0,
      descricao: atualLoadPatrimonial.data[0]?.outrasDespesas[0]?.descricao
        ? atualLoadPatrimonial.data[0]?.outrasDespesas[0]?.descricao
        : "",
      auxVersion: 0,
    });
    setAjusteReceita({
      modalVisivel: false,
      hooverVisivel: false,
      valor: atualLoadPatrimonial.data[0]?.ajusteReceita[0]?.valor
        ? atualLoadPatrimonial.data[0]?.ajusteReceita[0]?.valor
        : 0,
      descricao: atualLoadPatrimonial.data[0]?.ajusteReceita[0]?.descricao
        ? atualLoadPatrimonial.data[0]?.ajusteReceita[0]?.descricao
        : "",
      auxVersion: 0,
    });
    setEditarFundoDeReservaPerc(
      atualLoadPatrimonial.data[0]?.fundoDeReservaPerc
    );
    document.body.style.cursor = "default";

    const codigosBancos = await api.get("/estruturas/codigosbancos", {
      headers: { authorization: `Bearer ${cookies.usuario.accessToken}` },
    });
    setCodigosBancosLista(codigosBancos.data);
  }

  const notify = ($msg, $time = 5000, $id = null) =>
    toast($msg, {
      toastId: $id,
      position: "bottom-right",
      autoClose: $time,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });

  async function adicionarOutrasDespesas() {
    var currentVersion =
      patrimonialObj?.outrasDespesas[0]?.version + outrasDespesas.auxVersion;

    if (!currentVersion) {
      currentVersion = outrasDespesas.auxVersion;
    }

    try {
      var response = await api.post(
        "/patrimoniais/despesa",
        {
          patrimonial_id: patrimonialObj.value,
          ano: patrimonialObj.dadosTempo.ano,
          mes: patrimonialObj.dadosTempo.mesconsulta,
          usuario_id: cookies.usuario.id,
          version: currentVersion + 1,
          descricao: outrasDespesas.descricao,
          valor: parseFloat(outrasDespesas.valor),
        },
        { headers: { authorization: `Bearer ${cookies.usuario.accessToken}` } }
      );

      setOutrasDespesas({
        ...outrasDespesas,
        modalVisivel: false,
        auxVersion: currentVersion + 1,
      });
      if (response.status !== 200) return notify("Erro ao adicionar despesa!");
      if (patrimonialObj?.modelo === 1 || patrimonialObj?.modelo === 3) {
        var atualImpostosEDespesas =
          patrimonialObj.pis +
          patrimonialObj.cofins +
          patrimonialObj.irpj +
          patrimonialObj.adicionalIRPJ +
          patrimonialObj.csll +
          parseFloat(outrasDespesas.valor);
        var atualLucroADistribuir =
          patrimonialObj.totalAluguelBruto - atualImpostosEDespesas;
        if (patrimonialObj?.modelo === 3) {
          var atualLucroADistribuir =
            patrimonialObj.totalAluguelBruto - atualImpostosEDespesas;
        } else {
          var atualLucroADistribuir =
            patrimonialObj.totalAluguelBruto -
            atualImpostosEDespesas -
            patrimonialObj.fundoDeReserva;
        }
        var atualLucroADistribuirPorAcao =
          atualLucroADistribuir / patrimonialObj.acoes;

        var auxAcionistas = patrimonialObj.acionistas;

        auxAcionistas.forEach((acionista) => {
          return (acionista.receitaAluguel =
            atualLucroADistribuirPorAcao * parseFloat(acionista.acoes));
        });

        setPatrimonialObj({
          ...patrimonialObj,
          impostosEDespesas: atualImpostosEDespesas,
          lucroADistribuir: atualLucroADistribuir,
          lucroADistribuirPorAcao: atualLucroADistribuirPorAcao,
          outrasDespesas: [
            {
              valor: parseFloat(outrasDespesas.valor),
              descricao: outrasDespesas.descricao,
              auxVersion: currentVersion + 1,
            },
          ],
          acionistas: auxAcionistas,
        });
        return notify("Despesa adicionada com sucesso!");
      } else {
        var atualLucroBrutoADistribuir =
          patrimonialObj.totalBrutoSimples + parseFloat(outrasDespesas.valor);
        var atualLucroBrutoADistribuirPorAcao =
          atualLucroBrutoADistribuir / patrimonialObj.acoes;

        var atualLucroLiquidoADistribuir =
          atualLucroBrutoADistribuir - patrimonialObj.fundoDeReserva;
        var atualLucroLiquidoADistribuirPorAcao =
          atualLucroLiquidoADistribuir / patrimonialObj.acoes;

        var auxAcionistas = patrimonialObj.acionistas;

        auxAcionistas.forEach((acionista) => {
          var receitaAluguelAtual =
            atualLucroBrutoADistribuirPorAcao * parseFloat(acionista.acoes);
          if (!acionista.isPJ && !!acionista?.reside_exterior)
            acionista.irrf = receitaAluguelAtual * (acionista?.aliquota / 100);
          if (!acionista.isPJ && !!!acionista?.reside_exterior)
            acionista.irrf =
              receitaAluguelAtual *
              patrimonialObj?.tabelaProgressivaIR?.find(
                (d) =>
                  d.basecalculo_max >= receitaAluguelAtual &&
                  d.basecalculo_min < receitaAluguelAtual
              )?.aliquota -
              patrimonialObj?.tabelaProgressivaIR?.find(
                (d) =>
                  d.basecalculo_max >= receitaAluguelAtual &&
                  d.basecalculo_min < receitaAluguelAtual
              )?.deducao;
          acionista.receitaAluguel = receitaAluguelAtual;
          acionista.valorLiquidoPagamento =
            receitaAluguelAtual -
            acionista.irrf -
            acionista.retencaoFundoReserva;
        });

        setPatrimonialObj({
          ...patrimonialObj,
          totalBruto: atualLucroBrutoADistribuir,
          aluguelPorAcao: atualLucroBrutoADistribuirPorAcao,
          aluguelPorAcaoLiquido: atualLucroLiquidoADistribuirPorAcao,
          outrasDespesas: [
            {
              valor: parseFloat(outrasDespesas.valor),
              descricao: outrasDespesas.descricao,
              auxVersion: currentVersion + 1,
            },
          ],
          correcaoAluguel: parseFloat(outrasDespesas.valor),
          acionistas: auxAcionistas,
        });
        return notify("Correção adicionada com sucesso!");
      }
    } catch (error) {
      console.error(error);
      return notify("Erro ao adicionar despesa!");
    }
  }

  async function adicionarAjusteReceita() {
    var currentVersion =
      patrimonialObj?.ajusteReceita[0]?.version + ajusteReceita.auxVersion;

    if (!currentVersion) {
      currentVersion = ajusteReceita.auxVersion;
    }

    try {
      var response = await api.post(
        "/patrimoniais/ajuste",
        {
          patrimonial_id: patrimonialObj.value,
          ano: patrimonialObj.dadosTempo.ano,
          mes: patrimonialObj.dadosTempo.mesconsulta,
          usuario_id: cookies.usuario.id,
          version: currentVersion + 1,
          descricao: ajusteReceita.descricao,
          valor: parseFloat(ajusteReceita.valor),
        },
        { headers: { authorization: `Bearer ${cookies.usuario.accessToken}` } }
      );

      return loadPatrimonialAtual();

      console.log(patrimonialObj);

      setAjusteReceita({
        ...ajusteReceita,
        modalVisivel: false,
        auxVersion: currentVersion + 1,
      });
      if (response.status !== 200) return notify("Erro ao adicionar despesa!");
      if (patrimonialObj?.modelo === 1 || patrimonialObj?.modelo === 3) {
        patrimonialObj.aluguelLiquido =
          patrimonialObj.totalBruto -
          patrimonialObj.fundoDeReserva +
          ajusteReceita.valor;
        patrimonialObj.aluguelBruto = parseFloat(
          patrimonialObj.aluguelLiquido *
          (patrimonialObj?.proprietarios?.length > 0
            ? patrimonialObj?.proprietarios
              ?.filter((p) => p.distribui)
              .reduce((acc, cv) => (acc += cv.percentual), 0)
            : 1)
        );
        patrimonialObj.totalAluguelBruto = parseFloat(
          patrimonialObj.aluguelBruto +
          patrimonialObj.fundoDeReserva +
          patrimonialObj?.alugueis?.reduce((acc, cv) => (acc += cv.valor), 0)
        );

        patrimonialObj.pis = parseFloat(
          patrimonialObj.totalAluguelBruto * 0.0065
        );
        patrimonialObj.cofins = parseFloat(
          patrimonialObj.totalAluguelBruto * 0.03
        );
        patrimonialObj.csll = parseFloat(
          patrimonialObj.totalAluguelBruto * 0.0288
        );
        patrimonialObj.irpj = parseFloat(
          patrimonialObj.totalAluguelBruto * 0.048
        );
        patrimonialObj.adicionalIRPJ = parseFloat(
          ((patrimonialObj.totalAluguelBruto * 0.32 * 3 - 60000) * 0.1) / 3
        );

        if (patrimonialObj.adicionalIRPJ < 0) patrimonialObj.adicionalIRPJ = 0;

        var atualImpostosEDespesas =
          patrimonialObj.pis +
          patrimonialObj.cofins +
          patrimonialObj.irpj +
          patrimonialObj.adicionalIRPJ +
          patrimonialObj.csll;
        var atualLucroADistribuir =
          patrimonialObj.totalAluguelBruto -
          atualImpostosEDespesas -
          patrimonialObj.fundoDeReserva;
        var atualLucroADistribuirPorAcao =
          atualLucroADistribuir / patrimonialObj.acoes;

        var auxAcionistas = patrimonialObj.acionistas;

        auxAcionistas.forEach((acionista) => {
          return (acionista.receitaAluguel =
            atualLucroADistribuirPorAcao * parseFloat(acionista.acoes));
        });

        setPatrimonialObj({
          ...patrimonialObj,
          impostosEDespesas: atualImpostosEDespesas,
          lucroADistribuir: atualLucroADistribuir,
          lucroADistribuirPorAcao: atualLucroADistribuirPorAcao,
          aluguelPorAcao: patrimonialObj.aluguelLiquido / patrimonialObj.acoes,
          ajusteReceita: [
            {
              valor: parseFloat(ajusteReceita.valor),
              descricao: ajusteReceita.descricao,
              auxVersion: currentVersion + 1,
            },
          ],
          acionistas: auxAcionistas,
        });
        return notify("Ajuste adicionado com sucesso!");
      } else {
        // Erro I-AR0001
        notify(
          "Erro I-AR0001: Ajuste adicionado ao modelo incorreto, entre em contato com o suporte!",
          null
        );
      }
    } catch (error) {
      console.error(error);
      return notify("Erro ao adicionar ajuste!");
    }
  }

  async function adicionarObs() {
    var currentVersion =
      patrimonialObj?.comentario[0]?.version + observacao.auxVersion;

    if (!currentVersion) {
      currentVersion = observacao.auxVersion;
    }

    try {
      var response = await api.post(
        "/patrimoniais/comentario",
        {
          patrimonial_id: patrimonialObj.value,
          ano: patrimonialObj.dadosTempo.ano,
          mes: patrimonialObj.dadosTempo.mesconsulta,
          usuario_id: cookies.usuario.id,
          version: currentVersion + 1,
          comentario: observacao.comentario,
        },
        { headers: { authorization: `Bearer ${cookies.usuario.accessToken}` } }
      );

      if (response.status !== 200)
        return notify("Erro ao adicionar comentário!");
      setObservacao({
        ...observacao,
        modalVisivel: false,
        auxVersion: observacao.auxVersion + 1,
      });
      setPatrimonialObj({
        ...patrimonialObj,
        comentario: [
          { comentario: observacao.comentario, version: currentVersion + 1 },
        ],
      });
      return notify("Comentário adicionado com sucesso!");
    } catch (error) {
      console.error(error);
      return notify("Erro ao adicionar comentario!");
    }
  }

  async function atualizarProprietarios() {
    if (editarProprietarios.banco.toUpperCase() === "PIX") {
      editarProprietarios.conta = null;
    }

    try {
      var response = await api.post(
        "/patrimoniais/proprietario/" + patrimonialObj.value,
        {
          percentual: editarProprietarios.percentual,
          agencia: editarProprietarios.agencia,
          banco: editarProprietarios.banco,
          conta: editarProprietarios.conta,
          copia: editarProprietarios.copia,
          destinatario: editarProprietarios.destinatario,
          cnpj: editarProprietarios["cnpj"],
          proprietario: editarProprietarios.proprietario,
          version: editarProprietarios.version + 1,
          comentario: editarProprietarios.comentario,
          usuario_id: cookies.usuario.id,
          distribui: editarProprietarios.distribui,
          ordem: editarProprietarios.ordem,
          isPix: editarProprietarios.isPix,
          tipoPix: editarProprietarios.tipoPix,
          pix: editarProprietarios.pix,
          codigobanco_id: editarProprietarios.codigobanco_id,
        },
        { headers: { authorization: `Bearer ${cookies.usuario.accessToken}` } }
      );

      setEditarProprietarios({ ...editarProprietarios, modalVisivel: false });
      if (response.status !== 200)
        return notify("Erro ao atualizar proprietario!");
      var foundIndex = patrimonialObj?.proprietarios.findIndex(
        (x) => x["cnpj"] === editarProprietarios["cnpj"]
      );
      var proprietariosAll = patrimonialObj?.proprietarios;

      proprietariosAll[foundIndex] = {
        ...editarProprietarios,
        version: editarProprietarios.version + 1,
        aluguelLiquidoACreditar:
          parseFloat(editarProprietarios.percentual) *
          patrimonialObj.aluguelLiquido,
      };

      if (editarProprietarios.distribui) {
        await api
          .get(`/patrimoniais/all/${cookies.usuario.id}`, {
            headers: { authorization: `Bearer ${cookies.usuario.accessToken}` },
          })
          .then((res) => {
            setPatrimonialObj({
              ...res.data.find((d) => d.value === patrimonialObj.value),
              proprietarios: proprietariosAll,
            });
          });
      } else {
        setPatrimonialObj({
          ...patrimonialObj,
          proprietarios: proprietariosAll,
        });
      }

      return notify("Proprietario atualizado com sucesso!");
    } catch (error) {
      console.error(error);
      return notify("Erro ao atualizar proprietario!");
    }
  }

  async function atualizarInvestidor() {
    try {
      var response = await api.post(
        "/patrimoniais/acionista/" + patrimonialObj.value,
        {
          acoes: editarInvestidor.acoes,
          agencia: editarInvestidor.agencia,
          banco: editarInvestidor.banco,
          conta: editarInvestidor.conta,
          copia: editarInvestidor.copia,
          destinatario: editarInvestidor.destinatario,
          "cpf/cnpj": editarInvestidor["cpf/cnpj"],
          isPJ: editarInvestidor.isPJ,
          nome: editarInvestidor.nome,
          version: editarInvestidor.version + 1,
          comentario: editarInvestidor.comentario,
          usuario_id: cookies.usuario.id,
          isPix: editarInvestidor.isPix,
          tipoPix: editarInvestidor.tipoPix,
          pix: editarInvestidor.pix,
          codigobanco_id: editarInvestidor.codigobanco_id,
          reside_exterior: editarInvestidor?.reside_exterior,
          pais_residencia: editarInvestidor?.pais_residencia,
          aliquota: editarInvestidor?.aliquota,
        },
        { headers: { authorization: `Bearer ${cookies.usuario.accessToken}` } }
      );

      if (response.status !== 200)
        return notify("Erro ao atualizar investidor!");

      if (editarInvestidor.email.apartamentos) {
        await Promise.all(
          editarInvestidor.email.apartamentos.map(async (aps) => {
            var responseApartamentos = await api.post(
              "/patrimoniais/apartamentos",
              {
                "cpf/cnpj": aps["cpf/cnpj"],
                unidade_id: aps.unidade_id,
                apartamentos: aps.apartamentos,
                version: aps.version + 1,
                comentario: editarInvestidor.comentario,
                usuario_id: cookies.usuario.id,
              },
              {
                headers: {
                  authorization: `Bearer ${cookies.usuario.accessToken}`,
                },
              }
            );

            if (responseApartamentos.status !== 200)
              return notify(
                "Erro ao atualizar apartamentos da unidade " +
                aps.unidade +
                " do investidor" +
                editarInvestidor.nome +
                "!"
              );

            var apartamentoIdx = editarInvestidor.email.apartamentos.findIndex(
              (x) => x.unidade_id === aps.unidade_id
            );
            editarInvestidor.email.apartamentos[apartamentoIdx].version += 1;
            setEditarInvestidor({
              ...editarInvestidor,
              email: {
                ...editarInvestidor.email,
                apartamentos: editarInvestidor.email.apartamentos,
              },
            });
          })
        );
      }

      try {
        var responseAjusteReceita = await api.post(
          "/patrimoniais/acionista/ajuste/" + patrimonialObj.value,
          {
            "cpf/cnpj": editarInvestidor["cpf/cnpj"],
            version: editarInvestidor.version + 1,
            comentario: editarInvestidor.comentario,
            usuario_id: cookies.usuario.id,
            ajuste: editarInvestidor.ajusteReceita,
            mes: patrimonialObj.dadosTempo.mesconsulta,
            ano: patrimonialObj.dadosTempo.ano,
          },
          {
            headers: { authorization: `Bearer ${cookies.usuario.accessToken}` },
          }
        );

        if (responseAjusteReceita.status !== 200)
          return notify("Erro ao adicionar ajuste a receber!");
      } catch (error) {
        console.error(error);
      }

      setEditarInvestidor({ ...editarInvestidor, modalVisivel: false });
      var foundIndex = patrimonialObj?.acionistas.findIndex(
        (x) => x["cpf/cnpj"] === editarInvestidor["cpf/cnpj"]
      );
      var acionistasAll = patrimonialObj?.acionistas;
      var auxAjusteReceitaInvestidor = 0;
      if (editarInvestidor.ajusteReceita !== "")
        auxAjusteReceitaInvestidor = parseFloat(editarInvestidor.ajusteReceita);
      if (patrimonialObj.modelo === 2) {
        var receitaAluguelAtual =
          parseFloat(editarInvestidor.acoes) * patrimonialObj.aluguelPorAcao;
        var retencaoFundoReservaAtual =
          patrimonialObj.fundoDeReservaPorAcao *
          parseFloat(editarInvestidor.acoes);
        var irrfAtual = 0;
        if (!editarInvestidor.isPJ && !!editarInvestidor?.reside_exterior)
          irrfAtual =
            (receitaAluguelAtual + auxAjusteReceitaInvestidor) *
            (editarInvestidor?.aliquota / 100);
        if (!editarInvestidor.isPJ && !!!editarInvestidor?.reside_exterior)
          irrfAtual =
            (receitaAluguelAtual + auxAjusteReceitaInvestidor) *
            patrimonialObj?.tabelaProgressivaIR?.find(
              (d) =>
                d.basecalculo_max >=
                receitaAluguelAtual + auxAjusteReceitaInvestidor &&
                d.basecalculo_min <
                receitaAluguelAtual + auxAjusteReceitaInvestidor
            )?.aliquota -
            patrimonialObj?.tabelaProgressivaIR?.find(
              (d) =>
                d.basecalculo_max >=
                receitaAluguelAtual + auxAjusteReceitaInvestidor &&
                d.basecalculo_min <
                receitaAluguelAtual + auxAjusteReceitaInvestidor
            )?.deducao;
        acionistasAll[foundIndex] = {
          ...editarInvestidor,
          version: editarInvestidor.version + 1,
          receitaAluguel: receitaAluguelAtual + auxAjusteReceitaInvestidor,
          retencaoFundoReserva: retencaoFundoReservaAtual,
          irrf: irrfAtual,
          valorLiquidoPagamento:
            receitaAluguelAtual +
            auxAjusteReceitaInvestidor -
            irrfAtual -
            retencaoFundoReservaAtual,
        };
      } else {
        acionistasAll[foundIndex] = {
          ...editarInvestidor,
          version: editarInvestidor.version + 1,
          receitaAluguel:
            parseFloat(editarInvestidor.acoes) *
            patrimonialObj.lucroADistribuirPorAcao +
            auxAjusteReceitaInvestidor,
        };
      }

      setPatrimonialObj({ ...patrimonialObj, acionistas: acionistasAll });
      setEditarInvestidor({ modalVisivel: false, auxVersion: 0, banco: "" });
      return notify("Investidor atualizado com sucesso!");
    } catch (error) {
      console.error(error);
      return notify("Erro ao atualizar investidor!");
    }
  }

  function buildModalDataAdicionaApartamentoFix() {
    return (
      <Modal
        show={modalDataAdicionaApartamentoFix.modalVisivel}
        onHide={() =>
          setModalDataAdicionaApartamentoFix({
            ...modalDataAdicionaApartamentoFix,
            modalVisivel: false,
          })
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="md"
        scrollable
      >
        <Modal.Header closeButton style={{ gap: "15px" }}>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Button variant="success" style={{ marginRight: "8px" }} disabled>
              <RiUserStarLine />
            </Button>{" "}
            Adicionar Apartamento Ausente
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {patrimonialObj?.acaoPorUnidade?.map((apartamento, index) => {
              return (
                // build apartamento fix
                <Col
                  md
                  style={{ marginBottom: "5px", marginTop: "5px" }}
                  key={"4" + index}
                >
                  <Form.Label>
                    Apartamentos {apartamento.unidade.split(" ")[0]}
                  </Form.Label>
                  <Form.Control
                    placeholder="Apartamentos"
                    aria-label="Apartamento"
                    name={"Apartamento-" + apartamento.unidade.split(" ")[0]}
                    id={"Apartamento-" + apartamento.unidade.split(" ")[0]}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        e.target.value = 0;
                      }
                      e.target.value = math.evaluate(e.target.value);

                      if (
                        modalDataAdicionaApartamentoFix.apartamentosNovos ===
                        undefined ||
                        index === 0
                      ) {
                        setModalDataAdicionaApartamentoFix({
                          ...modalDataAdicionaApartamentoFix,
                          apartamentosNovos: [
                            {
                              "cpf/cnpj": editarInvestidor["cpf/cnpj"],
                              unidade: apartamento.unidade,
                              apartamentos: e.target.value,
                              unidade_id: apartamento.unidade_id,
                              version: 1,
                            },
                          ],
                        });
                      } else {
                        setModalDataAdicionaApartamentoFix({
                          ...modalDataAdicionaApartamentoFix,
                          apartamentosNovos: [
                            ...modalDataAdicionaApartamentoFix?.apartamentosNovos.filter(
                              (d) => d.unidade_id !== apartamento.unidade_id
                            ),
                            {
                              "cpf/cnpj": editarInvestidor["cpf/cnpj"],
                              unidade: apartamento.unidade,
                              apartamentos: e.target.value,
                              unidade_id: apartamento.unidade_id,
                              version: 1,
                            },
                          ],
                        });
                      }
                    }}
                    required
                    onInvalid={(e) => {
                      e.target.classList.add("is-invalid");
                    }}
                    onChange={(e) => {
                      e.target.classList.remove("is-invalid");
                    }}
                  />
                </Col>
              );
            })}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => adicionarApartamentoFix()}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  async function adicionarApartamentoFix() {
    await Promise.all(
      modalDataAdicionaApartamentoFix?.apartamentosNovos.map(async (aps) => {
        var responseApartamentos = await api.post(
          "/patrimoniais/apartamentos",
          {
            "cpf/cnpj": aps["cpf/cnpj"],
            unidade_id: aps.unidade_id,
            apartamentos: aps.apartamentos,
            version: 1,
            usuario_id: cookies.usuario.id,
          },
          {
            headers: { authorization: `Bearer ${cookies.usuario.accessToken}` },
          }
        );

        if (responseApartamentos.status !== 200)
          return notify(
            "Erro ao adicionar apartamentos da unidade " +
            aps.unidade +
            " para o investidor" +
            modalDataAdicionaApartamentoFix.nome +
            "!"
          );

        var novoValorEmAcoes =
          modalDataAdicionaApartamentoFix.apartamentosNovos.reduce(
            (acc, cv) =>
            (acc +=
              cv.apartamentos *
              (patrimonialObj?.acaoPorUnidade?.find(
                (uni) => uni.unidade_id === cv.unidade_id
              )?.acoes +
                0)),
            0
          );

        setEditarInvestidor({
          ...editarInvestidor,
          acoes: novoValorEmAcoes,
          email: {
            ...editarInvestidor.email,
            apartamentos: modalDataAdicionaApartamentoFix.apartamentosNovos,
            acoes: novoValorEmAcoes,
          },
        });

        var idxAcionista = patrimonialObj.acionistas.findIndex(
          (d) => d["cpf/cnpj"] === modalDataAdicionaApartamentoFix["cpf/cnpj"]
        );
        var acionistaLista = patrimonialObj.acionistas;
        acionistaLista[idxAcionista].email.apartamentos =
          modalDataAdicionaApartamentoFix.apartamentosNovos;
        acionistaLista[idxAcionista].acoes = novoValorEmAcoes;
        setPatrimonialObj({ ...patrimonialObj, acionistas: acionistaLista });
        setModalDataAdicionaApartamentoFix({
          ...modalDataAdicionaApartamentoFix,
          modalVisivel: false,
        });
        notify("Apartamentos adicionados com sucesso");
      })
    );
  }

  function BuildModalInvestidor() {
    return (
      <Modal
        show={editarInvestidor.modalVisivel}
        onHide={() =>
          setEditarInvestidor({ ...editarInvestidor, modalVisivel: false })
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        scrollable
      >
        <Modal.Header closeButton style={{ gap: "15px" }}>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Button variant="success" style={{ marginRight: "8px" }} disabled>
              <RiUserStarLine />
            </Button>{" "}
            Editar investidor
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md style={{ marginBottom: "5px", marginTop: "5px" }}>
              <label>Nome Investidor</label>
              <FormControl
                placeholder="Nome"
                aria-label="Nome"
                defaultValue={editarInvestidor.nome}
                onChange={(e) =>
                  setEditarInvestidor({
                    ...editarInvestidor,
                    nome: e.target.value,
                  })
                }
              />
            </Col>
            <Col md style={{ marginBottom: "5px", marginTop: "5px" }}>
              {editarInvestidor.isPJ ? (
                <>
                  <label>CNPJ</label>
                  <FormControl
                    placeholder="CNPJ"
                    aria-label="CNPJ"
                    pattern="[0-9]{2}.[0-9]{3}.[0-9]{3}/[0-9]{4}-[0-9]{2}"
                    defaultValue={editarInvestidor["cpf/cnpj"]}
                    disabled
                  />
                </>
              ) : (
                <>
                  <label>CPF</label>
                  <FormControl
                    placeholder="CPF"
                    aria-label="CPF"
                    pattern="[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}"
                    defaultValue={editarInvestidor["cpf/cnpj"]}
                    disabled
                  />
                </>
              )}
            </Col>
          </Row>
          {patrimonialObj.modelo === 2 ? null : (
            <Row>
              <Col md style={{ marginBottom: "5px", marginTop: "5px" }}>
                <label>Valor a Receber</label>
                <FormControl
                  placeholder="Valor a Receber"
                  aria-label="Numero de ações"
                  type="text"
                  value={
                    "R$ " +
                    (
                      editarInvestidor.acoes *
                      patrimonialObj.lucroADistribuirPorAcao +
                      editarInvestidor.ajusteReceita
                    ).toLocaleString("pt-br", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  }
                  disabled
                />
              </Col>
              <Col md style={{ marginBottom: "5px", marginTop: "5px" }}>
                <label>
                  Ajuste Valor a Receber{" "}
                  <sup>
                    {" "}
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Popover
                          style={{ zIndex: 100000, cursor: "help" }}
                          id="popover-basic"
                        >
                          <Popover.Title as="h3">Ajuda</Popover.Title>
                          <Popover.Content>
                            <ul>
                              <li>
                                Utilize expressões matemáticas para facilitar!
                              </li>
                              <li>
                                Para inserir o valor a receber atual digite '='
                                (igual)!
                              </li>
                              <li>Valores serão somados ao valor a receber!</li>
                            </ul>
                          </Popover.Content>
                        </Popover>
                      }
                    >
                      <Badge variant="primary">?</Badge>
                    </OverlayTrigger>{" "}
                  </sup>
                </label>
                <FormControl
                  placeholder="(2+3)*5%"
                  aria-label="Expressão matemática"
                  type="text"
                  defaultValue={
                    editarInvestidor.ajusteReceita === 0
                      ? ""
                      : editarInvestidor.ajusteReceita
                  }
                  onChange={(e) =>
                  (e.target.value = e.target.value
                    .replaceAll(",", ".")
                    .replaceAll(
                      "=",
                      editarInvestidor.acoes *
                      patrimonialObj.lucroADistribuirPorAcao
                    ))
                  }
                  onBlur={(e) => {
                    if (e.target.value !== "") {
                      e.target.value = math.evaluate(e.target.value);
                      setEditarInvestidor({
                        ...editarInvestidor,
                        ajusteReceita: math.evaluate(e.target.value),
                      });
                    } else {
                      setEditarInvestidor({
                        ...editarInvestidor,
                        ajusteReceita: 0,
                      });
                    }
                  }}
                />
              </Col>
              <Col md style={{ marginBottom: "5px", marginTop: "5px" }}>
                <label>Nº de Ações</label>
                <FormControl
                  placeholder="Nº de Ações"
                  aria-label="Numero de ações"
                  type="text"
                  disabled={patrimonialObj.modelo === 2 ? true : false}
                  defaultValue={editarInvestidor.acoes}
                  onBlur={(e) => {
                    e.target.value = math.evaluate(e.target.value);
                    setEditarInvestidor({
                      ...editarInvestidor,
                      acoes: math.evaluate(e.target.value),
                    });
                  }}
                />
              </Col>
            </Row>
          )}
          {patrimonialObj.modelo === 2 ? (
            <Row>
              {editarInvestidor?.email?.apartamentos.map(
                (apartamento, index) => {
                  return (
                    <Col
                      md
                      style={{ marginBottom: "5px", marginTop: "5px" }}
                      key={"5" + index}
                    >
                      {/* BuildModalInvestidor mudaraqui */}
                      <label>
                        Apartamentos {apartamento?.unidade?.split(" ")?.[0]}
                      </label>
                      <FormControl
                        placeholder="Apartamento"
                        aria-label="Apartamento"
                        defaultValue={parseFloat(apartamento.apartamentos)}
                        onBlur={(e) => {
                          e.target.value = math.evaluate(e.target.value);
                          var apartamentoIdx =
                            editarInvestidor.email.apartamentos.findIndex(
                              (x) => x.unidade_id === apartamento.unidade_id
                            );
                          editarInvestidor.email.apartamentos[
                            apartamentoIdx
                          ].apartamentos = e.target.value;
                          var acoesAtualizadas =
                            editarInvestidor.email.apartamentos.reduce(
                              (acc, cv) =>
                              (acc +=
                                parseFloat(cv.apartamentos) *
                                parseFloat(
                                  patrimonialObj.acaoPorUnidade.find(
                                    (d) => d.unidade_id === cv.unidade_id
                                  ).acoes
                                )),
                              0
                            );
                          setEditarInvestidor({
                            ...editarInvestidor,
                            acoes: acoesAtualizadas,
                            email: {
                              ...editarInvestidor.email,
                              apartamentos: editarInvestidor.email.apartamentos,
                            },
                          });
                        }}
                      />
                    </Col>
                  );
                }
              )}
              <Col md style={{ marginBottom: "5px", marginTop: "5px" }}>
                <label>Nº de Ações</label>
                <FormControl
                  placeholder="Nº de Ações"
                  aria-label="Numero de ações"
                  type="text"
                  disabled={patrimonialObj.modelo === 2 ? true : false}
                  value={editarInvestidor.acoes}
                />
              </Col>
            </Row>
          ) : null}
          {patrimonialObj.modelo === 2 ? (
            <Row>
              <Col md style={{ marginBottom: "5px", marginTop: "5px" }}>
                <label>Valor a Receber</label>
                <FormControl
                  placeholder="Valor a Receber"
                  aria-label="Numero de ações"
                  type="text"
                  value={
                    "R$ " +
                    parseFloat(
                      parseFloat(editarInvestidor.acoes) *
                      patrimonialObj.aluguelPorAcao +
                      editarInvestidor.ajusteReceita
                    ).toLocaleString("pt-br", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  }
                  disabled
                />
              </Col>
              <Col md style={{ marginBottom: "5px", marginTop: "5px" }}>
                <label>
                  Ajuste Valor a Receber{" "}
                  <sup>
                    {" "}
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Popover
                          style={{ zIndex: 100000, cursor: "help" }}
                          id="popover-basic"
                        >
                          <Popover.Title as="h3">Ajuda</Popover.Title>
                          <Popover.Content>
                            <ul>
                              <li>
                                Utilize expressões matemáticas para facilitar!
                              </li>
                              <li>
                                Para inserir o valor a receber atual digite '='
                                (igual)!
                              </li>
                              <li>Valores serão somados ao valor a receber!</li>
                            </ul>
                          </Popover.Content>
                        </Popover>
                      }
                    >
                      <Badge variant="primary">?</Badge>
                    </OverlayTrigger>{" "}
                  </sup>
                </label>
                <FormControl
                  placeholder="(2+3)*5%"
                  aria-label="Expressão matemática"
                  type="text"
                  defaultValue={
                    editarInvestidor.ajusteReceita === 0
                      ? ""
                      : editarInvestidor.ajusteReceita
                  }
                  onChange={(e) =>
                  (e.target.value = e.target.value
                    .replaceAll(",", ".")
                    .replaceAll(
                      "=",
                      editarInvestidor.acoes * patrimonialObj.aluguelPorAcao
                    ))
                  }
                  onBlur={(e) => {
                    if (e.target.value !== "") {
                      e.target.value = math.evaluate(e.target.value);
                      setEditarInvestidor({
                        ...editarInvestidor,
                        ajusteReceita: math.evaluate(e.target.value),
                      });
                    } else {
                      setEditarInvestidor({
                        ...editarInvestidor,
                        ajusteReceita: 0,
                      });
                    }
                  }}
                />
              </Col>
            </Row>
          ) : null}
          {/* <Row >
                        <Col md={3} style={{ marginBottom: '5px', marginTop: '5px' }} >
                            <Form.Label>Reside no Exterior?</Form.Label>
                            <Form.Control
                                placeholder="Reside no Exterior"
                                aria-label="Reside no Exterior"
                                name='Reside no Exterior'
                                type='checkbox'
                                defaultChecked={editarInvestidor?.reside_exterior}
                                onChange={(e) => { setEditarInvestidor({ ...editarInvestidor, reside_exterior: e.target.checked }) }}
                            />
                        </Col>
                        <Col>
                            <Form.Label>País residencia</Form.Label>
                            <Select
                                isDisabled={!!!editarInvestidor?.reside_exterior}
                                name="colors"
                                options={patrimonialObj?.codigosPaises}
                                closeMenuOnSelect={true}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                defaultValue={patrimonialObj?.codigosPaises?.filter(d => d.value === editarInvestidor?.pais_residencia)}
                                onChange={(e) => { setEditarInvestidor({ ...editarInvestidor, pais_residencia: e.value, aliquota: e.aliquota }) }}
                            />
                        </Col>
                        <Col>
                            <label>Aliquota IR</label>
                            <InputGroup>
                                <FormControl
                                    disabled={!!!editarInvestidor?.pais_residencia || !!!editarInvestidor?.reside_exterior}
                                    placeholder="aliquota"
                                    type='number'
                                    id='aliquotaIRInput'
                                    aria-label="aliquota"
                                    value={editarInvestidor?.aliquota}
                                    onChange={e => setEditarInvestidor({ ...editarInvestidor, aliquota: e.target.value })}
                                    onBlur={(e) => { setEditarInvestidor({ ...editarInvestidor, aliquota: e.target.value }) }}
                                />
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                                </InputGroup.Prepend>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col hidden={editarInvestidor.codigobanco_id !== null} md style={{ marginBottom: '5px', marginTop: '5px' }} >
                            <label>Banco</label>
                            <FormControl
                                placeholder="Banco"
                                aria-label="Banco"
                                disabled
                                defaultValue={editarInvestidor.banco}
                                onChange={(e) => setEditarInvestidor({ ...editarInvestidor, banco: e.target.value })}
                            />
                        </Col>
                        <Col md={3} style={{ marginBottom: '5px', marginTop: '5px' }} >
                            <Form.Label>É Pix?</Form.Label>
                            <Form.Control
                                placeholder="isPix"
                                aria-label="isPix"
                                name='isPix'
                                type='checkbox'
                                defaultChecked={editarInvestidor.isPix}
                                onChange={(e) => { setEditarInvestidor({ ...editarInvestidor, isPix: e.target.checked }) }}
                            />
                        </Col>
                        <Col hidden={!editarInvestidor?.isPix} md style={{ marginBottom: '5px', marginTop: '5px' }} >
                            <Form.Label>Tipo Pix</Form.Label>
                            <Select
                                name="colors"
                                options={[{ label: "Celular", value: "01" }, { label: "Email", value: "02" }, { label: "CPF/CNPJ", value: "03" }, { label: "Aleatória", value: "04" }]}
                                closeMenuOnSelect={true}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                defaultValue={[{ label: "Celular", value: "01" }, { label: "Email", value: "02" }, { label: "CPF/CNPJ", value: "03" }, { label: "Aleatória", value: "04" }].filter(d => d.value === editarInvestidor.tipoPix)}
                                onChange={(e) => { setEditarInvestidor({ ...editarInvestidor, tipoPix: e.value }) }}
                            />
                        </Col>
                        <Col md style={{ marginBottom: '5px', marginTop: '5px' }} >
                            <label style={editarInvestidor.codigobanco_id === null ? { color: 'red' } : {}}>Banco</label>
                            <Select
                                name="colors"
                                options={codigosBancosLista.reduce((acc, cv) => acc = [...acc, { label: cv.codigo + ' - ' + cv.banco, value: cv.codigo }], [])}
                                closeMenuOnSelect={true}
                                className="basic-multi-select"
                                defaultValue={codigosBancosLista.reduce((acc, cv) => acc = [...acc, { label: cv.codigo + ' - ' + cv.banco, value: cv.codigo }], []).filter(d => d.value === editarInvestidor.codigobanco_id)}
                                classNamePrefix="select"
                                onChange={(e) => { setEditarInvestidor({ ...editarInvestidor, codigobanco_id: e.value, banco: e.label?.split(' - ')[1] }) }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        {editarInvestidor.isPix
                            ? <Col md style={{ marginBottom: '5px', marginTop: '5px' }} >
                                <label>Chave Pix</label>
                                <FormControl
                                    placeholder="Chave Pix"
                                    aria-label="Chave Pix"
                                    defaultValue={editarInvestidor.pix}
                                    onChange={(e) => setEditarInvestidor({ ...editarInvestidor, pix: e.target.value })}
                                />
                            </Col>
                            : null}
                        <Col md style={{ marginBottom: '5px', marginTop: '5px' }} >
                            <label>Agência</label>
                            <FormControl
                                placeholder="Agência"
                                aria-label="Agência"
                                defaultValue={editarInvestidor.agencia}
                                onChange={(e) => setEditarInvestidor({ ...editarInvestidor, agencia: e.target.value })}
                            />
                        </Col>
                        <Col md style={{ marginBottom: '5px', marginTop: '5px' }} >
                            <label>Conta Corrente</label>
                            <FormControl
                                placeholder="Conta corrente"
                                aria-label="Conta corrente"
                                defaultValue={editarInvestidor.conta}
                                onChange={(e) => setEditarInvestidor({ ...editarInvestidor, conta: e.target.value })}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md style={{ marginBottom: '5px', marginTop: '5px' }} >
                            <label>Destinatário</label>
                            <FormControl
                                placeholder="Email destinatário"
                                aria-label="Email destinatário"
                                type="email"
                                defaultValue={editarInvestidor.destinatario}
                                onChange={(e) => setEditarInvestidor({ ...editarInvestidor, destinatario: e.target.value, email: { ...editarInvestidor.email, destinatario: e.target.value } })}
                            />
                        </Col>
                        <Col md style={{ marginBottom: '5px', marginTop: '5px' }} >
                            <label>Com Cópia</label>
                            <FormControl
                                placeholder="Email cópia"
                                aria-label="Email cópia"
                                type="email"
                                defaultValue={editarInvestidor.copia}
                                onChange={(e) => setEditarInvestidor({ ...editarInvestidor, copia: e.target.value, email: { ...editarInvestidor.email, copia: e.target.value } })}
                            />
                        </Col>
                    </Row> */}
          <Row>
            <Col md style={{ marginBottom: "5px", marginTop: "5px" }}>
              <label>Observações</label>
              <FormControl
                placeholder="Comentário"
                aria-label="Comentário"
                type="textArea"
                defaultValue={editarInvestidor?.comentario}
                onChange={(e) =>
                  setEditarInvestidor({
                    ...editarInvestidor,
                    comentario: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "space-between" }}>
          <div>
            <Button
              style={{ marginRight: "5px" }}
              size="sm"
              variant="outline-danger"
              onClick={() =>
                patrimonialObj?.assinatura?.length === 0
                  ? setModalDesativarAcionista(true)
                  : notify("Edição de acionistas encerrada!")
              }
            >
              Desativar
            </Button>
          </div>
          <div>
            <Button
              style={{ marginLeft: "5px" }}
              size="sm"
              variant="outline-primary"
              onClick={() =>
                window.open(
                  "http://analytics.buphotels.com.br:3333/cartas/acionistas/" +
                  editarInvestidor?.email?.anoReferencia +
                  "/" +
                  editarInvestidor?.email?.mesReferencia.replaceAll(
                    "ç",
                    "c"
                  ) +
                  "/" +
                  editarInvestidor?.email?.patrimonial
                    ?.replaceAll(" ", "_")
                    ?.replaceAll("/", "_") +
                  "/" +
                  editarInvestidor["cpf/cnpj"]
                    .replaceAll("/", "_")
                    .replaceAll(".", "_")
                    .replaceAll("-", "_") +
                  editarInvestidor?.nome
                    ?.replaceAll(" ", "_")
                    ?.replaceAll("/", "") +
                  ".pdf",
                  "blank"
                )
              }
            >
              Abrir carta
            </Button>
            <Button
              style={{ marginLeft: "5px" }}
              size="sm"
              variant="outline-primary"
              onClick={() => enviarEmailInvestidorUnico(editarInvestidor)}
            >
              Reenviar Carta
            </Button>
            <Button
              style={{ marginLeft: "5px" }}
              size="sm"
              variant="outline-primary"
              onClick={() => gerarCarta(editarInvestidor)}
            >
              Gerar Carta
            </Button>
            <Button
              style={{ marginLeft: "5px" }}
              size="sm"
              onClick={() =>
                editarInvestidor.codigobanco_id === null
                  ? notify("Atualizar o codigo do banco para continuar")
                  : atualizarInvestidor()
              }
            >
              Salvar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }

  function BuildModalDesativarAcionista() {
    return (
      <Modal
        show={modalDesativarAcionista}
        onHide={() => setModalDesativarAcionista(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Desativar Acionista</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Deseja Realmente Desativar o acionista {editarInvestidor.nome}?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => setModalDesativarAcionista(false)}
          >
            Fechar
          </Button>
          <Button variant="outline-danger" onClick={() => desativarAcionista()}>
            Desativar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  async function desativarAcionista() {
    setModalDesativarAcionista(false);
    try {
      await api.post(
        `/patrimoniais/desativar/acionistas`,
        {
          "cpf/cnpj": editarInvestidor["cpf/cnpj"],
          version: editarInvestidor.version,
          usuario_id: cookies.usuario.id,
          comentario: "Acionista desativado!",
          patrimonial_id: patrimonialObj.value,
        },
        { headers: { authorization: `Bearer ${cookies.usuario.accessToken}` } }
      );
    } catch (error) {
      console.error(error);
      setEditarInvestidor({ ...editarInvestidor, modalVisivel: false });
      return notify("Erro ao desativar acionista!");
    }
    notify("Acionista desativado com sucesso!");
    setPatrimonialObj({
      ...patrimonialObj,
      acionistas: patrimonialObj.acionistas.filter(
        (acionista) => acionista["cpf/cnpj"] !== editarInvestidor["cpf/cnpj"]
      ),
    });
    setEditarInvestidor({
      ...editarInvestidor,
      modalVisivel: false,
      auxVersion: 0,
      banco: "",
    });
  }

  function BuildModalProprietarios() {
    return (
      <Modal
        show={editarProprietarios.modalVisivel}
        onHide={() =>
          setEditarProprietarios({
            ...editarProprietarios,
            modalVisivel: false,
          })
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        scrollable
      >
        <Modal.Header closeButton style={{ gap: "15px" }}>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Button variant="success" style={{ marginRight: "8px" }} disabled>
              <RiUserStarLine />
            </Button>{" "}
            Editar Proprietarios
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md style={{ marginBottom: "5px", marginTop: "5px" }}>
              <label>Proprietario</label>
              <FormControl
                placeholder="Nome"
                aria-label="Nome"
                defaultValue={editarProprietarios.proprietario}
                onChange={(e) =>
                  setEditarProprietarios({
                    ...editarProprietarios,
                    proprietario: e.target.value,
                  })
                }
              />
            </Col>
            <Col md style={{ marginBottom: "5px", marginTop: "5px" }}>
              <label>CNPJ</label>
              <FormControl
                placeholder="CNPJ"
                aria-label="CNPJ"
                pattern="[0-9]{2}.[0-9]{3}.[0-9]{3}/[0-9]{4}-[0-9]{2}"
                defaultValue={editarProprietarios["cnpj"]}
                disabled
              />
            </Col>
            <Col md style={{ marginBottom: "5px", marginTop: "5px" }}>
              <label>Percentual</label>
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip style={{ zIndex: 100000 }}>
                    Use o ponto "." para separar os centavos!
                  </Tooltip>
                }
              >
                <InputGroup className="mb-3">
                  <FormControl
                    placeholder="Percentual de participação"
                    aria-label="Percentual de participação"
                    type="text"
                    defaultValue={editarProprietarios.percentual * 100}
                    onChange={(e) =>
                      (e.target.value = e.target.value.replaceAll(",", "."))
                    }
                    onBlur={(e) => {
                      e.target.value = math.evaluate(e.target.value);
                      setEditarProprietarios({
                        ...editarProprietarios,
                        percentual: math.evaluate(e.target.value) / 100,
                      });
                    }}
                  />
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                  </InputGroup.Prepend>
                </InputGroup>
              </OverlayTrigger>
            </Col>
          </Row>

          <Row>
            <Col
              hidden={editarProprietarios.codigobanco_id !== null}
              md
              style={{ marginBottom: "5px", marginTop: "5px" }}
            >
              <label>Banco</label>
              <FormControl
                placeholder="Banco"
                aria-label="Banco"
                disabled
                defaultValue={editarProprietarios.banco}
                onChange={(e) =>
                  setEditarProprietarios({
                    ...editarProprietarios,
                    banco: e.target.value,
                  })
                }
              />
            </Col>
            <Col md={3} style={{ marginBottom: "5px", marginTop: "5px" }}>
              <Form.Label>É Pix?</Form.Label>
              <Form.Control
                placeholder="isPix"
                aria-label="isPix"
                name="isPix"
                type="checkbox"
                defaultChecked={editarProprietarios.isPix}
                onChange={(e) => {
                  setEditarProprietarios({
                    ...editarProprietarios,
                    isPix: e.target.checked,
                  });
                }}
              />
            </Col>
            <Col
              hidden={!editarProprietarios?.isPix}
              md
              style={{ marginBottom: "5px", marginTop: "5px" }}
            >
              <Form.Label>Tipo Pix</Form.Label>
              <Select
                name="colors"
                options={[
                  { label: "Celular", value: "01" },
                  { label: "Email", value: "02" },
                  { label: "CPF/CNPJ", value: "03" },
                  { label: "Aleatória", value: "04" },
                ]}
                closeMenuOnSelect={true}
                className="basic-multi-select"
                classNamePrefix="select"
                defaultValue={[
                  { label: "Celular", value: "01" },
                  { label: "Email", value: "02" },
                  { label: "CPF/CNPJ", value: "03" },
                  { label: "Aleatória", value: "04" },
                ].filter((d) => d.value === editarProprietarios.tipoPix)}
                onChange={(e) => {
                  setEditarProprietarios({
                    ...editarProprietarios,
                    tipoPix: e.value,
                  });
                }}
              />
            </Col>
            <Col md style={{ marginBottom: "5px", marginTop: "5px" }}>
              <label
                style={
                  editarProprietarios.codigobanco_id === null
                    ? { color: "red" }
                    : {}
                }
              >
                Banco
              </label>
              <Select
                name="colors"
                options={codigosBancosLista.reduce(
                  (acc, cv) =>
                  (acc = [
                    ...acc,
                    { label: cv.codigo + " - " + cv.banco, value: cv.codigo },
                  ]),
                  []
                )}
                closeMenuOnSelect={true}
                className="basic-multi-select"
                defaultValue={codigosBancosLista
                  .reduce(
                    (acc, cv) =>
                    (acc = [
                      ...acc,
                      {
                        label: cv.codigo + " - " + cv.banco,
                        value: cv.codigo,
                      },
                    ]),
                    []
                  )
                  .filter(
                    (d) => d.value === editarProprietarios.codigobanco_id
                  )}
                classNamePrefix="select"
                onChange={(e) => {
                  setEditarProprietarios({
                    ...editarProprietarios,
                    codigobanco_id: e.value,
                    banco: e.label?.split(" - ")[1],
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            {editarProprietarios.isPix ? (
              <Col md style={{ marginBottom: "5px", marginTop: "5px" }}>
                <label>Chave Pix</label>
                <FormControl
                  placeholder="Chave Pix"
                  aria-label="Chave Pix"
                  defaultValue={editarProprietarios.pix}
                  onChange={(e) =>
                    setEditarProprietarios({
                      ...editarProprietarios,
                      pix: e.target.value,
                    })
                  }
                />
              </Col>
            ) : null}
            <Col md style={{ marginBottom: "5px", marginTop: "5px" }}>
              <label>Agência</label>
              <FormControl
                placeholder="Agência"
                aria-label="Agência"
                defaultValue={editarProprietarios.agencia}
                onChange={(e) =>
                  setEditarProprietarios({
                    ...editarProprietarios,
                    agencia: e.target.value,
                  })
                }
              />
            </Col>
            <Col md style={{ marginBottom: "5px", marginTop: "5px" }}>
              <label>Conta Corrente</label>
              <FormControl
                placeholder="Conta corrente"
                aria-label="Conta corrente"
                defaultValue={editarProprietarios.conta}
                onChange={(e) =>
                  setEditarProprietarios({
                    ...editarProprietarios,
                    conta: e.target.value,
                  })
                }
              />
            </Col>
          </Row>

          {/* <Row>
                        <Col md style={{ marginBottom: '5px', marginTop: '5px' }} >
                            <label>Banco</label>
                            <FormControl
                                placeholder="Banco"
                                aria-label="Banco"
                                defaultValue={editarProprietarios.banco}
                                onChange={(e) => setEditarProprietarios({ ...editarProprietarios, banco: e.target.value })}
                            />
                        </Col>
                        {editarProprietarios.banco.toLowerCase() === 'pix'
                            ? <Col md style={{ marginBottom: '5px', marginTop: '5px' }} >
                                <label>Chave Pix</label>
                                <FormControl
                                    placeholder="Chave Pix"
                                    aria-label="Chave Pix"
                                    defaultValue={editarProprietarios.agencia}
                                    onChange={(e) => setEditarProprietarios({ ...editarProprietarios, agencia: e.target.value })}
                                />
                            </Col>
                            : <>
                                <Col md style={{ marginBottom: '5px', marginTop: '5px' }} >
                                    <label>Agência</label>
                                    <FormControl
                                        placeholder="Agência"
                                        aria-label="Agência"
                                        defaultValue={editarProprietarios.agencia}
                                        onChange={(e) => setEditarProprietarios({ ...editarProprietarios, agencia: e.target.value })}
                                    />
                                </Col>
                                <Col md style={{ marginBottom: '5px', marginTop: '5px' }} >
                                    <label>Conta Corrente</label>
                                    <FormControl
                                        placeholder="Conta corrente"
                                        aria-label="Conta corrente"
                                        defaultValue={editarProprietarios.conta}
                                        onChange={(e) => setEditarProprietarios({ ...editarProprietarios, conta: e.target.value })}
                                    />
                                </Col>
                            </>
                        }

                    </Row> */}
          <Row>
            <Col md style={{ marginBottom: "5px", marginTop: "5px" }}>
              <label>Destinatário</label>
              <FormControl
                placeholder="Email destinatário"
                aria-label="Email destinatário"
                type="email"
                defaultValue={editarProprietarios.destinatario}
                onChange={(e) =>
                  setEditarProprietarios({
                    ...editarProprietarios,
                    destinatario: e.target.value,
                    email: {
                      ...editarProprietarios.email,
                      destinatario: e.target.value,
                    },
                  })
                }
              />
            </Col>
            <Col md style={{ marginBottom: "5px", marginTop: "5px" }}>
              <label>Com Cópia</label>
              <FormControl
                placeholder="Email cópia"
                aria-label="Email cópia"
                type="email"
                defaultValue={editarProprietarios.copia}
                onChange={(e) =>
                  setEditarProprietarios({
                    ...editarProprietarios,
                    copia: e.target.value,
                    email: {
                      ...editarProprietarios.email,
                      copia: e.target.value,
                    },
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md style={{ marginBottom: "5px", marginTop: "5px" }}>
              <label>Observações</label>
              <FormControl
                placeholder="Comentário"
                aria-label="Comentário"
                type="textArea"
                defaultValue={editarProprietarios?.comentario}
                onChange={(e) =>
                  setEditarProprietarios({
                    ...editarProprietarios,
                    comentario: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "right" }}>
          <Button
            style={{ marginLeft: "5px" }}
            variant="outline-primary"
            size="sm"
            onClick={() =>
              window.open(
                "http://analytics.buphotels.com.br:3333/cartas/proprietarios/" +
                editarProprietarios?.email?.anoReferencia +
                "/" +
                editarProprietarios?.email?.mesReferencia.replaceAll(
                  "ç",
                  "c"
                ) +
                "/" +
                editarProprietarios?.email?.patrimonial
                  ?.replaceAll(" ", "_")
                  ?.replaceAll("/", "_") +
                "/" +
                editarProprietarios["cnpj"]
                  .replaceAll("/", "_")
                  .replaceAll(".", "_")
                  .replaceAll("-", "_") +
                editarProprietarios?.proprietario
                  ?.replaceAll(" ", "_")
                  ?.replaceAll("/", "") +
                ".pdf",
                "blank"
              )
            }
          >
            Abrir carta
          </Button>
          <Button
            style={{ marginLeft: "5px" }}
            variant="outline-primary"
            size="sm"
            onClick={() => enviarEmailInvestidorUnico(editarInvestidor)}
          >
            Reenviar Carta
          </Button>
          <Button
            style={{ marginLeft: "5px" }}
            variant="outline-primary"
            size="sm"
            onClick={() => gerarCarta(editarInvestidor)}
          >
            Gerar Carta
          </Button>
          <Button
            style={{ marginLeft: "5px" }}
            size="sm"
            onClick={() =>
              editarProprietarios.codigobanco_id === null
                ? notify("Atualizar o codigo do banco para continuar")
                : atualizarProprietarios()
            }
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  function BuildModalOutrasDespesas() {
    return (
      <Modal
        show={outrasDespesas.modalVisivel}
        onHide={() =>
          setOutrasDespesas({ ...outrasDespesas, modalVisivel: false })
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header closeButton style={{ gap: "15px" }}>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Button variant="warning" style={{ marginRight: "8px" }} disabled>
              <MdAttachMoney />
            </Button>{" "}
            Outras Despesas
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormControl
            placeholder="Descreva a despesa"
            type="text"
            size="sm"
            style={{ marginBottom: "10px" }}
            value={outrasDespesas.descricao}
            onChange={(e) =>
              setOutrasDespesas({
                ...outrasDespesas,
                descricao: e.target.value,
              })
            }
          />

          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip style={{ zIndex: 100000 }}>
                Use o ponto "." para separar os centavos!
              </Tooltip>
            }
          >
            <InputGroup className="mb-3" size="sm">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">R$</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                id="outrasDespesasValor"
                placeholder="00.00"
                type="text"
                defaultValue={outrasDespesas.valor}
                onChange={(e) =>
                  (e.target.value = e.target.value.replaceAll(",", "."))
                }
                onBlur={(e) => {
                  e.target.value = math.evaluate(e.target.value);
                  setOutrasDespesas({
                    ...outrasDespesas,
                    valor: math.evaluate(e.target.value),
                  });
                }}
              />
            </InputGroup>
          </OverlayTrigger>
        </Modal.Body>
        <Modal.Footer
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            <Button
              size="sm"
              variant="outline-primary"
              onClick={() => {
                document.getElementById("outrasDespesasValor").value = 0;
                setOutrasDespesas({
                  ...outrasDespesas,
                  valor: 0,
                  descricao: "",
                });
              }}
            >
              Limpar
            </Button>
          </div>
          <div>
            <Button
              style={{ marginRight: "5px" }}
              size="sm"
              variant="outline-danger"
              onClick={() =>
                setOutrasDespesas({
                  ...outrasDespesas,
                  valor: patrimonialObj?.outrasDespesas[0]?.valor
                    ? patrimonialObj?.outrasDespesas[0]?.valor
                    : 0,
                  descricao: patrimonialObj?.outrasDespesas[0]?.descricao
                    ? patrimonialObj?.outrasDespesas[0]?.descricao
                    : "",
                  modalVisivel: false,
                })
              }
            >
              Fechar
            </Button>
            <Button
              style={{ marginLeft: "5px" }}
              size="sm"
              onClick={() => adicionarOutrasDespesas()}
            >
              Salvar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }

  function BuildModalCorrecoes() {
    return (
      <Modal
        show={outrasDespesas.modalVisivel}
        onHide={() =>
          setOutrasDespesas({ ...outrasDespesas, modalVisivel: false })
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header closeButton style={{ gap: "15px" }}>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Button variant="warning" style={{ marginRight: "8px" }} disabled>
              <MdAttachMoney />
            </Button>{" "}
            Outras Despesas
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormControl
            placeholder="Descreva a despesa"
            type="text"
            size="sm"
            style={{ marginBottom: "10px" }}
            value={outrasDespesas.descricao}
            onChange={(e) =>
              setOutrasDespesas({
                ...outrasDespesas,
                descricao: e.target.value,
              })
            }
          />
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip style={{ zIndex: 100000 }}>
                Use o ponto "." para separar os centavos!
              </Tooltip>
            }
          >
            <InputGroup className="mb-3" size="sm">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">R$</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                id="outrasDespesasValor"
                placeholder="00,00"
                type="text"
                defaultValue={outrasDespesas.valor}
                // onChange={(e) => setOutrasDespesas({ ...outrasDespesas, valor: e.target.value })}
                onChange={(e) =>
                  (e.target.value = e.target.value.replaceAll(",", "."))
                }
                onBlur={(e) => {
                  e.target.value = math.evaluate(e.target.value);
                  setOutrasDespesas({
                    ...outrasDespesas,
                    valor: math.evaluate(e.target.value),
                  });
                }}
              />
            </InputGroup>
          </OverlayTrigger>
        </Modal.Body>
        <Modal.Footer
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            <Button
              size="sm"
              variant="outline-primary"
              onClick={() => {
                document.getElementById("outrasDespesasValor").value = 0;
                setOutrasDespesas({
                  ...outrasDespesas,
                  valor: 0,
                  descricao: "",
                });
              }}
            >
              Limpar
            </Button>
          </div>
          <div>
            <Button
              style={{ marginRight: "5px" }}
              size="sm"
              variant="outline-danger"
              onClick={() =>
                setOutrasDespesas({
                  ...outrasDespesas,
                  valor: patrimonialObj?.outrasDespesas[0]?.valor
                    ? patrimonialObj?.outrasDespesas[0]?.valor
                    : 0,
                  descricao: patrimonialObj?.outrasDespesas[0]?.descricao
                    ? patrimonialObj?.outrasDespesas[0]?.descricao
                    : "",
                  modalVisivel: false,
                })
              }
            >
              Fechar
            </Button>
            <Button
              style={{ marginLeft: "5px" }}
              size="sm"
              onClick={() => adicionarOutrasDespesas()}
            >
              Salvar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }

  function BuildModalAjustes() {
    return (
      <Modal
        show={ajusteReceita.modalVisivel}
        onHide={() =>
          setAjusteReceita({ ...ajusteReceita, modalVisivel: false })
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header closeButton style={{ gap: "15px" }}>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Button variant="warning" style={{ marginRight: "8px" }} disabled>
              <MdAttachMoney />
            </Button>{" "}
            Ajuste
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormControl
            placeholder="Descreva o ajuste"
            type="text"
            size="sm"
            style={{ marginBottom: "10px" }}
            value={ajusteReceita.descricao}
            onChange={(e) =>
              setAjusteReceita({ ...ajusteReceita, descricao: e.target.value })
            }
          />
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip style={{ zIndex: 100000 }}>
                Use o ponto "." para separar os centavos!
              </Tooltip>
            }
          >
            <InputGroup className="mb-3" size="sm">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">R$</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                id="ajusteReceitaValor"
                placeholder="00,00"
                type="text"
                defaultValue={ajusteReceita.valor}
                // onChange={(e) => setAjusteReceita({ ...ajusteReceita, valor: e.target.value })}
                onChange={(e) =>
                  (e.target.value = e.target.value.replaceAll(",", "."))
                }
                onBlur={(e) => {
                  e.target.value = math.evaluate(e.target.value);
                  setAjusteReceita({
                    ...ajusteReceita,
                    valor: math.evaluate(e.target.value),
                  });
                }}
              />
            </InputGroup>
          </OverlayTrigger>
        </Modal.Body>
        <Modal.Footer
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            <Button
              size="sm"
              variant="outline-primary"
              onClick={() => {
                document.getElementById("ajusteReceitaValor").value = 0;
                setAjusteReceita({ ...ajusteReceita, valor: 0, descricao: "" });
              }}
            >
              Limpar
            </Button>
          </div>
          <div>
            <Button
              style={{ marginRight: "5px" }}
              size="sm"
              variant="outline-danger"
              onClick={() =>
                setAjusteReceita({
                  ...ajusteReceita,
                  valor: patrimonialObj?.ajusteReceita[0]?.valor
                    ? patrimonialObj?.ajusteReceita[0]?.valor
                    : 0,
                  descricao: patrimonialObj?.ajusteReceita[0]?.descricao
                    ? patrimonialObj?.ajusteReceita[0]?.descricao
                    : "",
                  modalVisivel: false,
                })
              }
            >
              Fechar
            </Button>
            <Button
              style={{ marginLeft: "5px" }}
              size="sm"
              onClick={() => adicionarAjusteReceita()}
            >
              Salvar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }

  function BuildModalObs() {
    return (
      <Modal
        show={observacao.modalVisivel}
        onHide={() => setObservacao({ ...observacao, modalVisivel: false })}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header closeButton style={{ gap: "15px" }}>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Button variant="warning" style={{ marginRight: "8px" }} disabled>
              <BiCommentEdit />
            </Button>
            Observação
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormControl
            as="textarea"
            className="form-control"
            placeholder="Digite aqui sua observação"
            value={observacao.comentario}
            onChange={(e) =>
              setObservacao({ ...observacao, comentario: e.target.value })
            }
          />
        </Modal.Body>
        <Modal.Footer
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            <Button
              size="sm"
              variant="outline-primary"
              onClick={() => setObservacao({ ...observacao, comentario: "" })}
            >
              Limpar
            </Button>
          </div>
          <div>
            <Button
              style={{ marginRight: "5px" }}
              size="sm"
              variant="outline-danger"
              onClick={() =>
                setObservacao({
                  ...observacao,
                  comentario: patrimonialObj?.comentario[0]?.comentario
                    ? patrimonialObj?.comentario[0]?.comentario
                    : "",
                  modalVisivel: false,
                })
              }
            >
              Fechar
            </Button>
            <Button
              style={{ marginLeft: "5px" }}
              size="sm"
              onClick={() => adicionarObs()}
            >
              Salvar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }

  async function atualizarAlugueis() {
    try {
      var res = await api.post(
        "/patrimoniais/alugueis",
        {
          id: editarAlugueis.id,
          titulo: editarAlugueis.titulo,
          valor: editarAlugueis.valor,
          mes: patrimonialObj?.dadosTempo?.mesconsulta,
          ano: patrimonialObj?.dadosTempo?.ano,
          patrimonial_id: patrimonialObj?.value,
        },
        { headers: { authorization: `Bearer ${cookies.usuario.accessToken}` } }
      );
    } catch (error) {
      console.error(error);
      return notify(
        editarAlugueis.id === null
          ? "Erro ao criar aluguel!"
          : "Erro ao atualizar aluguel!"
      );
    }

    if (res.status !== 200)
      return notify(
        editarAlugueis.id === null
          ? "Erro ao criar"
          : "Erro ao atualizar" + " aluguel!"
      );

    return loadPatrimonialAtual();

    var alugueisAux = patrimonialObj?.alugueis.filter(
      (aluguel) => aluguel.id + "" !== res.data[0].id + ""
    );
    alugueisAux.push(res.data[0]);

    var totalAluguelAtual =
      alugueisAux.reduce((acc, cv) => (acc += cv.valor), 0) +
      patrimonialObj?.aluguelLiquido;
    var novoPis = totalAluguelAtual * 0.0065;
    var novoirpj = totalAluguelAtual * 0.048;
    var novoadicionalIRPJ = parseFloat(
      ((totalAluguelAtual * 0.32 * 3 - 60000) * 0.1) / 3
    );
    var novoCofins = totalAluguelAtual * 0.03;
    var novoCsll = totalAluguelAtual * 0.0288;

    if (novoadicionalIRPJ < 0) novoadicionalIRPJ = 0;

    var somaOutrasDespesas = patrimonialObj.outrasDespesas.reduce(
      (acc, cv) => (acc += cv.valor),
      0
    );

    var atualImpostosEDespesas =
      novoPis +
      novoirpj +
      novoadicionalIRPJ +
      novoCofins +
      novoCsll +
      somaOutrasDespesas;
    var atualLucroADistribuir = totalAluguelAtual - atualImpostosEDespesas;
    var atualLucroADistribuirPorAcao =
      atualLucroADistribuir / patrimonialObj.acoes;

    if (patrimonialObj?.acionistas.length > 0) {
      var acionistasAux = patrimonialObj?.acionistas;
      acionistasAux.map(
        (acionista) =>
        (acionista.receitaAluguel =
          parseFloat(acionista.acoes) * atualLucroADistribuirPorAcao)
      );
    } else {
      var acionistasAux = [];
    }

    setPatrimonialObj({
      ...patrimonialObj,
      alugueis: alugueisAux,
      lucroADistribuir: atualLucroADistribuir,
      lucroADistribuirPorAcao: atualLucroADistribuirPorAcao,
      acionistas: acionistasAux,
      pis: novoPis,
      irpj: novoirpj,
      adicionalIRPJ: novoadicionalIRPJ,
      cofins: novoCofins,
      csll: novoCsll,
      impostosEDespesas: atualImpostosEDespesas,
    });

    setEditarAlugueis({
      titulo: "",
      valor: "",
      id: null,
    });

    setKeyAlugueis("Novo");
    return notify(
      editarAlugueis.id === null
        ? "Aluguel criado com sucesso!"
        : "Aluguel atualizado com sucesso!"
    );
  }

  function buildAlugueisTab() {
    return (
      <Container fluid>
        <Row>
          <Col md style={{ marginBottom: "5px", marginTop: "5px" }}>
            <label>Nome da empresa</label>
            <FormControl
              placeholder="Nome do aluguel"
              aria-label="Nome do aluguel"
              value={editarAlugueis.titulo}
              onChange={(e) =>
                setEditarAlugueis({ ...editarAlugueis, titulo: e.target.value })
              }
              onBlur={(e) =>
                setEditarAlugueis({ ...editarAlugueis, titulo: e.target.value })
              }
            />
          </Col>
        </Row>
        <Row>
          <Col md style={{ marginBottom: "5px", marginTop: "5px" }}>
            <label>Valor</label>
            <FormControl
              placeholder="valor"
              aria-label="valor"
              value={editarAlugueis.valor}
              pattern="[0-9]+[.]?[0-9]{2}"
              onChange={(e) =>
                setEditarAlugueis({
                  ...editarAlugueis,
                  valor: e.target.value
                    .replaceAll(",", ".")
                    .replaceAll(/[A-Za-z]/gi, ""),
                })
              }
              onBlur={(e) => {
                e.target.value = math.evaluate(e.target.value);
                setEditarAlugueis({
                  ...editarAlugueis,
                  valor: math.evaluate(e.target.value),
                });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md style={{ marginBottom: "5px", marginTop: "5px" }}>
            <label>Mês</label>
            <FormControl
              placeholder="Mês"
              aria-label="Mês"
              pattern="[0-9]{2}"
              type="number"
              value={patrimonialObj?.dadosTempo?.mesconsulta}
              readOnly={true}
            />
          </Col>
          <Col md style={{ marginBottom: "5px", marginTop: "5px" }}>
            <label>Ano</label>
            <FormControl
              placeholder="Ano"
              aria-label="Ano"
              pattern="[0-9]{4}"
              type="number"
              value={patrimonialObj?.dadosTempo?.ano}
              readOnly={true}
            />
          </Col>
        </Row>
        <Row>
          <Col align="center">
            {editarAlugueis.id !== null ? (
              <Button
                variant="outline-danger"
                size="sm"
                onClick={() => excluirAluguel(editarAlugueis.id)}
              >
                Excluir
              </Button>
            ) : null}
          </Col>
          <Col align="center">
            <Button
              size="sm"
              disabled={
                editarAlugueis.titulo === "" || editarAlugueis.valor === ""
                  ? true
                  : false
              }
              onClick={() => atualizarAlugueis()}
            >
              Salvar
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }

  async function excluirAluguel(id) {
    if (id === null) return console.error("Nenhum aluguel selecionado");

    var res = await api.delete("/patrimoniais/alugueis/" + id, {
      headers: { authorization: `Bearer ${cookies.usuario.accessToken}` },
    });

    if (res.status !== 200) return notify("Erro ao excluir aluguel");

    return loadPatrimonialAtual();

    var alugueisAux = patrimonialObj?.alugueis.filter(
      (aluguel) => aluguel.id + "" !== id + ""
    );

    var totalAluguelAtual =
      alugueisAux.reduce((acc, cv) => (acc += cv.valor), 0) +
      patrimonialObj?.aluguelLiquido;
    var novoPis = totalAluguelAtual * 0.0065;
    var novoirpj = totalAluguelAtual * 0.048;
    var novoadicionalIRPJ = parseFloat(
      ((totalAluguelAtual * 0.32 * 3 - 60000) * 0.1) / 3
    );
    var novoCofins = totalAluguelAtual * 0.03;
    var novoCsll = totalAluguelAtual * 0.0288;

    if (novoadicionalIRPJ < 0) novoadicionalIRPJ = 0;

    var somaOutrasDespesas = patrimonialObj.outrasDespesas.reduce(
      (acc, cv) => (acc += cv.valor),
      0
    );

    var atualImpostosEDespesas =
      novoPis +
      novoirpj +
      novoadicionalIRPJ +
      novoCofins +
      novoCsll +
      somaOutrasDespesas;
    var atualLucroADistribuir = totalAluguelAtual - atualImpostosEDespesas;
    var atualLucroADistribuirPorAcao =
      atualLucroADistribuir / patrimonialObj.acoes;

    if (patrimonialObj?.acionistas.length > 0) {
      var acionistasAux = patrimonialObj?.acionistas;
      acionistasAux = patrimonialObj?.acionistas.forEach((acionista) => {
        return (acionista.receitaAluguel =
          parseFloat(acionista.acoes) * atualLucroADistribuirPorAcao);
      });
    } else {
      var acionistasAux = [];
    }

    setPatrimonialObj({
      ...patrimonialObj,
      alugueis: alugueisAux,
      lucroADistribuir: atualLucroADistribuir,
      lucroADistribuirPorAcao: atualLucroADistribuirPorAcao,
      acionistas: acionistasAux,
      pis: novoPis,
      irpj: novoirpj,
      adicionalIRPJ: novoadicionalIRPJ,
      cofins: novoCofins,
      csll: novoCsll,
      impostosEDespesas: atualImpostosEDespesas,
    });

    setEditarAlugueis({
      titulo: "",
      valor: "",
      id: null,
    });

    setKeyAlugueis("Novo");
    return notify("Aluguel excluído com sucesso!");
  }

  var maskCPFCNPJ = (v) => {
    v = v.replace(/\D/g, "");

    if (v.length <= 11) {
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    } else {
      v = v.replace(/^(\d{2})(\d)/, "$1.$2");
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
      v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
      v = v.replace(/(\d{4})(\d)/, "$1-$2");
    }

    return v;
  };

  function BuildConfiguracoes() {
    return (
      <Modal
        show={configuracoes.modalVisivel}
        onHide={() =>
          setModalConfiguracoes({ ...configuracoes, modalVisivel: false })
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        size="lg"
      >
        <Modal.Header closeButton style={{ gap: "15px" }}>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Button variant="dark" style={{ marginRight: "8px" }} disabled>
              <AiOutlineTool />
            </Button>
            Configurações
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tab.Container id="left-tabs-example" defaultActiveKey="Alugueis">
            <Row>
              <Col lg={3}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="Alugueis">Alugueis</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Taxas">Taxas</Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                                        <Nav.Link eventKey="AdcAcionista">Adicionar Acionista</Nav.Link>
                                    </Nav.Item> */}
                  <Nav.Item hidden={cookies.usuario.permissao !== 0}>
                    <Nav.Link onClick={() => salvarHistoricoPagamento()}>
                      Salvar Histórico de pagamento atual
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col lg={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="Alugueis">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="Novo"
                      activeKey={keyAlugueis}
                      onSelect={(k) => setKeyAlugueis(k)}
                    >
                      <Row>
                        <Col lg={6}>
                          <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                              <Nav.Link
                                eventKey="Novo"
                                onClick={() => {
                                  setEditarAlugueis({
                                    titulo: "",
                                    valor: "",
                                    id: null,
                                  });
                                }}
                              >
                                Novo Aluguel
                              </Nav.Link>
                            </Nav.Item>
                            {patrimonialObj?.alugueis?.map((aluguel, idx) => {
                              return (
                                <Nav.Item key={"6" + idx}>
                                  <Nav.Link
                                    onClick={() => {
                                      setEditarAlugueis({
                                        titulo: aluguel?.titulo,
                                        id: aluguel?.id,
                                        valor: aluguel?.valor,
                                      });
                                    }}
                                    eventKey={aluguel.titulo.replaceAll(
                                      " ",
                                      "_"
                                    )}
                                  >
                                    {aluguel.titulo}
                                  </Nav.Link>
                                </Nav.Item>
                              );
                            })}
                          </Nav>
                        </Col>
                        <Col lg={6}>{buildAlugueisTab()}</Col>
                      </Row>
                    </Tab.Container>
                  </Tab.Pane>
                  <Tab.Pane eventKey="Taxas">
                    <label htmlFor="basic-url">
                      Percentual de retenção fundo de reserva:
                    </label>
                    <Row style={{ alignItems: "baseline" }}>
                      <Col lg={9}>
                        <FormControl
                          placeholder="Username"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          id="basic-addon1"
                          type="range"
                          step="0.01"
                          value={
                            editarFundoDeReservaPerc.retencaopercentual * 100
                          }
                          onChange={(e) =>
                            setEditarFundoDeReservaPerc({
                              ...editarFundoDeReservaPerc,
                              retencaopercentual: e.target.value / 100,
                            })
                          }
                        />
                      </Col>
                      <Col lg={3}>
                        <InputGroup size="sm" className="mb-3">
                          <FormControl
                            placeholder="Username"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            id="basic-addon1"
                            type="number"
                            step="0.01"
                            value={
                              editarFundoDeReservaPerc.retencaopercentual * 100
                            }
                            onChange={(e) =>
                              setEditarFundoDeReservaPerc({
                                ...editarFundoDeReservaPerc,
                                retencaopercentual: e.target.value / 100,
                              })
                            }
                          />
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              %
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col align="right">
                        <Button
                          size="sm"
                          onClick={() => atualizarPercentualFReserva()}
                        >
                          Salvar
                        </Button>
                      </Col>
                    </Row>
                  </Tab.Pane>
                  {/* <Tab.Pane eventKey="AdcAcionista">
                                        <Form onSubmit={(e) => { salvarNovoAcionista(e) }}>
                                            <Row>
                                                <Col md style={{ marginBottom: '5px', marginTop: '5px' }} >
                                                    <Form.Label>Nome Investidor</Form.Label>
                                                    <Form.Control
                                                        placeholder="Nome"
                                                        name='nome'
                                                        aria-label="Nome"
                                                        required
                                                        onInvalid={(e) => { e.target.classList.add('is-invalid') }}
                                                        onChange={(e) => { e.target.classList.remove('is-invalid') }}
                                                        onBlur={(e) => { setEditarInvestidor({ ...editarInvestidor, nome: e.target.value }) }}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Label>É PJ?</Form.Label>
                                                    <Form.Control
                                                        placeholder="isPJ"
                                                        aria-label="isPJ"
                                                        name='isPJ'
                                                        type='checkbox'
                                                        onChange={(e) => { setEditarInvestidor({ ...editarInvestidor, isPJ: e.target.checked }) }}
                                                    />
                                                </Col>
                                                <Col md style={{ marginBottom: '5px', marginTop: '5px' }} >
                                                    {editarInvestidor.isPJ
                                                        ? <>
                                                            <Form.Label>CNPJ</Form.Label>
                                                            <Form.Control
                                                                placeholder="CNPJ"
                                                                aria-label="CNPJ"
                                                                name='cpf/cnpj'
                                                                pattern='[0-9]{2}.[0-9]{3}.[0-9]{3}/[0-9]{4}-[0-9]{2}'
                                                                maxLength={18}
                                                                required
                                                                onInvalid={(e) => { e.target.classList.add('is-invalid') }}
                                                                onChange={(e) => { e.target.classList.remove('is-invalid'); e.target.value = maskCPFCNPJ(e.target.value) }}
                                                                onBlur={(e) => {
                                                                    if (verificaDigitoDocumento(e.target.value)) {
                                                                        setEditarInvestidor({ ...editarInvestidor, 'cpf/cnpj': e.target.value })
                                                                    } else {
                                                                        e.target.classList.add('is-invalid')
                                                                        e.target.value = null
                                                                        notify("Documento inválido!")
                                                                    }
                                                                }}
                                                            />
                                                        </>
                                                        : <>
                                                            <Form.Label>CPF</Form.Label>
                                                            <Form.Control
                                                                placeholder="CPF"
                                                                aria-label="CPF"
                                                                name='cpf/cnpj'
                                                                pattern='[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}'
                                                                maxLength={14}
                                                                required
                                                                onInvalid={(e) => { e.target.classList.add('is-invalid') }}
                                                                onChange={(e) => { e.target.classList.remove('is-invalid'); e.target.value = maskCPFCNPJ(e.target.value) }}
                                                                onBlur={(e) => {
                                                                    if (verificaDigitoDocumento(e.target.value)) {
                                                                        setEditarInvestidor({ ...editarInvestidor, 'cpf/cnpj': e.target.value })
                                                                    } else {
                                                                        e.target.classList.add('is-invalid')
                                                                        e.target.value = null
                                                                        notify("Documento inválido!")
                                                                    }
                                                                }}
                                                            />
                                                        </>}
                                                </Col>
                                                {patrimonialObj.modelo === 2 ? null
                                                    : <Col md style={{ marginBottom: '5px', marginTop: '5px' }} >
                                                        <Form.Label>Nº de Ações</Form.Label>
                                                        <Form.Control
                                                            placeholder="Nº de Ações"
                                                            aria-label="Numero de ações"
                                                            nome='acoes'
                                                            type="text"
                                                            onBlur={(e) => { e.target.value = math.evaluate(e.target.value); setEditarInvestidor({ ...editarInvestidor, acoes: math.evaluate(e.target.value) }) }}
                                                            required
                                                            onInvalid={(e) => { e.target.classList.add('is-invalid') }}
                                                            onChange={(e) => { e.target.classList.remove('is-invalid') }}
                                                        />
                                                    </Col>
                                                }
                                            </Row>
                                            {patrimonialObj.modelo === 2
                                                ? <Row>
                                                    {patrimonialObj?.acaoPorUnidade?.map((apartamento, index) => {
                                                        return (
                                                            <Col md style={{ marginBottom: '5px', marginTop: '5px' }} key={'7' + index} >
                                                                <Form.Label>Apartamentos {apartamento.unidade.split(' ')[0]}</Form.Label>
                                                                <Form.Control
                                                                    placeholder="Apartamentos"
                                                                    aria-label="Apartamento"
                                                                    name={'Apartamento-' + apartamento.unidade.split(' ')[0]}
                                                                    id={'Apartamento-' + apartamento.unidade.split(' ')[0]}
                                                                    defaultValue={0}
                                                                    onBlur={(e) => {
                                                                        if (e.target.value === '') { e.target.value = 0 }
                                                                        e.target.value = math.evaluate(e.target.value);

                                                                        var acoesAtualizadas = patrimonialObj?.acaoPorUnidade?.reduce((acc, cv) => acc += (cv.acoes * parseFloat(document.getElementById('Apartamento-' + cv.unidade.split(' ')[0]).value)), 0)

                                                                        if (editarInvestidor.apartamentosNovos === undefined) {
                                                                            setEditarInvestidor({ ...editarInvestidor, acoes: acoesAtualizadas, apartamentosNovos: [{ 'cpf/cnpj': editarInvestidor['cpf/cnpj'], unidade: apartamento.unidade, apartamentos: e.target.value, unidade_id: apartamento.unidade_id, version: 1 }] })
                                                                        } else {
                                                                            setEditarInvestidor({ ...editarInvestidor, acoes: acoesAtualizadas, apartamentosNovos: [...editarInvestidor.apartamentosNovos.filter(d => d.unidade_id !== apartamento.unidade_id), { 'cpf/cnpj': editarInvestidor['cpf/cnpj'], unidade: apartamento.unidade, apartamentos: e.target.value, unidade_id: apartamento.unidade_id, version: 1 }] })
                                                                        }
                                                                    }}
                                                                    required
                                                                    onInvalid={(e) => { e.target.classList.add('is-invalid') }}
                                                                    onChange={(e) => { e.target.classList.remove('is-invalid') }}
                                                                />
                                                            </Col>
                                                        )
                                                    })}
                                                    <Col md style={{ marginBottom: '5px', marginTop: '5px' }} >
                                                        <Form.Label>Nº de Ações</Form.Label>
                                                        <Form.Control
                                                            placeholder="Nº de Ações"
                                                            aria-label="Numero de ações"
                                                            nome='acoes'
                                                            type="text"
                                                            disabled={true}
                                                            value={editarInvestidor.acoes}
                                                            required
                                                            onInvalid={(e) => { e.target.classList.add('is-invalid') }}
                                                            onChange={(e) => { e.target.classList.remove('is-invalid') }}
                                                        />
                                                    </Col>
                                                </Row>
                                                : null}
                                            <Row >
                                                <Col md={3} style={{ marginBottom: '5px', marginTop: '5px' }} >
                                                    <Form.Label>Reside no Exterior?</Form.Label>
                                                    <Form.Control
                                                        placeholder="Reside no Exterior"
                                                        aria-label="Reside no Exterior"
                                                        name='Reside no Exterior'
                                                        type='checkbox'
                                                        defaultChecked={editarInvestidor?.reside_exterior}
                                                        onChange={(e) => { setEditarInvestidor({ ...editarInvestidor, reside_exterior: e.target.checked }) }}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label>País residencia</Form.Label>
                                                    <Select
                                                        isDisabled={!!!editarInvestidor?.reside_exterior}
                                                        name="colors"
                                                        options={patrimonialObj?.codigosPaises}
                                                        closeMenuOnSelect={true}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        defaultValue={patrimonialObj?.codigosPaises?.filter(d => d.value === editarInvestidor?.pais_residencia)}
                                                        onChange={(e) => { setEditarInvestidor({ ...editarInvestidor, pais_residencia: e.value, aliquota: e.aliquota }) }}
                                                    />
                                                </Col>
                                                <Col>
                                                    <label>Aliquota IR</label>
                                                    <InputGroup>
                                                        <FormControl
                                                            disabled={!!!editarInvestidor?.pais_residencia || !!!editarInvestidor?.reside_exterior}
                                                            placeholder="aliquota"
                                                            id='aliquotaIRInput'
                                                            type='number'
                                                            aria-label="aliquota"
                                                            value={editarInvestidor?.aliquota}
                                                            onChange={e => setEditarInvestidor({ ...editarInvestidor, aliquota: e.target.value })}
                                                            onBlur={(e) => { setEditarInvestidor({ ...editarInvestidor, aliquota: e.target.value }) }}
                                                        />
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={3} style={{ marginBottom: '5px', marginTop: '5px' }} >
                                                    <Form.Label>É Pix?</Form.Label>
                                                    <Form.Control
                                                        placeholder="isPix"
                                                        aria-label="isPix"
                                                        name='isPix'
                                                        type='checkbox'
                                                        disabled={editarInvestidor.isAssinado}
                                                        defaultChecked={editarInvestidor.isPix}
                                                        onChange={(e) => { setEditarInvestidor({ ...editarInvestidor, isPix: e.target.checked }) }}
                                                    />
                                                </Col>
                                                <Col hidden={!editarInvestidor?.isPix} md style={{ marginBottom: '5px', marginTop: '5px' }} >
                                                    <Form.Label>Tipo Pix</Form.Label>
                                                    <Select
                                                        name="colors"
                                                        options={[{ label: "Celular", value: "01" }, { label: "Email", value: "02" }, { label: "CPF/CNPJ", value: "03" }, { label: "Aleatória", value: "04" }]}
                                                        closeMenuOnSelect={true}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        defaultValue={[{ label: "Celular", value: "01" }, { label: "Email", value: "02" }, { label: "CPF/CNPJ", value: "03" }, { label: "Aleatória", value: "04" }].filter(d => d.value === editarInvestidor.tipoPix)}
                                                        isDisabled={editarInvestidor.isAssinado}
                                                        onChange={(e) => { setEditarInvestidor({ ...editarInvestidor, tipoPix: e.value }) }}
                                                    />
                                                </Col>
                                                <Col md style={{ marginBottom: '5px', marginTop: '5px' }} >
                                                    <label>Banco</label>
                                                    <Select
                                                        name="colors"
                                                        options={codigosBancosLista.reduce((acc, cv) => acc = [...acc, { label: cv.codigo + ' - ' + cv.banco, value: cv.codigo }], [])}
                                                        closeMenuOnSelect={true}
                                                        className="basic-multi-select"
                                                        defaultValue={codigosBancosLista.reduce((acc, cv) => acc = [...acc, { label: cv.codigo + ' - ' + cv.banco, value: cv.codigo }], []).filter(d => d.value === editarInvestidor.codigobanco_id)}
                                                        classNamePrefix="select"
                                                        isDisabled={editarInvestidor.isAssinado}
                                                        onChange={(e) => { setEditarInvestidor({ ...editarInvestidor, codigobanco_id: e.value, banco: e.label?.split(' - ')[1] }) }}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                {editarInvestidor.isPix
                                                    ? <Col md style={{ marginBottom: '5px', marginTop: '5px' }} >
                                                        <Form.Label>Chave Pix</Form.Label>
                                                        <Form.Control
                                                            placeholder="Chave Pix"
                                                            aria-label="Chave Pix"
                                                            nome='chavePix'
                                                            onBlur={(e) => setEditarInvestidor({ ...editarInvestidor, pix: e.target.value })}
                                                            required
                                                            onInvalid={(e) => { e.target.classList.add('is-invalid') }}
                                                            onChange={(e) => { e.target.classList.remove('is-invalid') }}
                                                        />
                                                    </Col>
                                                    : null}
                                                <Col md style={{ marginBottom: '5px', marginTop: '5px' }} >
                                                    <Form.Label>Agência</Form.Label>
                                                    <Form.Control
                                                        placeholder="Agência"
                                                        aria-label="Agência"
                                                        nome='agencia'
                                                        onBlur={(e) => setEditarInvestidor({ ...editarInvestidor, agencia: e.target.value })}
                                                        required
                                                        onInvalid={(e) => { e.target.classList.add('is-invalid') }}
                                                        onChange={(e) => { e.target.classList.remove('is-invalid') }}
                                                    />
                                                </Col>
                                                <Col md style={{ marginBottom: '5px', marginTop: '5px' }} >
                                                    <Form.Label>Conta Corrente</Form.Label>
                                                    <Form.Control
                                                        placeholder="Conta corrente"
                                                        aria-label="Conta corrente"
                                                        nome='conta'
                                                        onBlur={(e) => setEditarInvestidor({ ...editarInvestidor, conta: e.target.value })}
                                                        required
                                                        onInvalid={(e) => { e.target.classList.add('is-invalid') }}
                                                        onChange={(e) => { e.target.classList.remove('is-invalid') }}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md style={{ marginBottom: '5px', marginTop: '5px' }} >
                                                    <Form.Label>Destinatário</Form.Label>
                                                    <Form.Control
                                                        placeholder="Email destinatário"
                                                        aria-label="Email destinatário"
                                                        type="email"
                                                        nome='destinatario'
                                                        onBlur={(e) => setEditarInvestidor({ ...editarInvestidor, destinatario: e.target.value })}
                                                        required
                                                        onInvalid={(e) => { e.target.classList.add('is-invalid') }}
                                                        onChange={(e) => { e.target.classList.remove('is-invalid') }}
                                                    />
                                                </Col>
                                                <Col md style={{ marginBottom: '5px', marginTop: '5px' }} >
                                                    <Form.Label>Com Cópia</Form.Label>
                                                    <Form.Control
                                                        placeholder="Email cópia"
                                                        aria-label="Email cópia"
                                                        type="email"
                                                        nome='copia'
                                                        onBlur={(e) => setEditarInvestidor({ ...editarInvestidor, copia: e.target.value })}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md style={{ marginBottom: '5px', marginTop: '5px' }} >
                                                    <Form.Label>Observações</Form.Label>
                                                    <Form.Control
                                                        placeholder="Comentário"
                                                        aria-label="Comentário"
                                                        nome='comentario'
                                                        type="textArea"
                                                        onBlur={(e) => setEditarInvestidor({ ...editarInvestidor, comentario: e.target.value })}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col align='right'>
                                                    <Button variant="primary" size='sm' type='submit' >Salvar</Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Tab.Pane>  */}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Modal.Body>
      </Modal>
    );
  }

  async function salvarNovoAcionista(form) {
    form.preventDefault();
    var isPJProv = false;
    if (editarInvestidor.isPJ !== undefined) {
      isPJProv = editarInvestidor.isPJ;
    }

    var novaSubject = "";
    if (patrimonialObj.modelo === 2) {
      novaSubject =
        "Aluguel " +
        patrimonialObj.label +
        " " +
        patrimonialObj.dadosTempo.mesconsulta +
        "/" +
        patrimonialObj.dadosTempo.ano +
        " " +
        editarInvestidor.nome;
    } else {
      novaSubject =
        "DISTRIBUIÇÃO DE LUCROS " +
        patrimonialObj.label +
        " " +
        patrimonialObj.dadosTempo.mesconsulta +
        "/" +
        patrimonialObj.dadosTempo.ano +
        " " +
        editarInvestidor.nome;
    }

    try {
      await api
        .post(
          "/patrimoniais/create/acionistas",
          {
            acionistas: [
              {
                acoes: editarInvestidor.acoes,
                nome: editarInvestidor.nome,
                "cpf/cnpj": editarInvestidor["cpf/cnpj"],
                banco: editarInvestidor.banco,
                agencia: editarInvestidor.agencia,
                conta: editarInvestidor.conta,
                destinatario: editarInvestidor.destinatario,
                comentario: editarInvestidor.comentario,
                copia: editarInvestidor.copia,
                isPJ: isPJProv,
                usuario_id: cookies.usuario.id,
                patrimonial_id: patrimonialObj.value,
                isPix: editarInvestidor.isPix,
                pix: editarInvestidor.pix,
                tipoPix: editarInvestidor.tipoPix,
                codigobanco_id: editarInvestidor.codigobanco_id,
                reside_exterior: editarInvestidor?.reside_exterior,
                pais_residencia: editarInvestidor?.pais_residencia,
                aliquota: editarInvestidor?.aliquota,
              },
            ],
          },
          {
            headers: { authorization: `Bearer ${cookies.usuario.accessToken}` },
          }
        )
        .then(async (res) => {
          // dispatch({ type: 'OPEN_LOADING', text: "Carregando dados...", isLoading: false })
          if (res.status === 409)
            return notify("Já existe um acionista com este CPF/CNPJ!");
          if (res.status !== 201)
            return notify(
              "Erro ao salvar acionista, tente novamente mais tarde!"
            );

          if (editarInvestidor?.apartamentosNovos) {
            await Promise.all(
              editarInvestidor.apartamentosNovos.map(async (aps) => {
                var responseApartamentos = await api.post(
                  "/patrimoniais/apartamentos",
                  {
                    "cpf/cnpj": aps["cpf/cnpj"],
                    unidade_id: aps.unidade_id,
                    apartamentos: aps.apartamentos,
                    version: 1,
                    comentario: editarInvestidor.comentario,
                    usuario_id: cookies.usuario.id,
                  },
                  {
                    headers: {
                      authorization: `Bearer ${cookies.usuario.accessToken}`,
                    },
                  }
                );

                if (responseApartamentos.status !== 200)
                  return notify(
                    "Erro ao adicionar apartamentos da unidade " +
                    aps.unidade +
                    " para o investidor" +
                    editarInvestidor.nome +
                    "!"
                  );
              })
            );
          }

          var novoAcionista = {};
          if (patrimonialObj.modelo === 2) {
            var receitaAluguelAtual =
              parseFloat(editarInvestidor.acoes) *
              patrimonialObj.aluguelPorAcao;
            var retencaoFundoReservaAtual =
              parseFloat(editarInvestidor.acoes) *
              patrimonialObj.fundoDeReservaPorAcao;
            var irrfAtual = 0;
            if (!editarInvestidor.isPJ && !!editarInvestidor?.reside_exterior)
              irrfAtual =
                receitaAluguelAtual * (editarInvestidor?.aliquota / 100);
            if (!editarInvestidor.isPJ && !!!editarInvestidor?.reside_exterior)
              irrfAtual =
                receitaAluguelAtual *
                patrimonialObj?.tabelaProgressivaIR?.find(
                  (d) =>
                    d.basecalculo_max >= receitaAluguelAtual &&
                    d.basecalculo_min < receitaAluguelAtual
                )?.aliquota -
                patrimonialObj?.tabelaProgressivaIR?.find(
                  (d) =>
                    d.basecalculo_max >= receitaAluguelAtual &&
                    d.basecalculo_min < receitaAluguelAtual
                )?.deducao;
            novoAcionista = {
              acoes: editarInvestidor.acoes,
              nome: editarInvestidor.nome,
              "cpf/cnpj": editarInvestidor["cpf/cnpj"],
              banco: editarInvestidor.banco,
              agencia: editarInvestidor.agencia,
              conta: editarInvestidor.conta,
              destinatario: editarInvestidor.destinatario,
              comentario: editarInvestidor.comentario,
              copia: editarInvestidor.copia,
              isPJ: isPJProv,
              version: 1,
              receitaAluguel: receitaAluguelAtual,
              retencaoFundoReserva: retencaoFundoReservaAtual,
              irrf: irrfAtual,
              isPix: editarInvestidor.isPix,
              tipoPix: editarInvestidor.tipoPix,
              pix: editarInvestidor.pix,
              codigobanco_id: editarInvestidor.codigobanco_id,
              email: {
                anoReferencia: patrimonialObj.dadosTempo.ano,
                copia: editarInvestidor.copia,
                destinatario: editarInvestidor.destinatario,
                mesPagamento: patrimonialObj.dadosTempo.mespagamentostr,
                mesReferencia: patrimonialObj.dadosTempo.mesconsultastr,
                patrimonial: patrimonialObj.label,
                source: patrimonialObj.sourceEmail,
                subject: novaSubject,
                apartamentos: [...editarInvestidor.apartamentosNovos],
              },
            };
          } else {
            novoAcionista = {
              acoes: editarInvestidor.acoes,
              nome: editarInvestidor.nome,
              "cpf/cnpj": editarInvestidor["cpf/cnpj"],
              banco: editarInvestidor.banco,
              agencia: editarInvestidor.agencia,
              conta: editarInvestidor.conta,
              destinatario: editarInvestidor.destinatario,
              comentario: editarInvestidor.comentario,
              copia: editarInvestidor.copia,
              isPJ: isPJProv,
              isPix: editarInvestidor.isPix,
              tipoPix: editarInvestidor.tipoPix,
              pix: editarInvestidor.pix,
              version: 1,
              receitaAluguel:
                parseFloat(editarInvestidor.acoes) *
                patrimonialObj.lucroADistribuirPorAcao,
              codigobanco_id: editarInvestidor.codigobanco_id,
              email: {
                anoReferencia: patrimonialObj.dadosTempo.ano,
                copia: editarInvestidor.copia,
                destinatario: editarInvestidor.destinatario,
                mesPagamento: patrimonialObj.dadosTempo.mespagamentostr,
                mesReferencia: patrimonialObj.dadosTempo.mesconsultastr,
                patrimonial: patrimonialObj.label,
                source: patrimonialObj.sourceEmail,
                subject: novaSubject,
              },
            };
          }
          setPatrimonialObj({
            ...patrimonialObj,
            acionistas: [...patrimonialObj.acionistas, novoAcionista],
          });
          setEditarInvestidor({
            modalVisivel: false,
            auxVersion: 0,
            banco: "",
          });
          return notify("Acionista salvo com sucesso!");
        });
    } catch (error) {
      console.error(error);
      if (error.message.includes("409"))
        return notify("Já existe um acionista com este CPF/CNPJ!");
      // dispatch({ type: 'OPEN_LOADING', text: "Carregando dados...", isLoading: false })
      notify("Erro ao salvar, tente novamente mais tarde!");
    }
  }

  function verificaDigitoDocumento(documento) {
    var documentoFormated = documento
      .normalize("NFD")
      .replace(/([\u0300-\u036f]|[^0-9])/g, "");
    documentoFormated = documentoFormated.split("");
    if (documentoFormated.length === 11) {
      // é cpf
      var valor = 0;
      documentoFormated.slice(0, 9).map((digitoAtual, idx) => {
        valor += parseInt(digitoAtual) * (documentoFormated.length - idx - 1);
      });

      var primeiroDigitoVerificador = valor % 11 < 2 ? 0 : 11 - (valor % 11);

      if (primeiroDigitoVerificador == documentoFormated[9]) {
        var valorSegundo = 0;

        documentoFormated.slice(0, 10).map((digitoAtual, idx) => {
          valorSegundo +=
            parseInt(digitoAtual) * (documentoFormated.length - idx);
        });

        var segundoDigitoVerificador =
          valorSegundo % 11 < 2 ? 0 : 11 - (valorSegundo % 11);

        if (segundoDigitoVerificador == documentoFormated[10]) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else if (documentoFormated.length === 14) {
      // é cnpj
      var pesoAtual = 2;
      var valor = 0;

      documentoFormated
        .slice(0, 12)
        .reverse()
        .map((digitoAtual) => {
          valor += digitoAtual * pesoAtual;
          if (pesoAtual === 9) pesoAtual = 1;
          pesoAtual++;
        });

      var primeiroDigitoVerificador = valor % 11 < 2 ? 0 : 11 - (valor % 11);

      if (primeiroDigitoVerificador == documentoFormated[12]) {
        var pesoAtualSegundo = 2;
        var valorSegundo = 0;
        documentoFormated
          .slice(0, 13)
          .reverse()
          .map((digitoAtual) => {
            valorSegundo += digitoAtual * pesoAtualSegundo;
            if (pesoAtualSegundo === 9) pesoAtualSegundo = 1;
            pesoAtualSegundo++;
          });

        var segundoDigitoVerificador =
          valorSegundo % 11 < 2 ? 0 : 11 - (valorSegundo % 11);

        if (segundoDigitoVerificador == documentoFormated[13]) {
          console.log("oi");
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  async function atualizarPercentualFReserva() {
    try {
      var res = await api.post(
        "/patrimoniais/fundodereserva",
        {
          ...editarFundoDeReservaPerc,
          version: editarFundoDeReservaPerc.version + 1,
          patrimonial_id: patrimonialObj.value,
          usuario_id: cookies.usuario.id,
        },
        { headers: { authorization: `Bearer ${cookies.usuario.accessToken}` } }
      );

      if (res.status !== 200)
        notify("Erro ao atualizar percentual de retenção do fundo de reserva!");

      setEditarFundoDeReservaPerc({
        ...editarFundoDeReservaPerc,
        version: editarFundoDeReservaPerc.version + 1,
      });

      var fundoDeReservaAux =
        patrimonialObj.totalReceitas *
        editarFundoDeReservaPerc.retencaopercentual;
      var fundoDeReservaPorAcaoAux = fundoDeReservaAux / patrimonialObj.acoes;
      var aluguelLiquidoAux = patrimonialObj.totalBruto - fundoDeReservaAux;
      var aluguelLiquidoPorAcaoAux = aluguelLiquidoAux / patrimonialObj.acoes;

      var totalAluguelAtual =
        patrimonialObj?.alugueis.reduce((acc, cv) => (acc += cv.valor), 0) +
        aluguelLiquidoAux;
      var novoPis = totalAluguelAtual * 0.0065;
      var novoirpj = totalAluguelAtual * 0.048;
      var novoadicionalIRPJ = parseFloat(
        ((totalAluguelAtual * 0.32 * 3 - 60000) * 0.1) / 3
      );
      var novoCofins = totalAluguelAtual * 0.03;
      var novoCsll = totalAluguelAtual * 0.0288;

      if (novoadicionalIRPJ < 0) novoadicionalIRPJ = 0;

      var somaOutrasDespesas = patrimonialObj.outrasDespesas.reduce(
        (acc, cv) => (acc += cv.valor),
        0
      );

      var atualImpostosEDespesas =
        novoPis +
        novoirpj +
        novoadicionalIRPJ +
        novoCofins +
        novoCsll +
        somaOutrasDespesas;
      var atualLucroADistribuir = totalAluguelAtual - atualImpostosEDespesas;
      var atualLucroADistribuirPorAcao =
        atualLucroADistribuir / patrimonialObj.acoes;

      if (patrimonialObj?.acionistas.length > 0) {
        var acionistasAux = patrimonialObj?.acionistas;
        acionistasAux = patrimonialObj?.acionistas.forEach((acionista) => {
          return (acionista.receitaAluguel =
            parseFloat(acionista.acoes) * atualLucroADistribuirPorAcao);
        });
      } else {
        var acionistasAux = [];
      }

      setPatrimonialObj({
        ...patrimonialObj,
        fundoDeReservaPorAcao: fundoDeReservaPorAcaoAux,
        fundoDeReserva: fundoDeReservaAux,
        fundoDeReservaPerc: { ...editarFundoDeReservaPerc },
        aluguelLiquido: aluguelLiquidoAux,
        aluguelPorAcao: aluguelLiquidoPorAcaoAux,
        lucroADistribuir: atualLucroADistribuir,
        lucroADistribuirPorAcao: atualLucroADistribuirPorAcao,
        acionistas: acionistasAux,
        pis: novoPis,
        irpj: novoirpj,
        adicionalIRPJ: novoadicionalIRPJ,
        cofins: novoCofins,
        csll: novoCsll,
        impostosEDespesas: atualImpostosEDespesas,
      });

      return notify(
        "Percentual de retenção do fundo de reserva atualizado com sucesso!"
      );
    } catch (err) {
      console.error("Erro ao atualizar retenção do fundo de reserva " + err);
      return notify(
        "Erro ao atualizar percentual de retenção do fundo de reserva!"
      );
    }
  }

  function BuildFab() {
    return (
      <Fab
        id="fabToHide"
        icon={<AiOutlinePlus />}
        mainButtonStyles={{ backgroundColor: "#17a2b8" }}
        // actionButtonStyles={{ fontSize: 'large' }}
        alwaysShowTitle={true}
        style={{ bottom: 0, right: 0, zoom: "80%", margin: "20px" }}
      >
        {/* Bottom */}

        {(patrimonialObj?.assinatura?.length === 0 ||
          cookies.usuario.permissao === 0) &&
          cookies.usuario.rights.includes("d") ? (
          <Action
            text={
              <span style={{ fontSize: "large" }}>Abrir Configurações!</span>
            }
            style={{ backgroundColor: "blue" }}
            onClick={() => {
              setModalConfiguracoes({ ...configuracoes, modalVisivel: true });
            }}
          >
            <AiOutlineTool />
          </Action>
        ) : null}

        {patrimonialObj?.assinatura?.length !== 0 &&
          cookies.usuario.rights.includes("d") ? (
          <Action
            text={
              <span style={{ fontSize: "large" }}>
                Enviar Cartas aos Acionistas!
              </span>
            }
            style={{ backgroundColor: "blue" }}
            onClick={() => {
              enviarEmailInvestidores();
            }}
          >
            <BiMailSend />
          </Action>
        ) : null}

        {patrimonialObj?.assinatura?.length !== 0 &&
          cookies.usuario.rights.includes("d") ? (
          <Action
            text={<span style={{ fontSize: "large" }}>Arquivo de Remessa</span>}
            style={{ backgroundColor: "blue" }}
            onClick={() => {
              gerarRemessaSantander();
            }}
          >
            <AiOutlineBank />
          </Action>
        ) : null}

        <Action
          text={<span style={{ fontSize: "large" }}>Baixar PDF</span>}
          style={{ backgroundColor: "blue" }}
          onClick={() => {
            baixaPDF();
          }}
        >
          <FaFilePdf />
        </Action>

        <Action
          text={<span style={{ fontSize: "large" }}>Baixar Excel</span>}
          style={{ backgroundColor: "blue" }}
          onClick={() => {
            baixaExel();
          }}
        >
          <RiFileExcel2Fill />
        </Action>

        <Action
          text={
            <span style={{ fontSize: "large" }}>
              {patrimonialObj?.assinatura?.length > 0
                ? "Visualizar assinatura!"
                : "Assinar documento!"}
            </span>
          }
          style={{ backgroundColor: "blue" }}
          onClick={
            patrimonialObj?.assinatura?.length > 0
              ? () => setModalVisualizarAssinatura(true)
              : () => validaAssinatura()
          }
        >
          <FaFileSignature />
        </Action>

        {/* Top */}
      </Fab>
    );
  }

  async function gerarRemessaSantander() {
    if (
      patrimonialObj?.acionistas?.reduce(
        (acc, cv) => (acc += parseInt(cv.acoes)),
        0
      ) !== patrimonialObj.acoes ||
      patrimonialObj?.acionistas?.filter(
        (acionistaAtual) => acionistaAtual.acoes == 0
      ).length > 0
    )
      return notify("Numero de ações incorreto!");

    var dataDePagamento = Number(
      window.prompt("Qual a data de Pagamento?", 15)
    );

    if (isNaN(dataDePagamento))
      return window.alert("Data invalida, tente novamente!");

    if (patrimonialObj?.modelo === 3) {
      var resRemessa2 = await api.post(
        "/export/remessa/investidores/santander",
        {
          dataDePagamento,
          patrimonialObj: {
            ...patrimonialObj,
            acionistas: patrimonialObj.proprietarios,
          },
          patrimonial_id: patrimonialObj?.unidades?.reduce(
            (acc, cv) => (acc = [...acc, cv.id]),
            []
          ),
        },
        { headers: { authorization: `Bearer ${cookies.usuario.accessToken}` } }
      );

      if (resRemessa2.status === 201)
        return notify("Nenhum acionista cadastrado!");
      if (resRemessa2.status !== 200)
        return notify(
          "Erro ao gerar arquivo de remessa, entre em contato com o suporte!"
        );

      for (let i = 0; i < resRemessa2.data.url.length; i++) {
        var link2 = document.createElement("a");
        link2.setAttribute("download", resRemessa2.data.fileName[i] + ".txt");
        link2.setAttribute("href", resRemessa2.data.url[i]);
        link2.setAttribute("target", "_blank");
        document.body.appendChild(link2);
        link2.click();
      }
    }

    var resRemessa = await api.post(
      "/export/remessa/investidores/santander",
      {
        dataDePagamento,
        patrimonialObj,
        patrimonial_id: [patrimonialObj?.value],
      },
      { headers: { authorization: `Bearer ${cookies.usuario.accessToken}` } }
    );

    if (resRemessa.status === 201)
      return notify("Nenhum acionista cadastrado!");
    if (resRemessa.status !== 200)
      return notify(
        "Erro ao gerar arquivo de remessa, entre em contato com o suporte!"
      );

    for (let i = 0; i < resRemessa.data.url.length; i++) {
      var link = document.createElement("a");
      link.setAttribute("download", resRemessa.data.fileName[i] + ".txt");
      link.setAttribute("href", resRemessa.data.url[i]);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
    }
  }

  async function cancelarAssinatura() {
    if (patrimonialObj.assinatura[0].isDefinitivo === true)
      return notify("Assinatura não pode ser removida");
    var assinaturaid = patrimonialObj?.assinatura[0]?.id;

    try {
      var res = await api.delete(
        `/assinar/planilha/patrimoniais/${assinaturaid}/${cookies.usuario.id}`,
        { headers: { authorization: `Bearer ${cookies.usuario.accessToken}` } }
      );

      if (res.status === 400) return notify("Erro ao remover assinatura!");
      if (res.status === 401)
        return notify("Erro! Somente o assinante pode remover a assinatura!");

      setPatrimonialObj({
        ...patrimonialObj,
        assinatura: [],
      });

      return notify("Assinatura removida com sucesso!");
    } catch (err) {
      console.error(err);
      return notify("Erro ao remover assinatura!");
    }
  }

  function BuildVisualizarAssinatura() {
    return (
      <Modal
        show={modalVisualizarAssinatura}
        onHide={() => setModalVisualizarAssinatura(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header closeButton style={{ gap: "15px" }}>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Button style={{ marginRight: "8px" }} variant="dark" disabled>
              <FaSignature />
            </Button>
            Assiantura
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Documento assinado por{" "}
          {patrimonialObj?.assinatura[0]?.nome.toUpperCase()} em{" "}
          {new Date(
            patrimonialObj?.assinatura[0]?.assinadoem
          ).toLocaleDateString("pt-br")}{" "}
          as{" "}
          {new Date(
            patrimonialObj?.assinatura[0]?.assinadoem
          ).toLocaleTimeString("pt-br")}{" "}
          {patrimonialObj?.assinatura[0]?.comentario
            ? `contendo o seguinte comentário:                
                "${patrimonialObj?.assinatura[0]?.comentario}"`
            : ""}
        </Modal.Body>

        {(cookies.usuario.permissao === 0 ||
          cookies.usuario.permissao === 1 ||
          cookies.usuario.permissao === 9) &&
          patrimonialObj?.assinatura?.length !== 0 ? (
          <Modal.Footer>
            <Button
              disabled={patrimonialObj?.assinatura[0].isDefinitivo === false}
              text={
                <span style={{ fontSize: "large" }}>Cancelar assinatura</span>
              }
              style={{ backgroundColor: "red" }}
              onClick={() => {
                cancelarAssinatura(patrimonialObj?.assinatura[0]?.id);
              }}
            >
              Remover Assinatura
            </Button>
          </Modal.Footer>
        ) : null}
      </Modal>
    );
  }

  function BuildModalAssinaturaComentario() {
    return (
      <Modal
        show={modalAssinaturaComentario}
        onHide={() => setModalAssinaturaComentario(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header closeButton style={{ gap: "15px" }}>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Button style={{ marginRight: "8px" }} disabled>
              <FaFileSignature />
            </Button>
            Assiantura
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>Comentário:</label>
          <FormControl
            as="textarea"
            rows="3"
            placeholder="Comentário"
            value={assinaturaComentario}
            onChange={(e) => setAssinaturaComentario(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ marginRight: "5px" }}
            size="sm"
            variant="outline-danger"
            onClick={() => setModalAssinaturaComentario(false)}
          >
            Fechar
          </Button>
          <Button
            size="sm"
            variant="outline-success"
            onClick={() => assinarDocumento()}
          >
            Assinar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  function validaAssinatura() {
    if (patrimonialObj?.assinatura?.length > 0)
      return notify("Documento já assinado!");
    if (
      patrimonialObj?.acionistas?.reduce(
        (acc, cv) => (acc += parseInt(cv.acoes)),
        0
      ) > patrimonialObj.acoes
    )
      return notify(
        `Quantidades de ações destribuidas estão ${patrimonialObj?.acionistas?.reduce(
          (acc, cv) => (acc += parseInt(cv.acoes)),
          0
        ) - parseFloat(patrimonialObj.acoes)
        } MAIOR que o total!`
      );
    if (
      patrimonialObj?.acionistas?.reduce(
        (acc, cv) => (acc += parseInt(cv.acoes)),
        0
      ) < patrimonialObj.acoes
    )
      return notify(
        `Quantidades de ações destribuidas estão ${parseFloat(patrimonialObj.acoes) -
        patrimonialObj?.acionistas?.reduce(
          (acc, cv) => (acc += parseInt(cv.acoes)),
          0
        )
        } MENOR que o total!`
      );
    setModalAssinaturaComentario(true);
  }

  async function assinarDocumento() {
    setModalAssinaturaComentario(false);

    var res = await api.post(
      `/assinar/planilha/patrimoniais/${cookies.usuario.id}/${patrimonialObj.value}/${patrimonialObj.dadosTempo.ano}/${patrimonialObj.dadosTempo.mesconsulta}`,
      {
        comentario: assinaturaComentario,
      },
      { headers: { authorization: `Bearer ${cookies.usuario.accessToken}` } }
    );

    if (!res?.data?.pass) {
      const text = res?.data?.message || 'Erro ao assinar'
      return notify(text)
    }

    setPatrimonialObj({
      ...patrimonialObj,
      assinatura: [
        {
          nome: cookies.usuario.nome,
          assinadoem: new Date(),
          comentario: assinaturaComentario,
          id: res.data.id,
        },
      ],
    });

    return notify(res.data.message);
  }

  async function salvarHistoricoPagamento() {
    var historicoPagamento = patrimonialObj.acionistas.reduce(
      (acc, cv) =>
      (acc = [
        ...acc,
        {
          acionista_id: cv.id,
          mes: patrimonialObj.dadosTempo.mesconsulta,
          ano: patrimonialObj.dadosTempo.ano,
          patrimonial_id: patrimonialObj.value,
          valorrecebido: cv.receitaAluguel,
          imposto: patrimonialObj.modelo === 2 ? cv.irrf : 0,
          type: "acionista",
        },
      ]),
      []
    );

    if (patrimonialObj.modelo === 3)
      historicoPagamento = [
        ...historicoPagamento,
        ...patrimonialObj.proprietarios.reduce(
          (acc, cv) =>
          (acc = [
            ...acc,
            {
              acionista_id: cv.id,
              mes: patrimonialObj.dadosTempo.mesconsulta,
              ano: patrimonialObj.dadosTempo.ano,
              patrimonial_id: patrimonialObj.value,
              valorrecebido: cv.valorDoAluguel,
              imposto: 0,
              type: "proprietario",
            },
          ]),
          []
        ),
      ];

    try {
      var salvoAcionistaMes = await api.post(
        "/patrimoniais/historicopagamento/acionistas",
        {
          historicoPagamento,
        },
        { headers: { authorization: `Bearer ${cookies.usuario.accessToken}` } }
      );

      if (salvoAcionistaMes.status !== 200)
        notify(
          "Erro ao salvar historico de pagamento, entre em contato com o suporte!"
        );

      console.info("Histórico salvo com sucesso!");

      if (cookies.usuario.permissao === 0) return notify("Salvo com sucesso!");
    } catch (error) {
      console.log(error);
      return notify(
        "ERR ACSH-0001: Erro salvar historico de acionista, entre em contato com o suporte."
      );
    }
  }

  async function enviarEmailInvestidores() {
    if (
      patrimonialObj?.acionistas?.reduce(
        (acc, cv) => (acc += parseInt(cv.acoes)),
        0
      ) !== patrimonialObj.acoes ||
      patrimonialObj?.acionistas?.filter(
        (acionistaAtual) => acionistaAtual.acoes == 0
      ).length > 0
    )
      return notify("Numero de ações incorreto!");
    var mensagem = "Deseja realmente enviar cartas ao acionista?";
    if (patrimonialObj.assinatura[0].isDefinitivo !== true) {
      mensagem =
        "Deseja realmente enviar cartas ao acionista? Ao enviar as cartas a assinatura não mais poderá ser removida e a planilha não mais editada.";
    }

    if (!window.confirm(mensagem)) return;

    if (patrimonialObj.assinatura[0].isDefinitivo !== true) {
      try {
        await api.get(
          "/assinar/planilha/patrimoniais/definitivo/" +
          patrimonialObj.assinatura[0].id,
          {
            headers: { authorization: `Bearer ${cookies.usuario.accessToken}` },
          }
        );
      } catch (error) {
        console.log("Erro ao atualizar assinatura " + error);
        return notify(
          "ERR ACASS-0001: Erro ao assinar documento, entre em contato com o suporte."
        );
      }

      try {
        await api.post(
          "/patrimoniais/fundodereserva/historico/lancamento/retencao",
          {
            data:
              patrimonialObj.dadosTempo.anopagamento +
              "-" +
              patrimonialObj.dadosTempo.mespagamento +
              "-15",
            historico:
              "Retenção " +
              patrimonialObj?.fundoDeReservaPerc?.retencaopercentual?.toLocaleString(
                "pt-BR",
                { style: "percent", maximumFractionDigits: 2 }
              ) +
              " da receita bruta do mes de " +
              patrimonialObj.dadosTempo.mesconsultastr +
              "/" +
              patrimonialObj.dadosTempo.ano,
            ano: patrimonialObj.dadosTempo.anopagamento,
            mes: patrimonialObj.dadosTempo.mespagamento,
            patrimonial_id: patrimonialObj.value,
            usuario_id: cookies.usuario.id,
            valor: patrimonialObj.fundoDeReserva,
            tipo: "Crédito",
          },
          {
            headers: { authorization: `Bearer ${cookies.usuario.accessToken}` },
          }
        );
      } catch (err) {
        console.log(err);
        return notify(
          "ERR ACFR-0001: Erro ao adicionar fundo de reserva, entre em contato com o suporte."
        );
      }

      await salvarHistoricoPagamento();
    }

    await api
      .post(
        "/export/excel/investidores/dividendos",
        {
          patrimonialObj,
        },
        { headers: { authorization: `Bearer ${cookies.usuario.accessToken}` } }
      )
      .then((excel) => window.open(excel.data.url, "_blank"))
      .catch((err) => {
        console.log(err);
        return notify(
          "ERR ACXLSX-0001: Erro ao arquivar relatório, entre em contato com o suporte."
        );
      });

    notify("Enviando cartas aos investidores...", false, "waitNotify");

    try {
      var res = await api.post(
        "/patrimoniais/cartas/acionistas",
        { patrimonialObj },
        { headers: { authorization: `Bearer ${cookies.usuario.accessToken}` } }
      );
      if (res.status === 403)
        return notify(
          "ERR ACEC-0003: Permissão negada, entre em contato com o suporte!",
          5000
        );
      if (res.status !== 200)
        return notify(
          "ERR ACEC-0002: Erro ao enviar cartas aos investidores!",
          5000
        );
    } catch (error) {
      console.error(error);
      toast.dismiss("waitNotify");
      return notify(
        "ERR ACEC-0001: Erro ao enviar cartas aos investidores!",
        5000
      );
    }

    toast.dismiss("waitNotify");

    return notify("Cartas enviadas com sucesso!", 5000);
  }

  async function gerarCarta(investidorId) {
    if (
      patrimonialObj?.acionistas?.reduce(
        (acc, cv) => (acc += parseInt(cv.acoes)),
        0
      ) !== patrimonialObj.acoes ||
      patrimonialObj?.acionistas?.filter(
        (acionistaAtual) => acionistaAtual.acoes == 0
      ).length > 0
    )
      return notify("Numero de ações incorreto!");

    var mensagem = "Deseja realmente gerar a carta deste acionista?";

    if (!window.confirm(mensagem)) return;

    if (patrimonialObj.modelo === 1 || patrimonialObj.modelo === 2) {
      var patrimonialObjFiltrado = {
        ...patrimonialObj,
        acionistas: patrimonialObj.acionistas.filter(
          (cv) => cv["cpf/cnpj"] === investidorId.finder
        ),
      };
    } else {
      var patrimonialObjFiltrado = {
        ...patrimonialObj,
        acionistas: patrimonialObj.acionistas.filter(
          (cv) => cv["cpf/cnpj"] === investidorId.finder
        ),
        proprietarios: patrimonialObj.proprietarios.filter(
          (cv) => cv["cpf/cnpj"] === investidorId.finder
        ),
      };
    }

    try {
      var res = await api.post(
        "/patrimoniais/cartas/acionistas/gerar",
        { patrimonialObj: patrimonialObjFiltrado },
        { headers: { authorization: `Bearer ${cookies.usuario.accessToken}` } }
      );
      if (res.status === 403)
        return notify(
          "Permissão negada, entre em contato com o suporte!",
          5000
        );
      if (res.status !== 200)
        return notify("Erro ao enviar cartas aos investidores!", 5000);
    } catch (error) {
      console.error(error);
      toast.dismiss("waitNotify");
      return notify("Erro ao enviar cartas aos investidores!", 5000);
    }
  }

  async function enviarEmailInvestidorUnico(investidorId) {
    if (
      patrimonialObj?.acionistas?.reduce(
        (acc, cv) => (acc += parseInt(cv.acoes)),
        0
      ) !== patrimonialObj.acoes ||
      patrimonialObj?.acionistas?.filter(
        (acionistaAtual) => acionistaAtual.acoes == 0
      ).length > 0
    )
      return notify("Numero de ações incorreto!");

    var mensagem = "Deseja realmente enviar cartas ao acionista?";
    if (patrimonialObj.assinatura[0].isDefinitivo !== true) {
      return window.alert(
        "Não é possivel enviar a carta de um acionista antes de enviar para todos."
      );
    }

    if (!window.confirm(mensagem)) return;

    if (patrimonialObj.assinatura[0].isDefinitivo !== true) {
      try {
        await api.get(
          "/assinar/planilha/patrimoniais/definitivo/" +
          patrimonialObj.assinatura[0].id,
          {
            headers: { authorization: `Bearer ${cookies.usuario.accessToken}` },
          }
        );
      } catch (error) {
        console.log("Erro ao atualizar assinatura " + error);
        return notify("Erro ao enviar cartas, entre em contato com o suporte.");
      }

      try {
        await api.post(
          "/patrimoniais/fundodereserva/historico/lancamento/retencao",
          {
            data:
              patrimonialObj.dadosTempo.anopagamento +
              "-" +
              patrimonialObj.dadosTempo.mespagamento +
              "-15",
            historico:
              "Retenção " +
              patrimonialObj?.fundoDeReservaPerc?.retencaopercentual?.toLocaleString(
                "pt-BR",
                { style: "percent", maximumFractionDigits: 2 }
              ) +
              " do faturamento do mes de " +
              patrimonialObj.dadosTempo.mesconsultastr,
            ano: patrimonialObj.dadosTempo.anopagamento,
            mes: patrimonialObj.dadosTempo.mespagamento,
            patrimonial_id: patrimonialObj.value,
            usuario_id: cookies.usuario.id,
            valor: patrimonialObj.fundoDeReserva,
            tipo: "Crédito",
          },
          {
            headers: { authorization: `Bearer ${cookies.usuario.accessToken}` },
          }
        );
      } catch (err) {
        console.log(err);
        return notify("Erro ao enviar cartas, entre em contato com o suporte.");
      }
    }

    notify("Enviando cartas ao investidor...", false, "waitNotify");

    if (patrimonialObj.modelo === 1 || patrimonialObj.modelo === 2) {
      var patrimonialObjFiltrado = {
        ...patrimonialObj,
        acionistas: patrimonialObj.acionistas.filter(
          (cv) => cv["cpf/cnpj"] === investidorId.finder
        ),
      };
    } else {
      var patrimonialObjFiltrado = {
        ...patrimonialObj,
        acionistas: patrimonialObj.acionistas.filter(
          (cv) => cv["cpf/cnpj"] === investidorId.finder
        ),
        proprietarios: patrimonialObj.proprietarios.filter(
          (cv) => cv["cpf/cnpj"] === investidorId.finder
        ),
      };
    }

    try {
      var res = await api.post(
        "/patrimoniais/cartas/acionistas",
        { patrimonialObj: patrimonialObjFiltrado },
        { headers: { authorization: `Bearer ${cookies.usuario.accessToken}` } }
      );
      if (res.status === 403)
        return notify(
          "Permissão negada, entre em contato com o suporte!",
          5000
        );
      if (res.status !== 200)
        return notify("Erro ao enviar cartas aos investidores!", 5000);
    } catch (error) {
      console.error(error);
      toast.dismiss("waitNotify");
      return notify("Erro ao enviar cartas aos investidores!", 5000);
    }

    toast.dismiss("waitNotify");

    return notify("Cartas enviadas com sucesso!", 5000);
  }

  async function baixaExel() {
    try {
      var excel = await api.post(
        "/export/excel/investidores/dividendos",
        {
          patrimonialObj,
        },
        { headers: { authorization: `Bearer ${cookies.usuario.accessToken}` } }
      );
      window.open(excel.data.url, "_blank");
    } catch (err) {
      console.log(err);
      return notify(
        "Erro ao arquivar relatório, entre em contato com o suporte."
      );
    }
  }

  async function baixaPDF() {
    if (document.getElementById("iconAjusteReceita"))
      document.getElementById("iconAjusteReceita").hidden = true;
    if (document.getElementById("iconOutrasDespesas"))
      document.getElementById("iconOutrasDespesas").hidden = true;
    if (document.getElementById("drawer"))
      document.getElementById("drawer").hidden = true;
    if (document.getElementById("fabToHide"))
      document.getElementById("fabToHide").hidden = true;
    if (document.getElementById("iconComment"))
      document.getElementById("iconComment").hidden = true;
    if (document.getElementById("checkVality"))
      document.getElementById("checkVality").hidden = true;
    if (document.getElementById("investerTable"))
      document.getElementById("investerTable").style.background = "#fff";
    if (document.getElementsByTagName("body")[0])
      document.getElementsByTagName("body")[0].style.backgroundColor = "#fff";
    document.title =
      patrimonialObj?.label.replaceAll(" ", "_") +
      "_" +
      patrimonialObj?.dadosTempo?.ano +
      "_" +
      patrimonialObj?.dadosTempo?.mesconsulta;

    var css = "@page { size: landscape; }",
      head = document.head || document.getElementsByTagName("head")[0],
      style = document.createElement("style");

    style.type = "text/css";
    style.media = "print";

    if (style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }

    head.appendChild(style);

    window.print();

    head.removeChild(style);

    document.title = "Analytics";
    if (document.getElementsByTagName("body")[0])
      document.getElementsByTagName("body")[0].style.backgroundColor =
        "#b2bec3";
    if (document.getElementById("investerTable"))
      document.getElementById("investerTable").style.background = "#656562";
    if (document.getElementById("iconComment"))
      document.getElementById("iconComment").hidden = false;
    if (document.getElementById("checkVality"))
      document.getElementById("checkVality").hidden = false;
    if (document.getElementById("fabToHide"))
      document.getElementById("fabToHide").hidden = false;
    if (document.getElementById("drawer"))
      document.getElementById("drawer").hidden = false;
    if (document.getElementById("iconOutrasDespesas"))
      document.getElementById("iconOutrasDespesas").hidden = false;
    if (document.getElementById("iconAjusteReceita"))
      document.getElementById("iconAjusteReceita").hidden = false;
  }

  function showBadge() {
    if (
      patrimonialObj?.acionistas?.reduce(
        (acc, cv) => (acc += parseInt(cv.acoes)),
        0
      ) !== patrimonialObj.acoes ||
      patrimonialObj?.acionistas?.filter(
        (acionistaAtual) => acionistaAtual.acoes == 0
      ).length > 0
    ) {
      return (
        <Badge
          pill
          variant="danger"
          id="checkVality"
          style={{ color: "#dc3545", position: "relative", top: "-1px" }}
        >
          1
        </Badge>
      );
    }
  }

  return (
    <>
      {patrimonialObj?.modelo === 2 ? (
        <table
          id="investerTable"
          style={{
            width: "calc( 100% + 20px)",
            background: "#656562",
            WebkitTextSizeAdjust: "100%",
            msTextSizeAjust: "100%",
            padding: "0",
            margin: "-10px",
            borderCollapse: "collapse",
            fontSize: "small",
          }}
        >
          <tbody>
            <tr>
              <td>
                <table
                  align="center"
                  style={{
                    width: "90%",
                    background: "#fff",
                    WebkitTextSizeAdjust: "100%",
                    msTextSizeAjust: "100%",
                    padding: "0",
                  }}
                >
                  <tbody>
                    <tr>
                      <td>
                        <table
                          align="center"
                          style={{
                            width: "98%",
                            WebkitTextSizeAdjust: "100%",
                            msTextSizeAjust: "100%",
                            padding: "0",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td style={{ height: "5px" }}></td>
                            </tr>
                            <tr>
                              <td rowSpan={3}>
                                <img
                                  width={"auto"}
                                  height={80}
                                  className="mr-3"
                                  src={logoUpManagement}
                                  alt="Logo UP Asset Management"
                                />
                              </td>
                              <td align="center" style={{ fontWeight: "bold" }}>
                                {patrimonialObj?.titulo}
                              </td>
                            </tr>
                            <tr align="center" style={{ fontWeight: "bold" }}>
                              <td>
                                Relação de Investidores e Valores de Aluguel a
                                Pagar
                              </td>
                            </tr>
                            <tr align="center" style={{ fontWeight: "bold" }}>
                              <td>
                                Resultados relativos a{" "}
                                {patrimonialObj?.dadosTempo?.mesconsultastr}{" "}
                                pagamentos até o dia 15 de{" "}
                                {patrimonialObj?.dadosTempo?.mespagamentostr} de{" "}
                                {patrimonialObj?.dadosTempo?.anopagamento}
                              </td>
                            </tr>
                            <tr>
                              <td style={{ height: "5px" }}></td>
                            </tr>
                          </tbody>
                        </table>
                        <table
                          align="center"
                          style={{
                            width: "98%",
                            WebkitTextSizeAdjust: "100%",
                            msTextSizeAjust: "100%",
                            padding: "0",
                            border: "2px solid",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td
                                colSpan="2"
                                style={{ background: "#119e91", height: "5px" }}
                              ></td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid",
                                  padding: "0 20px",
                                  width: "50%",
                                }}
                              >
                                <table
                                  align="center"
                                  style={{
                                    width: "100%",
                                    WebkitTextSizeAdjust: "100%",
                                    msTextSizeAjust: "100%",
                                    padding: "0",
                                  }}
                                >
                                  <tbody>
                                    <tr>
                                      <td>Receita de Hospedagem - </td>
                                      <td
                                        align="right"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        R${" "}
                                        {patrimonialObj?.totalHospedagem?.toLocaleString(
                                          "pt-br",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Receita de A&B - </td>
                                      <td
                                        align="right"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        R${" "}
                                        {patrimonialObj?.totalAeB?.toLocaleString(
                                          "pt-br",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Receitas Diversas - </td>
                                      <td
                                        align="right"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        R${" "}
                                        {patrimonialObj?.totalOutros?.toLocaleString(
                                          "pt-br",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Receitas Não Operacionais - </td>
                                      <td
                                        align="right"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        R${" "}
                                        {patrimonialObj?.totalNOP?.toLocaleString(
                                          "pt-br",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Receita Bruta - </td>
                                      <td
                                        align="right"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        R${" "}
                                        {patrimonialObj?.totalReceitas?.toLocaleString(
                                          "pt-br",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        Valor da Retenção para Fundo de Reserva{" "}
                                        {"(" +
                                          parseFloat(
                                            patrimonialObj?.fundoDeReservaPerc
                                              .retencaopercentual
                                          )?.toLocaleString("pt-BR", {
                                            style: "percent",
                                            maximumFractionDigits: 2,
                                          }) +
                                          ") - "}
                                      </td>
                                      <td
                                        align="right"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        R${" "}
                                        {patrimonialObj?.fundoDeReserva?.toLocaleString(
                                          "pt-br",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        Total de Ações da{" "}
                                        {patrimonialObj?.label} -{" "}
                                      </td>
                                      <td
                                        align="right"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        {patrimonialObj?.acoes?.toLocaleString(
                                          "pr-br"
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td
                                style={{
                                  border: "1px solid",
                                  padding: "0 20px",
                                }}
                              >
                                <table
                                  align="center"
                                  style={{
                                    width: "100%",
                                    WebkitTextSizeAdjust: "100%",
                                    msTextSizeAjust: "100%",
                                    padding: "0",
                                  }}
                                >
                                  <tbody>
                                    <tr>
                                      <td>Valor Aluguel Apurado</td>
                                      <td
                                        align="right"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        R${" "}
                                        {patrimonialObj?.totalBrutoSimples?.toLocaleString(
                                          "pt-br",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                    </tr>
                                    <tr
                                      onClick={
                                        patrimonialObj?.assinatura.length > 0
                                          ? () =>
                                            notify(
                                              "Documento já assinado, não é possivel altera-lo!"
                                            )
                                          : () =>
                                            setOutrasDespesas({
                                              ...outrasDespesas,
                                              modalVisivel: true,
                                            })
                                      }
                                    >
                                      <td>
                                        (+/-) Valor investimentos aprovados /
                                        Outras adições ou reduções{" "}
                                        {patrimonialObj?.assinatura.length >
                                          0 ? null : (
                                          <span
                                            style={{ float: "right" }}
                                            id="iconComment"
                                          >
                                            <BiCommentEdit />
                                          </span>
                                        )}
                                      </td>
                                      <td
                                        align="right"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        R${" "}
                                        {patrimonialObj?.correcaoAluguel?.toLocaleString(
                                          "pt-br",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ padding: "0 20px" }}>
                                        Valor do Aluguel Bruto por Ação
                                      </td>
                                      <td
                                        align="right"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        R${" "}
                                        {patrimonialObj?.aluguelPorAcao?.toLocaleString(
                                          "pt-br",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        Valor da Retenção para Fundo de Reserva
                                      </td>
                                      <td
                                        align="right"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        R${" "}
                                        {patrimonialObj?.fundoDeReserva?.toLocaleString(
                                          "pt-br",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ padding: "0 20px" }}>
                                        Valor da Retenção para Fundo de Reserva
                                        por Ação
                                      </td>
                                      <td
                                        align="right"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        R${" "}
                                        {patrimonialObj?.fundoDeReservaPorAcao?.toLocaleString(
                                          "pt-br",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        Valor do aluguel liquido a distribuir
                                      </td>
                                      <td
                                        align="right"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        R${" "}
                                        {(
                                          patrimonialObj?.totalBrutoSimples -
                                          math.abs(
                                            patrimonialObj?.fundoDeReserva
                                          ) +
                                          patrimonialObj?.correcaoAluguel
                                        )?.toLocaleString("pt-br", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ padding: "0 20px" }}>
                                        Valor do Aluguel Liquido por Ação
                                      </td>
                                      <td
                                        align="right"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        R${" "}
                                        {patrimonialObj?.aluguelPorAcaoLiquido?.toLocaleString(
                                          "pt-br",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <>
                              <tr
                                onClick={
                                  patrimonialObj?.assinatura?.length > 0
                                    ? () =>
                                      notify(
                                        "Documento já assinado, não é possivel altera-lo!"
                                      )
                                    : () =>
                                      setObservacao({
                                        ...observacao,
                                        modalVisivel: true,
                                      })
                                }
                                align="center"
                                style={{
                                  border: "1px solid",
                                  borderRight: "0",
                                  borderLeft: "0",
                                }}
                              >
                                <td colSpan="2" style={{ padding: "0 20px" }}>
                                  <span>
                                    <b>OBSERVAÇÕES</b>
                                  </span>
                                  {patrimonialObj?.assinatura?.length >
                                    0 ? null : (
                                    <span
                                      style={{ float: "right" }}
                                      id="iconComment"
                                    >
                                      <BiCommentEdit />
                                    </span>
                                  )}
                                </td>
                              </tr>
                              <tr
                                onClick={
                                  patrimonialObj?.assinatura?.length > 0
                                    ? () =>
                                      notify(
                                        "Documento já assinado, não é possivel altera-lo!"
                                      )
                                    : () =>
                                      setObservacao({
                                        ...observacao,
                                        modalVisivel: true,
                                      })
                                }
                              >
                                <td
                                  colSpan="2"
                                  style={{ padding: "20px 20px" }}
                                >
                                  {observacao.comentario}
                                </td>
                              </tr>
                            </>
                            <tr>
                              <td
                                colSpan="2"
                                style={{ background: "#119e91", height: "5px" }}
                              ></td>
                            </tr>
                            <tr>
                              <td colSpan={2} style={{ padding: 0 }}>
                                <table
                                  align="center"
                                  style={{
                                    width: "100%",
                                    WebkitTextSizeAdjust: "100%",
                                    msTextSizeAjust: "100%",
                                    padding: "0",
                                  }}
                                >
                                  <tbody>
                                    <tr align="center">
                                      <td
                                        style={{
                                          border: "1px solid",
                                          padding: "2px 8px",
                                          borderLeft: "0px",
                                        }}
                                        rowSpan={2}
                                      >
                                        #
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid",
                                          padding: "2px 8px",
                                        }}
                                        rowSpan={2}
                                        onClick={() =>
                                          setPatrimonialObj({
                                            ...patrimonialObj,
                                            acionistas:
                                              patrimonialObj.acionistas.sort(
                                                (a, b) =>
                                                  a.nome < b.nome
                                                    ? -1
                                                    : a.nome > b.nome
                                                      ? 1
                                                      : 0
                                              ),
                                          })
                                        }
                                      >
                                        NOME
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid",
                                          padding: "2px 8px",
                                        }}
                                        rowSpan={2}
                                        onClick={() =>
                                          setPatrimonialObj({
                                            ...patrimonialObj,
                                            acionistas:
                                              patrimonialObj.acionistas.sort(
                                                (a, b) =>
                                                  a["cpf/cnpj"].length <
                                                    b["cpf/cnpj"].length
                                                    ? -1
                                                    : a["cpf/cnpj"].length >
                                                      b["cpf/cnpj"].length
                                                      ? 1
                                                      : 0
                                              ),
                                          })
                                        }
                                      >
                                        CPF/CNPJ
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid",
                                          padding: "2px 8px",
                                        }}
                                        rowSpan={2}
                                        onClick={() =>
                                          setPatrimonialObj({
                                            ...patrimonialObj,
                                            acionistas:
                                              patrimonialObj.acionistas.sort(
                                                (a, b) =>
                                                  parseFloat(a.acoes) <
                                                    parseFloat(b.acoes)
                                                    ? -1
                                                    : parseFloat(a.acoes) >
                                                      parseFloat(b.acoes)
                                                      ? 1
                                                      : 0
                                              ),
                                          })
                                        }
                                      >
                                        TOTAL DE AÇÕES
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid",
                                          padding: "2px 8px",
                                        }}
                                        rowSpan={2}
                                        onClick={() =>
                                          setPatrimonialObj({
                                            ...patrimonialObj,
                                            acionistas:
                                              patrimonialObj.acionistas.sort(
                                                (a, b) =>
                                                  a.receitaAluguel <
                                                    b.receitaAluguel
                                                    ? -1
                                                    : a.receitaAluguel >
                                                      b.receitaAluguel
                                                      ? 1
                                                      : 0
                                              ),
                                          })
                                        }
                                      >
                                        RECEITA ALUGUEL
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid",
                                          padding: "2px 8px",
                                        }}
                                        rowSpan={2}
                                        onClick={() =>
                                          setPatrimonialObj({
                                            ...patrimonialObj,
                                            acionistas:
                                              patrimonialObj.acionistas.sort(
                                                (a, b) =>
                                                  a.retencaoFundoReserva <
                                                    b.retencaoFundoReserva
                                                    ? -1
                                                    : a.retencaoFundoReserva >
                                                      b.retencaoFundoReserva
                                                      ? 1
                                                      : 0
                                              ),
                                          })
                                        }
                                      >
                                        RETENÇÃO F. RESERVA
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid",
                                          padding: "2px 8px",
                                        }}
                                        rowSpan={2}
                                        onClick={() =>
                                          setPatrimonialObj({
                                            ...patrimonialObj,
                                            acionistas:
                                              patrimonialObj.acionistas.sort(
                                                (a, b) =>
                                                  a.irrf < b.irrf
                                                    ? -1
                                                    : a.irrf > b.irrf
                                                      ? 1
                                                      : 0
                                              ),
                                          })
                                        }
                                      >
                                        IRRF
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid",
                                          padding: "2px 8px",
                                        }}
                                        rowSpan={2}
                                        onClick={() =>
                                          setPatrimonialObj({
                                            ...patrimonialObj,
                                            acionistas:
                                              patrimonialObj.acionistas.sort(
                                                (a, b) =>
                                                  a.valorLiquidoPagamento <
                                                    b.valorLiquidoPagamento
                                                    ? -1
                                                    : a.valorLiquidoPagamento >
                                                      b.valorLiquidoPagamento
                                                      ? 1
                                                      : 0
                                              ),
                                          })
                                        }
                                      >
                                        VALOR LIQUIDO PARA PAGAMENTO
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid",
                                          padding: "2px 8px",
                                          borderRight: "0px",
                                        }}
                                        colSpan="3"
                                        onClick={() =>
                                          setPatrimonialObj({
                                            ...patrimonialObj,
                                            acionistas:
                                              patrimonialObj.acionistas.sort(
                                                (a, b) =>
                                                  a.banco < b.banco
                                                    ? -1
                                                    : a.banco > b.banco
                                                      ? 1
                                                      : 0
                                              ),
                                          })
                                        }
                                      >
                                        Contas Para Depósito do Aluguel
                                      </td>
                                    </tr>
                                    <tr align="center">
                                      <td
                                        style={{
                                          border: "1px solid",
                                          padding: "2px 8px",
                                        }}
                                        onClick={() =>
                                          setPatrimonialObj({
                                            ...patrimonialObj,
                                            acionistas:
                                              patrimonialObj.acionistas.sort(
                                                (a, b) =>
                                                  a.banco < b.banco
                                                    ? -1
                                                    : a.banco > b.banco
                                                      ? 1
                                                      : 0
                                              ),
                                          })
                                        }
                                      >
                                        Banco
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid",
                                          padding: "2px 8px",
                                        }}
                                        onClick={() =>
                                          setPatrimonialObj({
                                            ...patrimonialObj,
                                            acionistas:
                                              patrimonialObj.acionistas.sort(
                                                (a, b) =>
                                                  a.agencia < b.agencia
                                                    ? -1
                                                    : a.agencia > b.agencia
                                                      ? 1
                                                      : 0
                                              ),
                                          })
                                        }
                                      >
                                        Ag.
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid",
                                          padding: "2px 8px",
                                          borderRight: "0px",
                                        }}
                                        onClick={() =>
                                          setPatrimonialObj({
                                            ...patrimonialObj,
                                            acionistas:
                                              patrimonialObj.acionistas.sort(
                                                (a, b) =>
                                                  a.conta < b.conta
                                                    ? -1
                                                    : a.conta > b.conta
                                                      ? 1
                                                      : 0
                                              ),
                                          })
                                        }
                                      >
                                        C/C
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        colSpan="11"
                                        style={{
                                          background: "#119e91",
                                          height: "5px",
                                        }}
                                      ></td>
                                    </tr>
                                    {patrimonialObj?.acionistas.map(
                                      (acionista, index) => {
                                        if (
                                          patrimonialObj?.assinatura.length > 0
                                        ) {
                                          return (
                                            <tr
                                              key={"1" + index}
                                              onMouseEnter={(e) =>
                                              (e.target.parentNode.bgColor =
                                                "#119e9199")
                                              }
                                              onMouseLeave={(e) =>
                                              (e.target.parentNode.bgColor =
                                                "transparent")
                                              }
                                              onClick={() => {
                                                if (
                                                  acionista?.email?.apartamentos
                                                    .length <= 0
                                                ) {
                                                  setModalDataAdicionaApartamentoFix(
                                                    {
                                                      ...modalDataAdicionaApartamentoFix,
                                                      ...acionista,
                                                      modalVisivel: true,
                                                    }
                                                  );
                                                }
                                                setEditarInvestidor({
                                                  ...editarInvestidor,
                                                  modalVisivel: true,
                                                  ...acionista,
                                                  finder: acionista["cpf/cnpj"],
                                                  isAssinado: true,
                                                });
                                              }}
                                            >
                                              <td
                                                style={{
                                                  border: "1px solid",
                                                  padding: "2px 8px",
                                                  borderLeft: "0px",
                                                  whiteSpace: "nowrap",
                                                }}
                                                align="center"
                                              >
                                                {index + 1}
                                                <OverlayTrigger
                                                  placement="right"
                                                  overlay={
                                                    <Popover
                                                      hidden={
                                                        acionista?.comentario ===
                                                          ""
                                                          ? true
                                                          : false
                                                      }
                                                      style={{ zIndex: 100000 }}
                                                      id="popover-basic"
                                                    >
                                                      <Popover.Title as="h5">
                                                        Comentário
                                                      </Popover.Title>
                                                      <Popover.Content>
                                                        <p>
                                                          {
                                                            acionista?.comentario
                                                          }
                                                        </p>
                                                      </Popover.Content>
                                                    </Popover>
                                                  }
                                                >
                                                  <sup
                                                    hidden={
                                                      acionista?.comentario ===
                                                        ""
                                                        ? true
                                                        : false
                                                    }
                                                  >
                                                    {" "}
                                                    <Badge variant="warning">
                                                      C
                                                    </Badge>
                                                  </sup>
                                                </OverlayTrigger>

                                                <OverlayTrigger
                                                  placement="right"
                                                  overlay={
                                                    <Tooltip
                                                      hidden={
                                                        !!!acionista?.reside_exterior
                                                      }
                                                      style={{ zIndex: 100000 }}
                                                      id="popover-basic"
                                                    >
                                                      Reside em{" "}
                                                      {acionista?.pais_residencia_nome +
                                                        " -> " +
                                                        acionista?.aliquota +
                                                        "%"}
                                                    </Tooltip>
                                                  }
                                                >
                                                  <sup
                                                    hidden={
                                                      !!!acionista?.reside_exterior
                                                    }
                                                  >
                                                    {" "}
                                                    <BiWorld color="blue" />
                                                  </sup>
                                                </OverlayTrigger>
                                              </td>
                                              <td
                                                style={{
                                                  border: "1px solid",
                                                  padding: "2px 8px",
                                                }}
                                              >
                                                {acionista?.nome}
                                              </td>
                                              <td
                                                style={{
                                                  border: "1px solid",
                                                  padding: "2px 8px",
                                                  whiteSpace: "nowrap",
                                                }}
                                                align="center"
                                              >
                                                {acionista?.["cpf/cnpj"]}
                                              </td>
                                              <OverlayTrigger
                                                placement="right"
                                                overlay={
                                                  <Tooltip
                                                    style={{ zIndex: 100000 }}
                                                  >
                                                    {acionista.email.apartamentos.map(
                                                      (apartamento, idx) => {
                                                        return (
                                                          <Col key={"2" + idx}>
                                                            <Row>
                                                              {
                                                                apartamento.unidade
                                                              }
                                                            </Row>
                                                            <Row>
                                                              {
                                                                apartamento.apartamentos
                                                              }
                                                            </Row>
                                                          </Col>
                                                        );
                                                      }
                                                    )}
                                                  </Tooltip>
                                                }
                                              >
                                                <td
                                                  style={{
                                                    border: "1px solid",
                                                    padding: "2px 8px",
                                                  }}
                                                  align="center"
                                                >
                                                  {acionista?.acoes?.toLocaleString(
                                                    "pr-br"
                                                  )}
                                                </td>
                                              </OverlayTrigger>
                                              <td
                                                style={{
                                                  border: "1px solid",
                                                  padding: "2px 8px",
                                                  whiteSpace: "nowrap",
                                                }}
                                                align="right"
                                              >
                                                R${" "}
                                                {acionista?.receitaAluguel?.toLocaleString(
                                                  "pt-br",
                                                  {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                  }
                                                )}
                                              </td>
                                              <td
                                                style={{
                                                  border: "1px solid",
                                                  padding: "2px 8px",
                                                  whiteSpace: "nowrap",
                                                }}
                                                align="right"
                                              >
                                                R${" "}
                                                {acionista?.retencaoFundoReserva?.toLocaleString(
                                                  "pt-br",
                                                  {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                  }
                                                )}
                                              </td>
                                              <td
                                                style={{
                                                  border: "1px solid",
                                                  padding: "2px 8px",
                                                  whiteSpace: "nowrap",
                                                }}
                                                align="right"
                                              >
                                                R${" "}
                                                {acionista?.irrf?.toLocaleString(
                                                  "pt-br",
                                                  {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                  }
                                                )}
                                              </td>
                                              <td
                                                style={{
                                                  border: "1px solid",
                                                  padding: "2px 8px",
                                                  whiteSpace: "nowrap",
                                                }}
                                                align="right"
                                              >
                                                R${" "}
                                                {acionista?.valorLiquidoPagamento?.toLocaleString(
                                                  "pt-br",
                                                  {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                  }
                                                )}
                                              </td>
                                              <td
                                                style={{
                                                  border: "1px solid",
                                                  padding: "2px 8px",
                                                }}
                                                align="center"
                                              >
                                                {acionista.banco}
                                              </td>
                                              {acionista?.isPix ? (
                                                <td
                                                  style={{
                                                    border: "1px solid",
                                                    padding: "2px 8px",
                                                    borderRight: "0px",
                                                    whiteSpace: "nowrap",
                                                  }}
                                                  colSpan="2"
                                                  align="center"
                                                >
                                                  {acionista?.pix}
                                                </td>
                                              ) : (
                                                <>
                                                  <td
                                                    style={{
                                                      border: "1px solid",
                                                      padding: "2px 8px",
                                                      whiteSpace: "nowrap",
                                                    }}
                                                    align="center"
                                                  >
                                                    {acionista?.agencia}
                                                  </td>
                                                  <td
                                                    style={{
                                                      border: "1px solid",
                                                      padding: "2px 8px",
                                                      borderRight: "0px",
                                                      whiteSpace: "nowrap",
                                                    }}
                                                    align="center"
                                                  >
                                                    {acionista?.conta}
                                                  </td>
                                                </>
                                              )}
                                            </tr>
                                          );
                                        } else {
                                          return (
                                            <OverlayTrigger
                                              placement="right"
                                              overlay={
                                                <Tooltip
                                                  style={{ zIndex: 100000 }}
                                                >
                                                  <BiEditAlt />
                                                </Tooltip>
                                              }
                                            >
                                              {/* cpf/cnpj e patrimonial_id */}
                                              <tr
                                                onMouseEnter={(e) =>
                                                (e.target.parentNode.bgColor =
                                                  "#119e9199")
                                                }
                                                onMouseLeave={(e) =>
                                                (e.target.parentNode.bgColor =
                                                  "transparent")
                                                }
                                                onClick={() => {
                                                  if (
                                                    acionista?.email
                                                      ?.apartamentos.length <= 0
                                                  ) {
                                                    setModalDataAdicionaApartamentoFix(
                                                      {
                                                        ...modalDataAdicionaApartamentoFix,
                                                        ...acionista,
                                                        modalVisivel: true,
                                                      }
                                                    );
                                                  }
                                                  setEditarInvestidor({
                                                    ...editarInvestidor,
                                                    modalVisivel: true,
                                                    ...acionista,
                                                    finder:
                                                      acionista["cpf/cnpj"],
                                                    isAssinado: false,
                                                  });
                                                }}
                                              >
                                                <td
                                                  style={{
                                                    border: "1px solid",
                                                    padding: "2px 8px",
                                                    borderLeft: "0px",
                                                    whiteSpace: "nowrap",
                                                  }}
                                                  align="center"
                                                >
                                                  {index + 1}
                                                  <OverlayTrigger
                                                    placement="right"
                                                    overlay={
                                                      <Popover
                                                        hidden={
                                                          acionista?.comentario ===
                                                            ""
                                                            ? true
                                                            : false
                                                        }
                                                        style={{
                                                          zIndex: 100000,
                                                        }}
                                                        id="popover-basic"
                                                      >
                                                        <Popover.Title as="h5">
                                                          Comentário
                                                        </Popover.Title>
                                                        <Popover.Content>
                                                          <p>
                                                            {
                                                              acionista?.comentario
                                                            }
                                                          </p>
                                                        </Popover.Content>
                                                      </Popover>
                                                    }
                                                  >
                                                    <sup
                                                      hidden={
                                                        acionista?.comentario ===
                                                          ""
                                                          ? true
                                                          : false
                                                      }
                                                    >
                                                      {" "}
                                                      <Badge variant="warning">
                                                        C
                                                      </Badge>
                                                    </sup>
                                                  </OverlayTrigger>

                                                  <OverlayTrigger
                                                    placement="right"
                                                    overlay={
                                                      <Tooltip
                                                        hidden={
                                                          !!!acionista?.reside_exterior
                                                        }
                                                        style={{
                                                          zIndex: 100000,
                                                        }}
                                                        id="popover-basic"
                                                      >
                                                        Reside em{" "}
                                                        {acionista?.pais_residencia_nome +
                                                          " -> " +
                                                          acionista?.aliquota +
                                                          "%"}
                                                      </Tooltip>
                                                    }
                                                  >
                                                    <sup
                                                      hidden={
                                                        !!!acionista?.reside_exterior
                                                      }
                                                    >
                                                      {" "}
                                                      <BiWorld color="blue" />
                                                    </sup>
                                                  </OverlayTrigger>
                                                </td>
                                                <td
                                                  style={{
                                                    border: "1px solid",
                                                    padding: "2px 8px",
                                                  }}
                                                >
                                                  {acionista?.nome}
                                                </td>
                                                <td
                                                  style={{
                                                    border: "1px solid",
                                                    padding: "2px 8px",
                                                    whiteSpace: "nowrap",
                                                  }}
                                                  align="center"
                                                >
                                                  {acionista?.["cpf/cnpj"]}
                                                </td>
                                                <OverlayTrigger
                                                  placement="right"
                                                  overlay={
                                                    <Tooltip
                                                      style={{ zIndex: 100000 }}
                                                    >
                                                      {acionista.email.apartamentos.map(
                                                        (apartamento, idx) => {
                                                          return (
                                                            <Col
                                                              key={"3" + index}
                                                            >
                                                              <Row>
                                                                {
                                                                  apartamento.unidade
                                                                }{" "}
                                                                -{" "}
                                                                {
                                                                  apartamento.apartamentos
                                                                }
                                                              </Row>
                                                            </Col>
                                                          );
                                                        }
                                                      )}
                                                    </Tooltip>
                                                  }
                                                >
                                                  <td
                                                    style={{
                                                      border: "1px solid",
                                                      padding: "2px 8px",
                                                    }}
                                                    align="center"
                                                  >
                                                    {acionista?.acoes?.toLocaleString(
                                                      "pr-br"
                                                    )}
                                                  </td>
                                                </OverlayTrigger>
                                                <td
                                                  style={{
                                                    border: "1px solid",
                                                    padding: "2px 8px",
                                                    whiteSpace: "nowrap",
                                                  }}
                                                  align="right"
                                                >
                                                  R${" "}
                                                  {acionista?.receitaAluguel?.toLocaleString(
                                                    "pt-br",
                                                    {
                                                      minimumFractionDigits: 2,
                                                      maximumFractionDigits: 2,
                                                    }
                                                  )}
                                                </td>
                                                <td
                                                  style={{
                                                    border: "1px solid",
                                                    padding: "2px 8px",
                                                    whiteSpace: "nowrap",
                                                  }}
                                                  align="right"
                                                >
                                                  R${" "}
                                                  {acionista?.retencaoFundoReserva?.toLocaleString(
                                                    "pt-br",
                                                    {
                                                      minimumFractionDigits: 2,
                                                      maximumFractionDigits: 2,
                                                    }
                                                  )}
                                                </td>
                                                <td
                                                  style={{
                                                    border: "1px solid",
                                                    padding: "2px 8px",
                                                    whiteSpace: "nowrap",
                                                  }}
                                                  align="right"
                                                >
                                                  R${" "}
                                                  {acionista?.irrf?.toLocaleString(
                                                    "pt-br",
                                                    {
                                                      minimumFractionDigits: 2,
                                                      maximumFractionDigits: 2,
                                                    }
                                                  )}
                                                </td>
                                                <td
                                                  style={{
                                                    border: "1px solid",
                                                    padding: "2px 8px",
                                                    whiteSpace: "nowrap",
                                                  }}
                                                  align="right"
                                                >
                                                  R${" "}
                                                  {acionista?.valorLiquidoPagamento?.toLocaleString(
                                                    "pt-br",
                                                    {
                                                      minimumFractionDigits: 2,
                                                      maximumFractionDigits: 2,
                                                    }
                                                  )}
                                                </td>
                                                <td
                                                  style={{
                                                    border: "1px solid",
                                                    padding: "2px 8px",
                                                  }}
                                                  align="center"
                                                >
                                                  {acionista.banco}
                                                </td>
                                                {acionista?.isPix ? (
                                                  <td
                                                    style={{
                                                      border: "1px solid",
                                                      padding: "2px 8px",
                                                      borderRight: "0px",
                                                      whiteSpace: "nowrap",
                                                    }}
                                                    colSpan="2"
                                                    align="center"
                                                  >
                                                    {acionista?.pix}
                                                  </td>
                                                ) : (
                                                  <>
                                                    <td
                                                      style={{
                                                        border: "1px solid",
                                                        padding: "2px 8px",
                                                        whiteSpace: "nowrap",
                                                      }}
                                                      align="center"
                                                    >
                                                      {acionista?.agencia}
                                                    </td>
                                                    <td
                                                      style={{
                                                        border: "1px solid",
                                                        padding: "2px 8px",
                                                        borderRight: "0px",
                                                        whiteSpace: "nowrap",
                                                      }}
                                                      align="center"
                                                    >
                                                      {acionista?.conta}
                                                    </td>
                                                  </>
                                                )}
                                              </tr>
                                            </OverlayTrigger>
                                          );
                                        }
                                      }
                                    )}
                                    <tr>
                                      <td
                                        style={{
                                          border: "1px solid",
                                          padding: "2px 8px",
                                          borderLeft: "0px",
                                        }}
                                        colSpan={3}
                                        align="right"
                                      >
                                        Total Geral
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid",
                                          padding: "2px 8px",
                                          whiteSpace: "nowrap",
                                        }}
                                        align="center"
                                      >
                                        {patrimonialObj?.acionistas
                                          ?.reduce(
                                            (acc, cv) =>
                                              (acc += parseInt(cv.acoes)),
                                            0
                                          )
                                          ?.toLocaleString("pr-br")}
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid",
                                          padding: "2px 8px",
                                          whiteSpace: "nowrap",
                                        }}
                                        align="right"
                                      >
                                        R${" "}
                                        {patrimonialObj?.acionistas
                                          ?.reduce(
                                            (acc, cv) =>
                                              (acc += cv.receitaAluguel),
                                            0
                                          )
                                          ?.toLocaleString("pt-br", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid",
                                          padding: "2px 8px",
                                          whiteSpace: "nowrap",
                                        }}
                                        align="right"
                                      >
                                        R${" "}
                                        {patrimonialObj?.acionistas
                                          ?.reduce(
                                            (acc, cv) =>
                                              (acc += cv.retencaoFundoReserva),
                                            0
                                          )
                                          ?.toLocaleString("pt-br", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid",
                                          padding: "2px 8px",
                                          whiteSpace: "nowrap",
                                        }}
                                        align="right"
                                      >
                                        R${" "}
                                        {patrimonialObj?.acionistas
                                          ?.reduce(
                                            (acc, cv) => (acc += cv.irrf),
                                            0
                                          )
                                          ?.toLocaleString("pt-br", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid",
                                          padding: "2px 8px",
                                          whiteSpace: "nowrap",
                                        }}
                                        align="right"
                                      >
                                        R${" "}
                                        {patrimonialObj?.acionistas
                                          ?.reduce(
                                            (acc, cv) =>
                                              (acc += cv.valorLiquidoPagamento),
                                            0
                                          )
                                          ?.toLocaleString("pt-br", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid",
                                          padding: "2px 8px",
                                          borderRight: "0px",
                                        }}
                                        colSpan={3}
                                        align="left"
                                      >
                                        {" "}
                                        {patrimonialObj?.acionistas?.reduce(
                                          (acc, cv) =>
                                            (acc += parseInt(cv.acoes)),
                                          0
                                        ) !== patrimonialObj.acoes ||
                                          patrimonialObj?.acionistas?.filter(
                                            (acionistaAtual) =>
                                              acionistaAtual.acoes == 0
                                          ).length > 0 ? (
                                          <Badge
                                            pill
                                            variant="danger"
                                            id="checkVality"
                                            style={{
                                              color: "#dc3545",
                                              position: "relative",
                                              top: "-1px",
                                            }}
                                          >
                                            1
                                          </Badge>
                                        ) : null}{" "}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ height: "5px" }}></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      ) : patrimonialObj?.modelo === 1 || patrimonialObj?.modelo === 3 ? (
        <table
          id="investerTable"
          style={{
            width: "calc( 100% + 20px)",
            background: "#656562",
            WebkitTextSizeAdjust: "100%",
            msTextSizeAjust: "100%",
            padding: "0",
            margin: "-10px",
            borderCollapse: "collapse",
            fontSize: "small",
          }}
        >
          <tbody>
            <tr>
              <td>
                <table
                  align="center"
                  style={{
                    width: "90%",
                    background: "#fff",
                    WebkitTextSizeAdjust: "100%",
                    msTextSizeAjust: "100%",
                    padding: "0",
                  }}
                >
                  <tbody>
                    <tr>
                      <td>
                        <table
                          align="center"
                          style={{
                            width: "98%",
                            WebkitTextSizeAdjust: "100%",
                            msTextSizeAjust: "100%",
                            padding: "0",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td style={{ height: "5px" }}></td>
                            </tr>
                            <tr>
                              <td rowSpan={2}>
                                <img
                                  width={"auto"}
                                  height={80}
                                  className="mr-3"
                                  src={logoUpManagement}
                                  alt="Logo UP Asset Management"
                                />
                              </td>
                              <td align="center" style={{ fontWeight: "bold" }}>
                                {patrimonialObj?.titulo}
                              </td>
                            </tr>
                            <tr>
                              <td align="center" style={{ fontWeight: "bold" }}>
                                RESULTADOS APURADOS PELA{" "}
                                {patrimonialObj?.label?.toUpperCase()}
                              </td>
                            </tr>
                            <tr>
                              <td style={{ height: "5px" }}></td>
                            </tr>
                          </tbody>
                        </table>
                        <table
                          align="center"
                          style={{
                            width: "98%",
                            WebkitTextSizeAdjust: "100%",
                            msTextSizeAjust: "100%",
                            padding: "0",
                            border: "2px solid",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td
                                colSpan="2"
                                style={{ background: "#119e91", height: "5px" }}
                              ></td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid",
                                  width: "50%",
                                  padding: "0 20px",
                                }}
                              >
                                <table
                                  align="center"
                                  style={{
                                    width: "100%",
                                    WebkitTextSizeAdjust: "100%",
                                    msTextSizeAjust: "100%",
                                    padding: "0",
                                  }}
                                >
                                  <tbody>
                                    <tr>
                                      <td>Receita de Hospedagem - </td>
                                      <td
                                        align="right"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        R${" "}
                                        {patrimonialObj?.totalHospedagem?.toLocaleString(
                                          "pt-br",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Receita de A&B - </td>
                                      <td
                                        align="right"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        R${" "}
                                        {patrimonialObj?.totalAeB?.toLocaleString(
                                          "pt-br",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Receitas Diversas - </td>
                                      <td
                                        align="right"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        R${" "}
                                        {patrimonialObj?.totalOutros?.toLocaleString(
                                          "pt-br",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Receitas Não Operacionais - </td>
                                      <td
                                        align="right"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        R${" "}
                                        {patrimonialObj?.totalNOP?.toLocaleString(
                                          "pt-br",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Receita Bruta - </td>
                                      <td
                                        align="right"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        R${" "}
                                        {patrimonialObj?.totalReceitas?.toLocaleString(
                                          "pt-br",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        Total de Ações da{" "}
                                        {patrimonialObj?.label} -{" "}
                                      </td>
                                      <td
                                        align="right"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        {patrimonialObj?.acoes?.toLocaleString(
                                          "pr-br"
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td
                                style={{
                                  border: "1px solid",
                                  width: "50%",
                                  padding: "0 20px",
                                }}
                              >
                                <table
                                  align="center"
                                  style={{
                                    width: "100%",
                                    WebkitTextSizeAdjust: "100%",
                                    msTextSizeAjust: "100%",
                                    padding: "0",
                                  }}
                                >
                                  <tbody>
                                    <tr>
                                      <td>
                                        Valor Aluguel Apurado{" "}
                                        {/* para {patrimonialObj.label}*/}
                                      </td>
                                      <td
                                        align="right"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        R${" "}
                                        {patrimonialObj?.totalBruto?.toLocaleString(
                                          "pt-br",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                    </tr>
                                    {patrimonialObj?.ajusteReceita[0]?.descricao
                                      ?.length > 0 ? (
                                      <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                          <Popover id="popover-basic">
                                            <Popover.Title as="h5">
                                              Comentário
                                            </Popover.Title>
                                            <Popover.Content>
                                              <p>
                                                {
                                                  patrimonialObj
                                                    ?.ajusteReceita[0]
                                                    ?.descricao
                                                }
                                              </p>
                                            </Popover.Content>
                                          </Popover>
                                        }
                                      >
                                        <tr
                                          style={{ cursor: "pointer" }}
                                          onClick={
                                            patrimonialObj?.assinatura?.length >
                                              0
                                              ? () =>
                                                notify(
                                                  "Documento já assinado, não é possivel altera-lo!"
                                                )
                                              : cookies.usuario.rights.includes(
                                                "d"
                                              )
                                                ? () =>
                                                  setAjusteReceita({
                                                    ...ajusteReceita,
                                                    modalVisivel: true,
                                                  })
                                                : null
                                          }
                                        >
                                          <td>
                                            {" "}
                                            (+/-) Ajustes{" "}
                                            {patrimonialObj?.label}
                                            {patrimonialObj?.assinatura
                                              ?.length > 0 ? null : (
                                              <span
                                                style={{ float: "right" }}
                                                id="iconAjusteReceita"
                                              >
                                                <BiCommentEdit />
                                              </span>
                                            )}
                                          </td>
                                          <td
                                            align="right"
                                            style={{ whiteSpace: "nowrap" }}
                                          >
                                            R${" "}
                                            {ajusteReceita.valor.toLocaleString(
                                              "pt-br",
                                              {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              }
                                            )}
                                          </td>
                                        </tr>
                                      </OverlayTrigger>
                                    ) : (
                                      <tr
                                        style={{ cursor: "pointer" }}
                                        onClick={
                                          patrimonialObj?.assinatura?.length > 0
                                            ? () =>
                                              notify(
                                                "Documento já assinado, não é possivel altera-lo!"
                                              )
                                            : cookies.usuario.rights.includes(
                                              "d"
                                            )
                                              ? () =>
                                                setAjusteReceita({
                                                  ...ajusteReceita,
                                                  modalVisivel: true,
                                                })
                                              : null
                                        }
                                      >
                                        <td>
                                          {" "}
                                          (+/-) Ajustes {patrimonialObj?.label}
                                          {patrimonialObj?.assinatura?.length >
                                            0 ? null : cookies.usuario.rights.includes(
                                              "d"
                                            ) ? (
                                            <span
                                              style={{ float: "right" }}
                                              id="iconAjusteReceita"
                                            >
                                              <BiCommentEdit />
                                            </span>
                                          ) : null}
                                        </td>
                                        <td
                                          align="right"
                                          style={{ whiteSpace: "nowrap" }}
                                        >
                                          R${" "}
                                          {ajusteReceita.valor.toLocaleString(
                                            "pt-br",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                        </td>
                                      </tr>
                                    )}
                                    <tr>
                                      <td>
                                        Valor da Retenção para Fundo de Reserva{" "}
                                        {"(" +
                                          parseFloat(
                                            patrimonialObj?.fundoDeReservaPerc
                                              .retencaopercentual
                                          )?.toLocaleString("pt-BR", {
                                            style: "percent",
                                            maximumFractionDigits: 2,
                                          }) +
                                          ") - "}
                                      </td>
                                      <td
                                        align="right"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        R${" "}
                                        {patrimonialObj?.fundoDeReserva?.toLocaleString(
                                          "pt-br",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ padding: "0 20px" }}>
                                        Valor da Retenção para Fundo de Reserva
                                        por Ação
                                      </td>
                                      <td
                                        align="right"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        R${" "}
                                        {patrimonialObj?.fundoDeReservaPorAcao?.toLocaleString(
                                          "pt-br",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Valor do aluguel líquido</td>
                                      <td
                                        align="right"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        R${" "}
                                        {patrimonialObj?.aluguelLiquido?.toLocaleString(
                                          "pt-br",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ padding: "0 20px" }}>
                                        Valor do Aluguel Liquido por Ação
                                      </td>
                                      <td
                                        align="right"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        R${" "}
                                        {parseFloat(
                                          patrimonialObj?.aluguelPorAcao
                                        )?.toLocaleString("pt-br", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td
                                colSpan="2"
                                style={{ background: "#119e91", height: "5px" }}
                              ></td>
                            </tr>
                            {patrimonialObj?.proprietarios.length > 0 ? (
                              <>
                                <tr>
                                  <td colSpan={2} style={{ padding: 0 }}>
                                    <table
                                      align="center"
                                      style={{
                                        width: "100%",
                                        WebkitTextSizeAdjust: "100%",
                                        msTextSizeAjust: "100%",
                                        padding: "0",
                                      }}
                                    >
                                      <tbody>
                                        <tr align="center">
                                          <td
                                            style={{
                                              border: "1px solid",
                                              padding: "2px 8px",
                                              borderLeft: "0px",
                                            }}
                                            rowSpan={2}
                                          >
                                            #
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid",
                                              padding: "2px 8px",
                                            }}
                                            rowSpan={2}
                                          >
                                            PROPRIETÁRIOS DO HOTEL
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid",
                                              padding: "2px 8px",
                                            }}
                                            rowSpan={2}
                                          >
                                            CNPJ
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid",
                                              padding: "2px 8px",
                                            }}
                                            rowSpan={2}
                                          >
                                            %
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid",
                                              padding: "2px 8px",
                                            }}
                                            rowSpan={2}
                                          >
                                            Valor do Aluguel
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid",
                                              padding: "2px 8px",
                                            }}
                                            rowSpan={2}
                                          >
                                            Fundo de Reserva
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid",
                                              padding: "2px 8px",
                                            }}
                                            rowSpan={2}
                                          >
                                            VR. Líquido a Creditar
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid",
                                              borderRight: "0px",
                                              padding: "2px 8px",
                                            }}
                                            colSpan="3"
                                          >
                                            Contas Para Depósito do Aluguel
                                          </td>
                                        </tr>
                                        <tr align="center">
                                          <td
                                            style={{
                                              border: "1px solid",
                                              padding: "2px 8px",
                                            }}
                                          >
                                            Banco
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid",
                                              padding: "2px 8px",
                                            }}
                                          >
                                            Ag.
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid",
                                              borderRight: "0px",
                                              padding: "2px 8px",
                                            }}
                                          >
                                            C/C
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            colSpan="10"
                                            style={{
                                              background: "#119e91",
                                              height: "5px",
                                            }}
                                          ></td>
                                        </tr>
                                        {patrimonialObj?.proprietarios.map(
                                          (proprietario, index) => {
                                            if (
                                              patrimonialObj?.assinatura
                                                ?.length > 0
                                            )
                                              return (
                                                <tr
                                                  key={"10" + index}
                                                  onClick={() =>
                                                    setEditarProprietarios({
                                                      ...editarProprietarios,
                                                      modalVisivel: true,
                                                      ...proprietario,
                                                      finder:
                                                        proprietario["cnpj"],
                                                      isAssinado: true,
                                                    })
                                                  }
                                                >
                                                  {proprietario?.comentario !==
                                                    "" &&
                                                    proprietario?.comentario !==
                                                    null ? (
                                                    <OverlayTrigger
                                                      placement="bottom"
                                                      overlay={
                                                        <Popover id="popover-basic">
                                                          <Popover.Title as="h5">
                                                            Comentário
                                                          </Popover.Title>
                                                          <Popover.Content>
                                                            <p>
                                                              {
                                                                proprietario?.comentario
                                                              }
                                                            </p>
                                                          </Popover.Content>
                                                        </Popover>
                                                      }
                                                    >
                                                      <td
                                                        style={{
                                                          border: "1px solid",
                                                          borderLeft: "0px",
                                                          padding: "2px 8px",
                                                        }}
                                                        align="center"
                                                      >
                                                        {index + 1}
                                                      </td>
                                                    </OverlayTrigger>
                                                  ) : (
                                                    <td
                                                      style={{
                                                        border: "1px solid",
                                                        borderLeft: "0px",
                                                        padding: "2px 8px",
                                                      }}
                                                      align="center"
                                                    >
                                                      {index + 1}
                                                    </td>
                                                  )}
                                                  <td
                                                    style={{
                                                      border: "1px solid",
                                                      padding: "2px 8px",
                                                    }}
                                                  >
                                                    {proprietario?.proprietario}
                                                  </td>
                                                  <td
                                                    style={{
                                                      border: "1px solid",
                                                      padding: "2px 8px",
                                                      whiteSpace: "nowrap",
                                                    }}
                                                    align="center"
                                                  >
                                                    {proprietario?.cnpj}
                                                  </td>
                                                  <td
                                                    style={{
                                                      border: "1px solid",
                                                      padding: "2px 8px",
                                                    }}
                                                    align="right"
                                                  >
                                                    {(
                                                      proprietario?.percentual *
                                                      100
                                                    )?.toFixed(2) + "%"}
                                                  </td>
                                                  <td
                                                    style={{
                                                      border: "1px solid",
                                                      whiteSpace: "nowrap",
                                                      padding: "2px 8px",
                                                    }}
                                                    align="right"
                                                  >
                                                    R${" "}
                                                    {proprietario?.valorDoAluguel?.toLocaleString(
                                                      "pt-br",
                                                      {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                      }
                                                    )}
                                                  </td>
                                                  <td
                                                    style={{
                                                      border: "1px solid",
                                                      whiteSpace: "nowrap",
                                                      padding: "2px 8px",
                                                    }}
                                                    align="right"
                                                  >
                                                    R${" "}
                                                    {proprietario?.fundoDeReserva?.toLocaleString(
                                                      "pt-br",
                                                      {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                      }
                                                    )}
                                                  </td>
                                                  <td
                                                    style={{
                                                      border: "1px solid",
                                                      whiteSpace: "nowrap",
                                                      padding: "2px 8px",
                                                    }}
                                                    align="right"
                                                  >
                                                    R${" "}
                                                    {proprietario?.aluguelLiquidoACreditar?.toLocaleString(
                                                      "pt-br",
                                                      {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                      }
                                                    )}
                                                  </td>
                                                  <td
                                                    style={{
                                                      border: "1px solid",
                                                      padding: "2px 8px",
                                                    }}
                                                    align="center"
                                                  >
                                                    {proprietario?.banco}
                                                  </td>
                                                  {proprietario.banco?.toLowerCase() ===
                                                    "pix" ? (
                                                    <td
                                                      style={{
                                                        border: "1px solid",
                                                        padding: "2px 8px",
                                                        whiteSpace: "nowrap",
                                                      }}
                                                      colSpan="2"
                                                      align="center"
                                                    >
                                                      {proprietario?.agencia}
                                                    </td>
                                                  ) : (
                                                    <>
                                                      <td
                                                        style={{
                                                          border: "1px solid",
                                                          padding: "2px 8px",
                                                          whiteSpace: "nowrap",
                                                        }}
                                                        align="center"
                                                      >
                                                        {proprietario?.agencia}
                                                      </td>
                                                      <td
                                                        style={{
                                                          border: "1px solid",
                                                          borderRight: "0px",
                                                          padding: "2px 8px",
                                                          whiteSpace: "nowrap",
                                                        }}
                                                        align="center"
                                                      >
                                                        {proprietario?.conta}
                                                      </td>
                                                    </>
                                                  )}
                                                </tr>
                                              );

                                            return (
                                              <OverlayTrigger
                                                placement="right"
                                                overlay={
                                                  <Tooltip
                                                    style={{ zIndex: 100000 }}
                                                  >
                                                    <BiEditAlt />
                                                  </Tooltip>
                                                }
                                              >
                                                <tr
                                                  onClick={() =>
                                                    setEditarProprietarios({
                                                      ...editarProprietarios,
                                                      modalVisivel: true,
                                                      ...proprietario,
                                                      finder:
                                                        proprietario["cnpj"],
                                                      isAssinado: false,
                                                    })
                                                  }
                                                >
                                                  {proprietario?.comentario !==
                                                    "" &&
                                                    proprietario?.comentario !==
                                                    null ? (
                                                    <OverlayTrigger
                                                      placement="right-start"
                                                      overlay={
                                                        <Popover id="popover-basic">
                                                          <Popover.Title as="h5">
                                                            Comentário
                                                          </Popover.Title>
                                                          <Popover.Content>
                                                            <p>
                                                              {
                                                                proprietario?.comentario
                                                              }
                                                            </p>
                                                          </Popover.Content>
                                                        </Popover>
                                                      }
                                                    >
                                                      <td
                                                        style={{
                                                          border: "1px solid",
                                                          borderLeft: "0px",
                                                          padding: "2px 8px",
                                                          textDecoration:
                                                            "underline",
                                                        }}
                                                        align="center"
                                                      >
                                                        {index + 1}
                                                      </td>
                                                    </OverlayTrigger>
                                                  ) : (
                                                    <td
                                                      style={{
                                                        border: "1px solid",
                                                        borderLeft: "0px",
                                                        padding: "2px 8px",
                                                      }}
                                                      align="center"
                                                    >
                                                      {index + 1}
                                                    </td>
                                                  )}
                                                  <td
                                                    style={{
                                                      border: "1px solid",
                                                      padding: "2px 8px",
                                                    }}
                                                  >
                                                    {proprietario?.proprietario}
                                                  </td>
                                                  <td
                                                    style={{
                                                      border: "1px solid",
                                                      padding: "2px 8px",
                                                    }}
                                                    align="center"
                                                  >
                                                    {proprietario?.cnpj}
                                                  </td>
                                                  <td
                                                    style={{
                                                      border: "1px solid",
                                                      padding: "2px 8px",
                                                    }}
                                                    align="right"
                                                  >
                                                    {(
                                                      proprietario?.percentual *
                                                      100
                                                    )?.toFixed(2) + "%"}
                                                  </td>
                                                  <td
                                                    style={{
                                                      border: "1px solid",
                                                      whiteSpace: "nowrap",
                                                      padding: "2px 8px",
                                                    }}
                                                    align="right"
                                                  >
                                                    R${" "}
                                                    {proprietario?.valorDoAluguel?.toLocaleString(
                                                      "pt-br",
                                                      {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                      }
                                                    )}
                                                  </td>
                                                  <td
                                                    style={{
                                                      border: "1px solid",
                                                      whiteSpace: "nowrap",
                                                      padding: "2px 8px",
                                                    }}
                                                    align="right"
                                                  >
                                                    R${" "}
                                                    {proprietario?.fundoDeReserva?.toLocaleString(
                                                      "pt-br",
                                                      {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                      }
                                                    )}
                                                  </td>
                                                  <td
                                                    style={{
                                                      border: "1px solid",
                                                      whiteSpace: "nowrap",
                                                      padding: "2px 8px",
                                                    }}
                                                    align="right"
                                                  >
                                                    R${" "}
                                                    {proprietario?.aluguelLiquidoACreditar?.toLocaleString(
                                                      "pt-br",
                                                      {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                      }
                                                    )}
                                                  </td>
                                                  <td
                                                    style={{
                                                      border: "1px solid",
                                                      padding: "2px 8px",
                                                    }}
                                                    align="center"
                                                  >
                                                    {proprietario?.banco}
                                                  </td>
                                                  {proprietario.banco?.toLowerCase() ===
                                                    "pix" ? (
                                                    <td
                                                      style={{
                                                        border: "1px solid",
                                                        padding: "2px 8px",
                                                        whiteSpace: "nowrap",
                                                      }}
                                                      colSpan="2"
                                                      align="center"
                                                    >
                                                      {proprietario?.agencia}
                                                    </td>
                                                  ) : (
                                                    <>
                                                      <td
                                                        style={{
                                                          border: "1px solid",
                                                          padding: "2px 8px",
                                                          whiteSpace: "nowrap",
                                                        }}
                                                        align="center"
                                                      >
                                                        {proprietario?.agencia}
                                                      </td>
                                                      <td
                                                        style={{
                                                          border: "1px solid",
                                                          borderRight: "0px",
                                                          padding: "2px 8px",
                                                          whiteSpace: "nowrap",
                                                        }}
                                                        align="center"
                                                      >
                                                        {proprietario?.conta}
                                                      </td>
                                                    </>
                                                  )}
                                                </tr>
                                              </OverlayTrigger>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colSpan="2"
                                    style={{
                                      background: "#119e91",
                                      height: "5px",
                                    }}
                                  ></td>
                                </tr>
                              </>
                            ) : null}
                            <tr>
                              <td colSpan="2" style={{ border: "1px solid" }}>
                                <table
                                  align="center"
                                  style={{
                                    width: "100%",
                                    WebkitTextSizeAdjust: "100%",
                                    msTextSizeAjust: "100%",
                                    padding: "0",
                                  }}
                                >
                                  <tbody>
                                    <tr
                                      align="center"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      <td>
                                        {" "}
                                        DISTRIBUIÇÃO DE LUCROS NA{" "}
                                        {patrimonialObj?.label?.toUpperCase()}
                                      </td>
                                    </tr>
                                    <tr
                                      align="center"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      <td>
                                        Relação de Investidores e Valores de
                                        Distribuição de Lucros a Pagar
                                      </td>
                                    </tr>
                                    <tr
                                      align="center"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      <td>
                                        Resultados relativos a{" "}
                                        {
                                          patrimonialObj?.dadosTempo
                                            ?.mesconsultastr
                                        }{" "}
                                        pagamentos até o dia 15 de{" "}
                                        {
                                          patrimonialObj?.dadosTempo
                                            ?.mespagamentostr
                                        }{" "}
                                        de{" "}
                                        {
                                          patrimonialObj?.dadosTempo
                                            ?.anopagamento
                                        }
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td
                                colSpan="2"
                                style={{ background: "#119e91", height: "5px" }}
                              ></td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid",
                                  width: "50%",
                                  padding: "0 20px",
                                }}
                              >
                                <table
                                  align="center"
                                  style={{
                                    width: "100%",
                                    WebkitTextSizeAdjust: "100%",
                                    msTextSizeAjust: "100%",
                                    padding: "0",
                                  }}
                                >
                                  <tbody>
                                    <tr>
                                      <td colSpan={2}>
                                        {" "}
                                        Aluguel Bruto Recebido da{" "}
                                        {patrimonialObj?.label}
                                      </td>
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        R${" "}
                                        {patrimonialObj?.aluguelBruto?.toLocaleString(
                                          "pt-br",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                    </tr>
                                    {patrimonialObj?.alugueis?.map(
                                      (aluguel) => {
                                        return (
                                          <tr>
                                            <td colSpan={2}>
                                              {" "}
                                              Aluguel Bruto Recebido{" "}
                                              {aluguel?.titulo}
                                            </td>
                                            <td
                                              style={{ whiteSpace: "nowrap" }}
                                            >
                                              R${" "}
                                              {aluguel?.valor?.toLocaleString(
                                                "pt-br",
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                    <tr>
                                      <td colSpan={2}>
                                        {" "}
                                        Impostos e outras eventuais despesas
                                      </td>
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        R${" "}
                                        {patrimonialObj?.impostosEDespesas?.toLocaleString(
                                          "pt-br",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td> Pis</td>
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        R${" "}
                                        {patrimonialObj?.pis?.toLocaleString(
                                          "pt-br",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td> Cofins</td>
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        R${" "}
                                        {patrimonialObj?.cofins?.toLocaleString(
                                          "pt-br",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td> CSLL</td>
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        R${" "}
                                        {patrimonialObj?.csll?.toLocaleString(
                                          "pt-br",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td> IRPJ</td>
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        R${" "}
                                        {patrimonialObj?.irpj?.toLocaleString(
                                          "pt-br",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td> Adicional do IRPJ</td>
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        R${" "}
                                        {patrimonialObj?.adicionalIRPJ?.toLocaleString(
                                          "pt-br",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                      <td></td>
                                    </tr>

                                    {patrimonialObj?.outrasDespesas[0]
                                      ?.descricao.length > 0 ? (
                                      <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                          <Popover id="popover-basic">
                                            <Popover.Title as="h5">
                                              Comentário
                                            </Popover.Title>
                                            <Popover.Content>
                                              <p>
                                                {
                                                  patrimonialObj
                                                    ?.outrasDespesas[0]
                                                    ?.descricao
                                                }
                                              </p>
                                            </Popover.Content>
                                          </Popover>
                                        }
                                      >
                                        <tr
                                          onClick={
                                            patrimonialObj?.assinatura?.length >
                                              0
                                              ? () =>
                                                notify(
                                                  "Documento já assinado, não é possivel altera-lo!"
                                                )
                                              : !cookies.usuario.rights.includes(
                                                "d"
                                              )
                                                ? null
                                                : () =>
                                                  setOutrasDespesas({
                                                    ...outrasDespesas,
                                                    modalVisivel: true,
                                                  })
                                          }
                                        >
                                          <td>
                                            {" "}
                                            Outras Eventuais Despesas da{" "}
                                            {patrimonialObj?.label}
                                          </td>
                                          <td style={{ whiteSpace: "nowrap" }}>
                                            R${" "}
                                            {outrasDespesas.valor.toLocaleString(
                                              "pt-br",
                                              {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              }
                                            )}
                                          </td>
                                          <td>
                                            {patrimonialObj?.assinatura
                                              ?.length >
                                              0 ? null : !cookies.usuario.rights.includes(
                                                "d"
                                              ) ? null : (
                                              <span id="iconOutrasDespesas">
                                                <BiCommentEdit />
                                              </span>
                                            )}
                                          </td>
                                        </tr>
                                      </OverlayTrigger>
                                    ) : (
                                      <tr
                                        onClick={
                                          patrimonialObj?.assinatura?.length > 0
                                            ? () =>
                                              notify(
                                                "Documento já assinado, não é possivel altera-lo!"
                                              )
                                            : !cookies.usuario.rights.includes(
                                              "d"
                                            )
                                              ? null
                                              : () =>
                                                setOutrasDespesas({
                                                  ...outrasDespesas,
                                                  modalVisivel: true,
                                                })
                                        }
                                      >
                                        <td>
                                          {" "}
                                          Outras Eventuais Despesas da{" "}
                                          {patrimonialObj?.label}
                                        </td>
                                        <td style={{ whiteSpace: "nowrap" }}>
                                          R${" "}
                                          {outrasDespesas.valor.toLocaleString(
                                            "pt-br",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                        </td>
                                        <td>
                                          {patrimonialObj?.assinatura?.length >
                                            0 ? null : !cookies.usuario.rights.includes(
                                              "d"
                                            ) ? null : (
                                            <span id="iconOutrasDespesas">
                                              <BiCommentEdit />
                                            </span>
                                          )}
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </td>
                              <td
                                onClick={
                                  patrimonialObj?.assinatura?.length > 0
                                    ? () =>
                                      notify(
                                        "Documento já assinado, não é possivel altera-lo!"
                                      )
                                    : () =>
                                      setObservacao({
                                        ...observacao,
                                        modalVisivel: true,
                                      })
                                }
                                style={{
                                  border: "1px solid",
                                  width: "50%",
                                  padding: 0,
                                }}
                              >
                                <table
                                  align="center"
                                  style={{
                                    width: "100%",
                                    WebkitTextSizeAdjust: "100%",
                                    msTextSizeAjust: "100%",
                                    padding: "0",
                                  }}
                                >
                                  <tbody>
                                    <tr>
                                      <td style={{ padding: "0 20px" }}>
                                        Lucro a Distribuir aos sócios
                                      </td>
                                      <td
                                        style={{
                                          padding: "0 20px",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        R${" "}
                                        {patrimonialObj?.lucroADistribuir?.toLocaleString(
                                          "pt-br",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ padding: "0 20px" }}>
                                        Lucro Liquido por Ação
                                      </td>
                                      <td
                                        style={{
                                          padding: "0 20px",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        R${" "}
                                        {patrimonialObj?.lucroADistribuirPorAcao?.toLocaleString(
                                          "pt-br",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                    </tr>
                                    <tr
                                      align="center"
                                      style={{
                                        border: "1px solid",
                                        borderRight: "0",
                                        borderLeft: "0",
                                      }}
                                    >
                                      <td
                                        colSpan={2}
                                        style={{ padding: "0 20px" }}
                                      >
                                        <span>
                                          <b>OBSERVAÇÕES</b>
                                        </span>
                                        {patrimonialObj?.assinatura?.length >
                                          0 ? null : (
                                          <span
                                            style={{ float: "right" }}
                                            id="iconComment"
                                          >
                                            <BiCommentEdit />
                                          </span>
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan={2}>
                                        <TextField
                                          id="standard-Nome"
                                          multiline
                                          size="small"
                                          style={{
                                            color: "#000",
                                            borderWidth: "0",
                                          }}
                                          disabled
                                          fullWidth
                                          maxRows={5}
                                          minRows={5}
                                          variant="outlined"
                                          value={observacao.comentario}
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td
                                colSpan="2"
                                style={{ background: "#119e91", height: "5px" }}
                              ></td>
                            </tr>
                            <tr>
                              <td key={"1"} colSpan={2} style={{ padding: 0 }}>
                                <table
                                  align="center"
                                  style={{
                                    width: "100%",
                                    WebkitTextSizeAdjust: "100%",
                                    msTextSizeAjust: "100%",
                                    padding: "0",
                                  }}
                                >
                                  <thead>
                                    <tr align="center">
                                      <td
                                        style={{
                                          border: "1px solid",
                                          borderLeft: "0px",
                                        }}
                                        rowSpan={2}
                                      >
                                        #
                                      </td>
                                      <td
                                        style={{ border: "1px solid" }}
                                        rowSpan={2}
                                        onClick={() =>
                                          setPatrimonialObj({
                                            ...patrimonialObj,
                                            acionistas:
                                              patrimonialObj.acionistas.sort(
                                                (a, b) =>
                                                  a.nome < b.nome
                                                    ? -1
                                                    : a.nome > b.nome
                                                      ? 1
                                                      : 0
                                              ),
                                          })
                                        }
                                      >
                                        NOME
                                      </td>
                                      <td
                                        style={{ border: "1px solid" }}
                                        rowSpan={2}
                                        onClick={() =>
                                          setPatrimonialObj({
                                            ...patrimonialObj,
                                            acionistas:
                                              patrimonialObj.acionistas.sort(
                                                (a, b) =>
                                                  a["cpf/cnpj"].length <
                                                    b["cpf/cnpj"].length
                                                    ? -1
                                                    : a["cpf/cnpj"].length >
                                                      b["cpf/cnpj"].length
                                                      ? 1
                                                      : 0
                                              ),
                                          })
                                        }
                                      >
                                        CPF/CNPJ
                                      </td>
                                      <td
                                        style={{ border: "1px solid" }}
                                        rowSpan={2}
                                        onClick={() =>
                                          setPatrimonialObj({
                                            ...patrimonialObj,
                                            acionistas:
                                              patrimonialObj.acionistas.sort(
                                                (a, b) =>
                                                  parseFloat(a.acoes) <
                                                    parseFloat(b.acoes)
                                                    ? -1
                                                    : parseFloat(a.acoes) >
                                                      parseFloat(b.acoes)
                                                      ? 1
                                                      : 0
                                              ),
                                          })
                                        }
                                      >
                                        TOTAL DE AÇÕES
                                      </td>
                                      <td
                                        style={{ border: "1px solid" }}
                                        rowSpan={2}
                                        onClick={() =>
                                          setPatrimonialObj({
                                            ...patrimonialObj,
                                            acionistas:
                                              patrimonialObj.acionistas.sort(
                                                (a, b) =>
                                                  a.receitaAluguel <
                                                    b.receitaAluguel
                                                    ? -1
                                                    : a.receitaAluguel >
                                                      b.receitaAluguel
                                                      ? 1
                                                      : 0
                                              ),
                                          })
                                        }
                                      >
                                        LUCRO A RECEBER
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid",
                                          borderRight: "0px",
                                        }}
                                        colSpan="3"
                                        onClick={() =>
                                          setPatrimonialObj({
                                            ...patrimonialObj,
                                            acionistas:
                                              patrimonialObj.acionistas.sort(
                                                (a, b) =>
                                                  a.banco < b.banco
                                                    ? -1
                                                    : a.banco > b.banco
                                                      ? 1
                                                      : 0
                                              ),
                                          })
                                        }
                                      >
                                        Contas Para Depósito dos Lucros
                                        Distribuidos
                                      </td>
                                    </tr>
                                    <tr align="center">
                                      <td
                                        style={{ border: "1px solid" }}
                                        onClick={() =>
                                          setPatrimonialObj({
                                            ...patrimonialObj,
                                            acionistas:
                                              patrimonialObj.acionistas.sort(
                                                (a, b) =>
                                                  a.banco < b.banco
                                                    ? -1
                                                    : a.banco > b.banco
                                                      ? 1
                                                      : 0
                                              ),
                                          })
                                        }
                                      >
                                        Banco
                                      </td>
                                      <td
                                        style={{ border: "1px solid" }}
                                        onClick={() =>
                                          setPatrimonialObj({
                                            ...patrimonialObj,
                                            acionistas:
                                              patrimonialObj.acionistas.sort(
                                                (a, b) =>
                                                  a.agencia < b.agencia
                                                    ? -1
                                                    : a.agencia > b.agencia
                                                      ? 1
                                                      : 0
                                              ),
                                          })
                                        }
                                      >
                                        Ag.
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid",
                                          borderRight: "0px",
                                        }}
                                        onClick={() =>
                                          setPatrimonialObj({
                                            ...patrimonialObj,
                                            acionistas:
                                              patrimonialObj.acionistas.sort(
                                                (a, b) =>
                                                  a.conta < b.conta
                                                    ? -1
                                                    : a.conta > b.conta
                                                      ? 1
                                                      : 0
                                              ),
                                          })
                                        }
                                      >
                                        C/C
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        colSpan="10"
                                        style={{
                                          background: "#119e91",
                                          height: "5px",
                                        }}
                                      ></td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {patrimonialObj?.acionistas.map(
                                      (acionista, index) => {
                                        if (
                                          patrimonialObj?.assinatura?.length > 0
                                        ) {
                                          return (
                                            <tr
                                              onMouseEnter={(e) =>
                                              (e.target.parentNode.bgColor =
                                                "#119e9199")
                                              }
                                              onMouseLeave={(e) =>
                                              (e.target.parentNode.bgColor =
                                                "transparent")
                                              }
                                              onClick={
                                                !cookies.usuario.rights.includes(
                                                  "d"
                                                )
                                                  ? null
                                                  : () =>
                                                    setEditarInvestidor({
                                                      ...editarInvestidor,
                                                      modalVisivel: true,
                                                      ...acionista,
                                                      finder:
                                                        acionista["cpf/cnpj"],
                                                      isAssinado: true,
                                                    })
                                              }
                                            >
                                              <td
                                                style={{
                                                  border: "1px solid",
                                                  padding: "2px 8px",
                                                  borderLeft: "0px",
                                                }}
                                                align="center"
                                              >
                                                {index + 1}
                                                <OverlayTrigger
                                                  placement="right"
                                                  overlay={
                                                    <Popover
                                                      hidden={
                                                        acionista?.comentario ===
                                                          ""
                                                          ? true
                                                          : false
                                                      }
                                                      style={{ zIndex: 100000 }}
                                                      id="popover-basic"
                                                    >
                                                      <Popover.Title as="h5">
                                                        Comentário
                                                      </Popover.Title>
                                                      <Popover.Content>
                                                        <p>
                                                          {
                                                            acionista?.comentario
                                                          }
                                                        </p>
                                                      </Popover.Content>
                                                    </Popover>
                                                  }
                                                >
                                                  <sup
                                                    hidden={
                                                      acionista?.comentario ===
                                                        ""
                                                        ? true
                                                        : false
                                                    }
                                                  >
                                                    {" "}
                                                    <Badge variant="warning">
                                                      C
                                                    </Badge>
                                                  </sup>
                                                </OverlayTrigger>

                                                <OverlayTrigger
                                                  placement="right"
                                                  overlay={
                                                    <Tooltip
                                                      hidden={
                                                        !!!acionista?.reside_exterior
                                                      }
                                                      style={{ zIndex: 100000 }}
                                                      id="popover-basic"
                                                    >
                                                      Reside em{" "}
                                                      {acionista?.pais_residencia_nome +
                                                        " -> " +
                                                        acionista?.aliquota +
                                                        "%"}
                                                    </Tooltip>
                                                  }
                                                >
                                                  <sup
                                                    hidden={
                                                      !!!acionista?.reside_exterior
                                                    }
                                                  >
                                                    {" "}
                                                    <BiWorld color="blue" />
                                                  </sup>
                                                </OverlayTrigger>
                                              </td>
                                              <td
                                                style={{
                                                  border: "1px solid",
                                                  padding: "2px 8px",
                                                }}
                                              >
                                                {acionista?.nome}
                                              </td>
                                              <td
                                                style={{
                                                  border: "1px solid",
                                                  padding: "2px 8px",
                                                  whiteSpace: "nowrap",
                                                }}
                                                align="center"
                                              >
                                                {acionista?.["cpf/cnpj"]}
                                              </td>
                                              <td
                                                style={{
                                                  border: "1px solid",
                                                  padding: "2px 8px",
                                                }}
                                                align="center"
                                              >
                                                {acionista?.acoes?.toLocaleString(
                                                  "pr-br"
                                                )}
                                              </td>
                                              <td
                                                style={{
                                                  border: "1px solid",
                                                  padding: "2px 8px",
                                                  whiteSpace: "nowrap",
                                                }}
                                                align="right"
                                              >
                                                R${" "}
                                                {acionista?.receitaAluguel?.toLocaleString(
                                                  "pt-br",
                                                  {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                  }
                                                )}
                                              </td>
                                              <td
                                                style={{
                                                  border: "1px solid",
                                                  padding: "2px 8px",
                                                }}
                                                align="center"
                                              >
                                                {acionista?.banco}
                                              </td>
                                              {acionista.isPix ? (
                                                <td
                                                  style={{
                                                    border: "1px solid",
                                                    padding: "2px 8px",
                                                    borderRight: "0px",
                                                    whiteSpace: "nowrap",
                                                  }}
                                                  colSpan="2"
                                                  align="center"
                                                >
                                                  {acionista?.pix}
                                                </td>
                                              ) : (
                                                <>
                                                  <td
                                                    style={{
                                                      border: "1px solid",
                                                      padding: "2px 8px",
                                                      whiteSpace: "nowrap",
                                                    }}
                                                    align="center"
                                                  >
                                                    {acionista?.agencia}
                                                  </td>
                                                  <td
                                                    style={{
                                                      border: "1px solid",
                                                      padding: "2px 8px",
                                                      borderRight: "0px",
                                                      whiteSpace: "nowrap",
                                                    }}
                                                    align="center"
                                                  >
                                                    {acionista?.conta}
                                                  </td>
                                                </>
                                              )}
                                            </tr>
                                          );
                                        } else {
                                          return (
                                            <OverlayTrigger
                                              placement="right"
                                              overlay={
                                                <Tooltip
                                                  hidden={
                                                    !cookies.usuario.rights.includes(
                                                      "d"
                                                    )
                                                  }
                                                  style={{ zIndex: 100000 }}
                                                >
                                                  <BiEditAlt />
                                                </Tooltip>
                                              }
                                            >
                                              {/* cpf/cnpj e patrimonial_id */}
                                              <tr
                                                onMouseEnter={(e) =>
                                                (e.target.parentNode.bgColor =
                                                  "#119e9199")
                                                }
                                                onMouseLeave={(e) =>
                                                (e.target.parentNode.bgColor =
                                                  "transparent")
                                                }
                                                onClick={
                                                  !cookies.usuario.rights.includes(
                                                    "d"
                                                  )
                                                    ? null
                                                    : () =>
                                                      setEditarInvestidor({
                                                        ...editarInvestidor,
                                                        modalVisivel: true,
                                                        ...acionista,
                                                        finder:
                                                          acionista[
                                                          "cpf/cnpj"
                                                          ],
                                                        isAssinado: false,
                                                      })
                                                }
                                              >
                                                <td
                                                  style={{
                                                    border: "1px solid",
                                                    padding: "2px 8px",
                                                    borderLeft: "0px",
                                                  }}
                                                  align="center"
                                                >
                                                  {index + 1}
                                                  <OverlayTrigger
                                                    placement="right"
                                                    overlay={
                                                      <Popover
                                                        hidden={
                                                          acionista?.comentario ===
                                                            ""
                                                            ? true
                                                            : false
                                                        }
                                                        style={{
                                                          zIndex: 100000,
                                                        }}
                                                        id="popover-basic"
                                                      >
                                                        <Popover.Title as="h5">
                                                          Comentário
                                                        </Popover.Title>
                                                        <Popover.Content>
                                                          <p>
                                                            {
                                                              acionista?.comentario
                                                            }
                                                          </p>
                                                        </Popover.Content>
                                                      </Popover>
                                                    }
                                                  >
                                                    <sup
                                                      hidden={
                                                        acionista?.comentario ===
                                                          ""
                                                          ? true
                                                          : false
                                                      }
                                                    >
                                                      {" "}
                                                      <Badge variant="warning">
                                                        C
                                                      </Badge>
                                                    </sup>
                                                  </OverlayTrigger>
                                                  <OverlayTrigger
                                                    placement="right"
                                                    overlay={
                                                      <Tooltip
                                                        hidden={
                                                          !!!acionista?.reside_exterior
                                                        }
                                                        style={{
                                                          zIndex: 100000,
                                                        }}
                                                        id="popover-basic"
                                                      >
                                                        Reside em{" "}
                                                        {acionista?.pais_residencia_nome +
                                                          " -> " +
                                                          acionista?.aliquota +
                                                          "%"}
                                                      </Tooltip>
                                                    }
                                                  >
                                                    <sup
                                                      hidden={
                                                        !!!acionista?.reside_exterior
                                                      }
                                                    >
                                                      {" "}
                                                      <BiWorld color="blue" />
                                                    </sup>
                                                  </OverlayTrigger>
                                                </td>
                                                <td
                                                  style={{
                                                    border: "1px solid",
                                                    padding: "2px 8px",
                                                  }}
                                                >
                                                  {acionista?.nome}
                                                </td>
                                                <td
                                                  style={{
                                                    border: "1px solid",
                                                    padding: "2px 8px",
                                                    whiteSpace: "nowrap",
                                                  }}
                                                  align="center"
                                                >
                                                  {acionista?.["cpf/cnpj"]}
                                                </td>
                                                <td
                                                  style={{
                                                    border: "1px solid",
                                                    padding: "2px 8px",
                                                  }}
                                                  align="center"
                                                >
                                                  {acionista?.acoes?.toLocaleString(
                                                    "pr-br"
                                                  )}
                                                </td>
                                                <td
                                                  style={{
                                                    border: "1px solid",
                                                    padding: "2px 8px",
                                                    whiteSpace: "nowrap",
                                                  }}
                                                  align="right"
                                                >
                                                  R${" "}
                                                  {acionista?.receitaAluguel?.toLocaleString(
                                                    "pt-br",
                                                    {
                                                      minimumFractionDigits: 2,
                                                      maximumFractionDigits: 2,
                                                    }
                                                  )}
                                                </td>
                                                <td
                                                  style={{
                                                    border: "1px solid",
                                                    padding: "2px 8px",
                                                  }}
                                                  align="center"
                                                >
                                                  {acionista?.banco}
                                                </td>
                                                {acionista.isPix ? (
                                                  <td
                                                    style={{
                                                      border: "1px solid",
                                                      padding: "2px 8px",
                                                      borderRight: "0px",
                                                      whiteSpace: "nowrap",
                                                    }}
                                                    colSpan="2"
                                                    align="center"
                                                  >
                                                    {acionista?.pix}
                                                  </td>
                                                ) : (
                                                  <>
                                                    <td
                                                      style={{
                                                        border: "1px solid",
                                                        padding: "2px 8px",
                                                        whiteSpace: "nowrap",
                                                      }}
                                                      align="center"
                                                    >
                                                      {acionista?.agencia}
                                                    </td>
                                                    <td
                                                      style={{
                                                        border: "1px solid",
                                                        padding: "2px 8px",
                                                        borderRight: "0px",
                                                        whiteSpace: "nowrap",
                                                      }}
                                                      align="center"
                                                    >
                                                      {acionista?.conta}
                                                    </td>
                                                  </>
                                                )}
                                              </tr>
                                            </OverlayTrigger>
                                          );
                                        }
                                      }
                                    )}
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <td
                                        style={{
                                          border: "1px solid",
                                          padding: "2px 8px",
                                          borderLeft: "0px",
                                        }}
                                        colSpan={3}
                                        align="right"
                                      >
                                        Total Geral
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid",
                                          padding: "2px 8px",
                                          whiteSpace: "nowrap",
                                        }}
                                        align="center"
                                      >
                                        {patrimonialObj?.acionistas
                                          ?.reduce(
                                            (acc, cv) =>
                                              (acc += parseInt(cv.acoes)),
                                            0
                                          )
                                          ?.toLocaleString("pr-br")}
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid",
                                          padding: "2px 8px",
                                          whiteSpace: "nowrap",
                                        }}
                                        align="right"
                                      >
                                        R${" "}
                                        {patrimonialObj?.acionistas
                                          ?.reduce(
                                            (acc, cv) =>
                                              (acc += cv.receitaAluguel),
                                            0
                                          )
                                          ?.toLocaleString("pt-br", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid",
                                          padding: "2px 8px",
                                          borderRight: "0px",
                                        }}
                                        colSpan={3}
                                        align="left"
                                      >
                                        {showBadge()}
                                      </td>
                                    </tr>
                                  </tfoot>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ height: "5px" }}></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      ) : null}

      {BuildFab()}
      {BuildModalObs()}
      {BuildModalCorrecoes()}
      {BuildModalInvestidor()}
      {BuildModalProprietarios()}
      {BuildModalAssinaturaComentario()}
      {BuildVisualizarAssinatura()}
      {BuildConfiguracoes()}
      {BuildModalDesativarAcionista()}
      {buildModalDataAdicionaApartamentoFix()}
      {BuildModalOutrasDespesas()}
      {BuildModalAjustes()}

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        style={{ zIndex: 1000000, color: "#2c3e50" }}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
    </>
  );
}

export default Investidores;
